import produce from 'immer';

import type { CartState } from './state';
import type { CartAction } from './actions';
import initialState from './state';
import * as actions from './actions';

export default function cartReducer(
  state: CartState = initialState,
  action: CartAction,
): CartState {
  switch (action.type) {
    case actions.CART_OPEN:
      return produce(state, (draft) => {
        draft.open = true;
      });

    case actions.CART_CLOSE:
      return produce(state, (draft) => {
        draft.open = false;
      });

    case actions.CART_ADD_COUPON:
      return produce(state, (draft) => {
        draft.isLoaded = true;
      });

    case actions.CART_ADD_COUPON_SUCCESS:
      const dataAddCoupon = action.data;
      return produce(state, (draft) => {
        draft.couponError = null;
        draft.data = dataAddCoupon;
        draft.isLoaded = true;
      });

    case actions.CART_ADD_COUPON_FAILURE:
      let dataAddCouponFail = action.error;
      dataAddCouponFail = dataAddCouponFail.toString();
      return produce(state, (draft) => {
        draft.couponError = dataAddCouponFail;
        draft.isLoaded = true;
      });

    case actions.CART_ADD_PRODUCT_REQUEST:
      return produce(state, (draft) => {
        draft.isLoaded = false;
      });

    case actions.CART_ADD_PRODUCT_SUCCESS:
      const dataAddProduct = action.data;
      return produce(state, (draft) => {
        draft.data = dataAddProduct;
        draft.isLoaded = true;
      });

    case actions.CART_ADD_PRODUCT_FAILURE:
      return produce(state, (draft) => {
        draft.isLoaded = true;
      });

    case actions.CART_SET_QUANTITY_REQUEST:
      return produce(state, (draft) => {
        draft.isLoaded = true;
      });

    case actions.CART_SET_QUANTITY_SUCCESS:
      const dataSetQuantity = action.data;
      return produce(state, (draft) => {
        draft.data = dataSetQuantity;
        draft.isLoaded = true;
      });

    case actions.CART_SET_QUANTITY_FAILURE:
      return produce(state, (draft) => {
        draft.isLoaded = true;
      });

    case actions.CART_FETCH_ADDONS_REQUEST:
      return produce(state, (draft) => {
        draft.isLoaded = true;
      });

    case actions.CART_FETCH_ADDONS_SUCCESS:
      const dataFetchAddons = action.data;
      return produce(state, (draft) => {
        draft.addons = dataFetchAddons;
        draft.isLoaded = true;
      });

    case actions.CART_FETCH_ADDONS_FAILURE:
      return produce(state, (draft) => {
        draft.isLoaded = true;
      });

    case actions.CART_FETCH_REQUEST:
      return produce(state, (draft) => {
        draft.isLoaded = false;
      });

    case actions.CART_FETCH_SUCCESS:
      const dataFetchCart = action.data;
      return produce(state, (draft) => {
        draft.data = dataFetchCart;
        draft.isLoaded = true;
      });

    case actions.CART_FETCH_FAILURE:
      return produce(state, (draft) => {
        draft.isLoaded = true;
      });

    default:
      return state;
  }
}

import React from 'react';
import type { PostOrderMeta } from './types';

// Props

type Props = {
  name: string;
  description: string;
  meta: PostOrderMeta;
  value: number;
  currency: string;
  formatCurrency: (arg0: number, arg1: string) => string;
};

// (3/18/19)
// Description and name props should be flipped for the future
// Currently reversed for GFUEL post_order w/ Faze mousepad
const CartPostOrder = (props: Props) => {
  // const price = props.formatCurrency(props.value, props.currency);

  return (
    <tr className="widget-item widget-item--post-order">
      <td className="widget-item__thumbnail">
        <a
          style={{
            backgroundImage: props.meta
              ? `url(${props.meta.thumbnail})`
              : 'none',
          }}
          aria-hidden="true"
          tabIndex={-1}
        >
          <span className="sr-only">{props.name}</span>
        </a>
      </td>
      <td className="widget-item__name">
        <span className="post-order__title">{props.description}</span>
        <span className="post-order__description">{props.name}</span>
      </td>
      <td className="widget-item__price widget-item__total">
        <span className="widget-item__total__line free-note">Free</span>
      </td>
    </tr>
  );
};

export default CartPostOrder;

/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */

import React from 'react';
import ReactDOM from 'react-dom/client';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createMiddleware } from 'redux-beacon';
import GoogleTagManager from '@redux-beacon/google-tag-manager';
import { Provider } from 'react-redux';
import accessibleModal from 'van11y-accessible-modal-window-aria';
import Tooltip from 'tooltip.js';
import Cookies from 'js-cookie';
import { datadogRum } from '@datadog/browser-rum';
import createAccordions from './accordions';
import createTabs from './tabs';

import AnimationFX from './animations/AnimationFX';
import CurrencyFormatter from './currency/CurrencyFormatter';
import AddressForm from './forms/AddressForm';
import FormValidator from './forms/FormValidator';
import choiceBox from './forms/choiceBox';
import StripePaymentMethod from './checkout/StripePaymentMethod';
import StripePaymentIntents from './checkout/StripePaymentIntents';
import updateShippingPrice from './checkout/updateShippingPrice';

import FacetedProductList from './catalog/FacetedProductList';
import BuySection from './catalog/BuySection';
import BuySectionv2 from './catalog/BuySectionv2';
import BuySectionCustomization from './catalog/BuySectionCustomization';
import threeDimensionalModelSetup from './threeDimensionalModel';
import SearchWidget from './search/SearchWidget';

import {
  searchReducer,
  gtmSearchEvents,
  addSearchEventListeners,
} from './search';

import Cart from './cart/Cart';
import CartIcon from './cart_widget/CartIcon';
import CartWidget from './cart_widget/CartWidget';
import cartReducer from './cart_widget/reducer';
import {
  gtmCartWidgetEvents,
} from './cart_widget/analytics';
import { createBindAddToCart, addCartEventListeners, createDelegateAddToCart } from './cart_widget/init';

import createQuickBuy from './quickbuy';
import createTooltip from './tooltip';
import { countrySelection } from './countries';
import createNewsletterSignup from './newsletter';
import validateSignupForm from './frontend_password_validation';
import addHighContrastClass from './highcontrast';
import captureUTM from './utm';
import addCriteoGTM from './criteo';
import Variables from './variables';

// Global namespace for everything SteelSeries

window.SteelSeries = window.SteelSeries || {};

// Datadog RUM UX tracking
if (window.datadogApplicationId && window.datadogClientToken) {
  datadogRum.init({
    applicationId: window.datadogApplicationId,
    clientToken: window.datadogClientToken,
    site: 'datadoghq.com',
    sessionSampleRate: 5,
    sessionReplaySampleRate: 5,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
  });

  datadogRum.startSessionReplayRecording();
}

// Currency formatting

const formatter = new CurrencyFormatter(window.currencyFormat || {});
window.SteelSeries.formatCurrency = (value, currencyCode) => formatter.format(value, currencyCode);

// Static Source
window.SteelSeries.staticUrl = window.staticUrl;

// Cart Widget Enabled Status
window.SteelSeries.enableCartWidget = window.enableCartWidget;

// Item Inventory
window.SteelSeries.inventoryList = window.inventoryList;

// Animations
window.SteelSeries.AnimationFX = AnimationFX;

// Forms

window.SteelSeries.FormValidator = FormValidator;
window.SteelSeries.AddressForm = AddressForm;
window.SteelSeries.choiceBox = choiceBox;

window.SteelSeries.Tooltip = Tooltip;

// Catalog

window.SteelSeries.FacetedProductList = FacetedProductList;
window.SteelSeries.BuySection = BuySection;
window.SteelSeries.BuySectionv2 = BuySectionv2;
window.SteelSeries.BuySectionCustomization = BuySectionCustomization;
window.SteelSeries.threeDimensionalModelSetup = threeDimensionalModelSetup;

// Checkout

window.SteelSeries.paymentMethods = {};
window.SteelSeries.StripePaymentMethod = StripePaymentMethod;
window.SteelSeries.StripePaymentIntents = StripePaymentIntents;
window.SteelSeries.updateShippingPrice = updateShippingPrice;

// Analytics

const gtm = GoogleTagManager();

const searchGtmMiddleware = createMiddleware(gtmSearchEvents, gtm);

const cartWidgetGtmMiddleware = createMiddleware(gtmCartWidgetEvents, gtm);

// Redux

const store = createStore(
  combineReducers({
    search: searchReducer,
    cart: cartReducer,
  }),
  applyMiddleware(
    thunkMiddleware,
    searchGtmMiddleware,
    cartWidgetGtmMiddleware,
  ),
);

// For debugging:
// store.subscribe(() => console.log(store.getState()));

const searchContainer = document.getElementById('js-search-container');
const cartIconContainer = document.getElementById('js-cart-icon-container');
const cartWidgetContainer = document.getElementById('js-cart-container');
const cartPageContainer = document.getElementById('js-cart-page');

// Search Widget
if (searchContainer instanceof HTMLElement) {
  const root = ReactDOM.createRoot(searchContainer);
  root.render(
    <Provider store={store}>
      <SearchWidget />
    </Provider>,
  );
}

// Cart Widget - Icon
if (cartIconContainer instanceof HTMLElement) {
  const root = ReactDOM.createRoot(cartIconContainer);
  root.render(
    <Provider store={store}>
      <CartIcon enableCartWidget={window.SteelSeries.enableCartWidget} />
    </Provider>,
  );
}

// Cart Widget - Overlay Panel
if (cartWidgetContainer instanceof HTMLElement) {
  const root = ReactDOM.createRoot(cartWidgetContainer);
  root.render(
    <Provider store={store}>
      <CartWidget
        formatCurrency={window.SteelSeries.formatCurrency}
        inventoryList={window.SteelSeries.inventoryList}
        staticUrl={window.SteelSeries.staticUrl}
      />
    </Provider>,
  );
}

// Cart Page
if (cartPageContainer instanceof HTMLElement) {
  const root = ReactDOM.createRoot(cartPageContainer);
  root.render(
    <Provider store={store}>
      <Cart
        formatCurrency={window.SteelSeries.formatCurrency}
        inventoryList={window.SteelSeries.inventoryList}
        staticUrl={window.SteelSeries.staticUrl}
      />
    </Provider>,
  );
}

addCartEventListeners(document, store);
addSearchEventListeners(document, store);

const bindAddToCart = createBindAddToCart(store);
window.SteelSeries.bindAddToCart = bindAddToCart;

const delegateAddToCart = createDelegateAddToCart(store);
window.SteelSeries.delegateAddToCart = delegateAddToCart;

window.SteelSeries.delegateAddToCartForSiteCampaign = () => {
  const mainElement = document.getElementById('main');
  if (mainElement && window.SteelSeries && window.SteelSeries.hasOwnProperty('delegateAddToCart')) {
    window.SteelSeries.delegateAddToCart(mainElement, '.sc-addToCartForm', '.sc-addToCart');
  }
};

const quickBuy = createQuickBuy(store);
window.SteelSeries.quickBuy = quickBuy;

createTooltip();
createAccordions();
createTabs();
countrySelection();
createNewsletterSignup('.js-footer-newsletter-form');
addHighContrastClass(window.document);
addCriteoGTM();

window.SteelSeries.captureUTM = captureUTM;

// validate signup form
window.SteelSeries.validateSignupForm = validateSignupForm;

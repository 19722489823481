import React from 'react';

// Props

type Props = {
  mediumThumbUrl: string;
  productPageUrl: string;
  name: string;
  quantity: number;
  product: number;
  price: number;
  maxQuantity?: number;
  linePrice: number;
  currency: string;
  tabIndex?: Object;
  formatCurrency: (number: number, string: string) => string;
};

const CartValueAdd = (props: Props) => {
  const price = props.formatCurrency(props.linePrice, props.currency);

  return (
    <tr className="widget-item widget-item--value-add">
      <td className="widget-item__thumbnail">
        <a
          href={props.productPageUrl}
          style={{
            backgroundImage: `url(${props.mediumThumbUrl})`,
          }}
          aria-hidden="true"
          tabIndex={-1}
        >
          <span className="sr-only">{props.name}</span>
        </a>
      </td>
      <td className="widget-item__name">
        <a
          href={props.productPageUrl}
          className="widget-item__link"
          {...props.tabIndex}
        >
          {props.name}
        </a>
        <div
          className="widget-item__description"
          aria-label={`${props.quantity} included with purchase`}
        >
          {`${props.quantity} included with purchase`}
        </div>
      </td>
      <td className="widget-item__price widget-item__total" colSpan={2}>
        <span className="widget-item__total__line free-note">Free</span>
        <small className="widget-item__total__msrp">{price}</small>
      </td>
    </tr>
  );
};

export default CartValueAdd;

import axios from 'axios';
import serialize from 'form-serialize';
import type { AxiosPromise } from 'axios';


function subscribe(location: string, data: string | Object): AxiosPromise<Object> {
  return axios.post(location, data, {
    headers: { 'X-Requested-With': 'XMLHttpRequest' },
  });
}


function attachSubmitHandler(form: HTMLFormElement) {
  const action = form.getAttribute('action');
  if (!action) {
    return;
  }
  const errorList = form.querySelector('.form-field__error-list ');
  const successMessage = form.querySelector('.footer-newsletter-form__success');
  const widgets = form.querySelector('.footer-newsletter-form__widgets');
  const emailField = form.querySelector('input[type="email"]');
  if (!(errorList instanceof HTMLElement)
    || !(emailField instanceof HTMLElement)
    || !(successMessage instanceof HTMLElement)
    || !(widgets instanceof HTMLElement)) {
    return;
  }

  form.addEventListener('submit', (event: Event) => {
    // Preventdefault once we know we can proceed
    event.preventDefault();

    // Perform subscribe
    const data = serialize(form);
    subscribe(action, data).then(
      (response) => {
        form.classList.add('is-success');
        form.removeChild(widgets)
        successMessage.setAttribute('aria-hidden', 'false');
        errorList.innerHTML = '';
      },
      (error) => {
        const errorMessages = [];
        if (error.response) {
          // We got a response from the server, just output all of them
          Object.keys(error.response.data).forEach(key => {
            const messages = error.response.data[key];
            messages.forEach(m => errorMessages.push(m));
          });
          emailField.classList.add('is-invalid');
          emailField.setAttribute('aria-invalid', 'true');
        } else {
          // Something else went wrong, use a generic message.
          errorMessages.push("Something went wrong, please try again later.");
        }

        // Clear existing errors
        errorList.innerHTML = '';

        // Add error title
        const titleElement = document.createElement('span');
        titleElement.className = 'form-field__error-title sr-only';
        titleElement.innerHTML = 'Errors for email:';
        errorList.appendChild(titleElement);

        // Add new errors
        errorMessages.forEach((err) => {
          const errorElement = document.createElement('span')
          errorElement.className = 'form-field__error';
          errorElement.innerHTML = err;
          errorList.appendChild(errorElement);
        });
      },
    );
  });
}


export default function createNewsletterSignup(
  selector: string,
) {
  const forms = Array.from(document.querySelectorAll(selector));
  forms.forEach((el) => {
    if (el instanceof HTMLFormElement) {
      attachSubmitHandler(el);
    }
  });
}

import React from 'react';
import generateKey from '@utils/generateKey';

type Props = {
  ariaHidden: boolean,
  title: string,
}

// SVG
export default function SvgLockOpen(props: Props) {
  const {ariaHidden, title} = props;
  const key = generateKey('lock-open');

  return (
    <svg
      aria-hidden={ariaHidden}
      aria-labelledby={key}
      role="img"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
    >
      <desc id={key}>{title ? title : 'Lock open'}</desc>
      <path d="M423.5 0C339.5.3 272 69.5 272 153.5V224H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48h-48v-71.1c0-39.6 31.7-72.5 71.3-72.9 40-.4 72.7 32.1 72.7 72v80c0 13.3 10.7 24 24 24h32c13.3 0 24-10.7 24-24v-80C576 68 507.5-.3 423.5 0z"/>
    </svg>
  );
}

import type { CartState } from './state';
import type { Cart, Item } from './types';
import type {
  CartAction,
  CartAddProductSuccessAction,
  CartSetQuantitySuccessAction,
} from './actions';
import {
  CART_OPEN,
  CART_CLOSE,
  CART_ADD_PRODUCT_SUCCESS,
  CART_SET_QUANTITY_SUCCESS,
} from './actions';


// Google Tag Manager

const gtmCartWidgetOpen = () => ({
  event: 'SendEvent',
  eventCategory: 'Cart Widget',
  eventAction: 'Open',
});

const gtmCartWidgetClose = () => ({
  event: 'SendEvent',
  eventCategory: 'Cart Widget',
  eventAction: 'Close',
});

const toGtmProduct = (item: Item) => {
  const coupon = item.coupon
  return {
    item_name: item.name.toString(),
    item_id: item.sku.toString(),
    price: parseFloat((item.line_price_rpa/item.quantity).toFixed(2)),
    quantity: parseInt(item.diff),
    discount: Math.abs(parseFloat((item.msrp - item.line_price_rpa/item.quantity).toFixed(2))),
    coupon: coupon ? coupon.toString() : null,
    item_category: item.category.toString(),
    in_stock: item.inventory > 0,
  }
}


const gtmCartEvent = (event: string, currency: string, products) => {
  const wholeValue = products.reduce((itemValue, item) => itemValue + (item.price * item.quantity), 0).toFixed(2)
  const items = products.length > 1 ? products : products[0] // no array when single item add
  
  return {
    event,
    ecommerce: {
      currency: currency.toString(),
      value: parseFloat(wholeValue),
      items: items ,
    },
  };
};

const gtmAddToCart = (action: CartAddProductSuccessAction) => {
  
  const products = action.data.items
    .filter((item: Item) => item.diff !== null)
    .map((item: Item) => ({ ...item, coupon: action.data.coupon_code}))
    .map(toGtmProduct);
  return gtmCartEvent('add_to_cart', action.data.currency, products);
};

const gtmSetQuantity = (
  action: CartSetQuantitySuccessAction,
  prevState: { cart: CartState },
) => {
  if (action.quantity === 0) {
    // Something has been removed, we need to find the removed item in
    // the prevState as it won't be returned in the json response.
    const products = prevState.cart.data.items
      .filter((item: Item) => item.product === action.productId)
      .map((item: Item) => ({...item, diff: Math.abs(item.quantity), coupon: prevState.cart.data.coupon_code }))
      .map(toGtmProduct)
      .map((p) => ({...p, quantity: Math.abs(p.quantity)}));
    return gtmCartEvent('remove_from_cart', action.data.currency, products);
  }
  // Something may have added or removed, we need to find the
  // items that have a diff.
  const products = action.data.items
    .filter((item: Item) => item.diff !== null)
    .map((item: Item) => ({ ...item, coupon: action.data.coupon_code}))
    .map(toGtmProduct);

  // Split into added and removed. Removed items get a negative diff, so be sure to turn it around.
  const added = products.filter(p => p.quantity > 0);

  const removed = products.filter(p => p.quantity < 0)
    .map(p => ({ ...p, quantity: Math.abs(p.quantity) }));

  const events = [];
  if (added.length > 0) {
    events.push(gtmCartEvent('add_to_cart', action.data.currency, added));
  }
  if (removed.length > 0) {
    events.push(gtmCartEvent('remove_from_cart', action.data.currency, removed));
  }
  return events;
};


export function gtmCartWidgetEvents(action: CartAction) {
  switch (action.type) {
    case CART_OPEN:
      return [gtmCartWidgetOpen];

    case CART_CLOSE:
      return [gtmCartWidgetClose];

    case CART_ADD_PRODUCT_SUCCESS:
      return [gtmAddToCart];

    case CART_SET_QUANTITY_SUCCESS:
      return [gtmSetQuantity];

    default:
      return [];
  }
}

// Helper Function
const buildProperties = (cart: Cart, formatCurrency) => {
  const fmt = value => formatCurrency(value, cart.currency);

  const products = cart.items.map((item, index) => ({
    product_id: String(item.product),
    sku: item.sku,
    category: item.category, // TODO
    name: item.name,
    variant: item.choice_name,
    msrp: item.msrp,
    msrp_formatted: fmt(item.msrp),
    line_msrp: item.line_msrp,
    line_msrp_formatted: fmt(item.line_msrp),
    price: item.price,
    price_formatted: fmt(item.price),
    line_price: item.line_price,
    line_price_formatted: fmt(item.line_price),
    quantity: item.quantity,
    coupon: null, // TODO
    position: index + 1,
    url: null, // TODO
    image_url: item.medium_thumb_url,
    in_stock: item.inventory > 0,
  }));

  const properties = {
    method: 'widget',
    empty: cart.is_empty,
    cart_id: cart.id,
    country: cart.country,
    region: cart.region,
    currency: cart.currency,
    total: cart.total,
    total_formatted: fmt(cart.total),
    estimated_shipping_price_base: cart.estimated_shipping_price_base,
    estimated_shipping_price_base_formatted: fmt(cart.estimated_shipping_price_base),
    estimated_total_base: cart.estimated_total_base,
    estimated_total_base_formatted: fmt(cart.estimated_total_base),
    products,
  };
  return properties;
};

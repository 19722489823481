import { playMedia } from "../utils/playPauseMedia";

declare var Waypoint: any;

// video state classes
const PLAYING_CLASS = 'is-playing';
const ENDED_CLASS = 'is-ended';

type Effect = {
  id: number,
  fx: string,
  size: string,
  before_color: string,
  after_color: string,
  is_play_video: boolean,
};

// implementation
export default class AnimationFX {
  container: HTMLElement;
  child: HTMLElement;
  videoContainer: HTMLElement;
  video: HTMLVideoElement;
  trigger: string;
  triggerTarget: HTMLElement;
  speed: number;
  timingFunc: string;
  effects: Effect[];

  constructor(
    container: HTMLElement,
    child: HTMLElement,
    trigger: string,
    triggerTarget: HTMLElement,
    speed: number,
    timingFunc: string,
    effects: Effect[],
  ) {
    this.container = container;
    this.child = child;
    this.trigger = trigger;
    this.triggerTarget = triggerTarget;
    this.speed = speed;
    this.timingFunc = timingFunc;
    this.effects = effects;

    const validTriggers = ['hover', 'click', 'inView'];

    if (!validTriggers.includes(this.trigger)) {
      return;
    }
    if (this.speed <= 0) {
      return;
    }
    if (this.effects.length <= 0) {
      return;
    }
    this.child.style.setProperty('--fx-animation-speed', `${this.speed}ms`);
    this.child.style.setProperty(
      '--fx-animation-timing-function',
      `${this.timingFunc}`,
    );
    this.child.classList.add('fx-animation');

    // EVENTS
    if (this.trigger === 'hover') {
      this.triggerTarget.addEventListener('mouseenter', () => {
        this.handleMouseEnter();
      });

      this.triggerTarget.addEventListener('mouseleave', () => {
        this.handleMouseLeave();
      });
    }

    if (this.trigger === 'click') {
      this.triggerTarget.addEventListener('click', () => {
        this.handleClick();
      });
    }

    if (this.trigger === 'inView') {
      this.handleInView();
    }

    this.handleEffects();
  }

  handleMouseEnter() {
    this.child.classList.add('fx-active');

    // might add a "boop" option that plays the transition once and then timesout
    // setTimeout(() => {
    //   child.classList.remove('fx-active');
    // }, speed);
    if (this.video) {
      if (this.video.paused) {
        playMedia(this.video);
        this.videoContainer.classList.add(PLAYING_CLASS);
        this.videoContainer.classList.remove(ENDED_CLASS);
      }
    }
  }

  handleMouseLeave() {
    this.child.classList.remove('fx-active');

    if (this.video) {
      if (!this.video.paused) {
        this.video.pause();
        this.videoContainer.classList.remove(PLAYING_CLASS);
      }
    }
  }

  handleClick() {
    if (this.child.classList.contains('fx-active')) {
      this.child.classList.remove('fx-active');
    } else {
      this.child.classList.add('fx-active');
    }

    if (this.video) {
      if (this.video.paused) {
        playMedia(this.video);
        this.videoContainer.classList.add(PLAYING_CLASS);
        this.videoContainer.classList.remove(ENDED_CLASS);
      } else {
        this.video.pause();
        this.videoContainer.classList.remove(PLAYING_CLASS);
      }
    }
  }

  handleEffects() {
    const willChange: Array<string> = [];

    this.effects.forEach((effect) => {
      this.child.classList.add(`fx-${effect.fx}`);

      // build out the will-change css value
      if (effect.fx === 'scale') {
        willChange.push('transform');
      }
      if (effect.fx === 'bg-color') {
        willChange.push('background-color');
      }

      // SCALE
      if (effect.size) {
        this.child.style.setProperty('--fx-size', effect.size);
      }

      // BACKGROUND COLOR
      if (effect.before_color && effect.after_color) {
        this.child.style.setProperty('--fx-color-before', effect.before_color);
        this.child.style.setProperty('--fx-color-after', effect.after_color);
      }

      // PLAY VIDEO
      if (effect.is_play_video) {
        // grab video variables if they are there
        const videoContainer = this.container.querySelector('.video');
        const video = this.container.querySelector('video');
        if (
          videoContainer instanceof HTMLElement &&
          video instanceof HTMLVideoElement
        ) {
          this.videoContainer = videoContainer;
          this.video = video;
        }
        if (this.video)
          this.video.setAttribute('data-video-type', 'parentAnimation');
      }
    });

    this.child.style.setProperty('--fx-will-change', willChange.toString());
  }

  handleInView() {
    // animate when 75% is visible in viewport
    // DOWN
    // eslint-disable-next-line no-unused-vars
    const waypointDown = new Waypoint({
      element: this.container,
      offset: 'bottom-in-view',
      handler: (direction: string) => {
        if (direction === 'down') {
          this.child.classList.add('fx-active');
        }
      },
    });
    // UP
    // eslint-disable-next-line no-unused-vars
    const waypointUp = new Waypoint({
      element: this.container,
      offset: () => this.container.clientHeight,
      handler: (direction: string) => {
        if (direction === 'up') {
          this.child.classList.add('fx-active');
        }
      },
    });
    // OUT OF VIEW
    // eslint-disable-next-line no-unused-vars
    const waypointDownOut = new Waypoint({
      element: this.container,
      offset: () => -this.container.clientHeight,
      handler: (direction: string) => {
        if (direction === 'down') {
          this.child.classList.remove('fx-active');
        }
      },
    });
    // eslint-disable-next-line no-unused-vars
    const waypointUpOut = new Waypoint({
      element: this.container,
      offset: 'bottom-in-view',
      handler: (direction: string) => {
        if (direction === 'up') {
          this.child.classList.remove('fx-active');
        }
      },
    });
  }
}

import produce from 'immer';
import axios from 'axios';
import type { AxiosPromise } from 'axios';
import type { Store } from 'redux';
import type { Dispatch, Thunk } from '../types';

// Types

export type Result = {
  id: number,
  name: string,
  type: string,
  page_url: string,
  image_url: string,
}

export type SearchState = {
  open: boolean,
  loading: boolean,
  scrollIndex: number,
  lastQuery: string,
  results: Array<Result>,
}


export type SearchOpenAction = {
  type: 'SEARCH_OPEN',
};

export type SearchCloseAction = {
  type: 'SEARCH_CLOSE',
};

export type SearchToggleAction = {
  type: 'SEARCH_TOGGLE',
};

export type SearchResetAction = {
  type: 'SEARCH_RESET',
};

export type SearchFetchRequestAction = {
  type: 'SEARCH_FETCH_REQUEST',
    query: string,
};

export type SearchFetchSuccessAction = {
  type: 'SEARCH_FETCH_SUCCESS',
    query: string,
      results: Array < Result >,
};

export type SearchFetchFailureAction = {
  type: 'SEARCH_FETCH_FAILURE',
    error: Object,
};

export type SearchFocusAction = {
  type: 'SEARCH_FOCUS',
    scrollIndex: number,
};

export type SearchAction =
  | SearchOpenAction
  | SearchCloseAction
  | SearchToggleAction
  | SearchResetAction
  | SearchFocusAction
  | SearchFetchRequestAction
  | SearchFetchSuccessAction
  | SearchFetchFailureAction;


// API

export function getSearchResults(query: string): AxiosPromise<Array<Result>> {
  return axios.get('/api/v1/search', {
    params: { q: query },
  });
}


// Actions

const SEARCH_OPEN = 'SEARCH_OPEN';
const SEARCH_CLOSE = 'SEARCH_CLOSE';
const SEARCH_TOGGLE = 'SEARCH_TOGGLE';
const SEARCH_RESET = 'SEARCH_RESET';
const SEARCH_FOCUS = 'SEARCH_FOCUS';
const SEARCH_FETCH_REQUEST = 'SEARCH_FETCH_REQUEST';
const SEARCH_FETCH_SUCCESS = 'SEARCH_FETCH_SUCCESS';
const SEARCH_FETCH_FAILURE = 'SEARCH_FETCH_FAILURE';


export function openSearch(): SearchOpenAction {
  return {
    type: SEARCH_OPEN,
  };
}

export function closeSearch(): SearchCloseAction {
  return {
    type: SEARCH_CLOSE,
  };
}

export function toggleSearch(): SearchToggleAction {
  return {
    type: SEARCH_TOGGLE,
  };
}

export function resetSearch(): SearchResetAction {
  return {
    type: SEARCH_RESET,
  };
}

export function scrollSearch(scrollIndex: number): SearchFocusAction {
  return {
    type: SEARCH_FOCUS,
    scrollIndex,
  };
}

export function fetchSearchRequest(query: string): SearchFetchRequestAction {
  return {
    type: SEARCH_FETCH_REQUEST,
    query,
  };
}

export function fetchSearchSuccess(
  query: string,
  results: Array<Result>,
): SearchFetchSuccessAction {
  return {
    type: SEARCH_FETCH_SUCCESS,
    query,
    results,
  };
}

export function fetchSearchFailure(error: Object): SearchFetchFailureAction {
  return {
    type: SEARCH_FETCH_FAILURE,
    error,
  };
}

export function fetchSearch(query: string): Thunk {
  return (dispatch) => {
    dispatch(fetchSearchRequest(query));
    return getSearchResults(query)
      .then(
        response => dispatch(fetchSearchSuccess(query, response.data)),
        error => dispatch(fetchSearchFailure(error)),
      );
  };
}


// State

const initialState = {
  open: false,
  loading: false,
  scrollIndex: 0,
  lastQuery: '',
  results: [],
};


// Reducer

export function searchReducer(
  state: SearchState = initialState,
  action: SearchAction,
): SearchState {
  switch (action.type) {
    case SEARCH_OPEN:
      return produce(state, (draft) => {
        draft.open = true;
      });

    case SEARCH_CLOSE:
      return produce(state, (draft) => {
        draft.open = false;
      });

    case SEARCH_TOGGLE:
      return produce(state, (draft) => {
        draft.open = !draft.open;
      });

    case SEARCH_RESET:
      return produce(state, (draft) => {
        draft.lastQuery = '';
        draft.scrollIndex = 0;
        draft.results = [];
      });

    case SEARCH_FOCUS:
      const { scrollIndex } = action;
      return produce(state, (draft) => {
        draft.scrollIndex = scrollIndex;
      });

    case SEARCH_FETCH_REQUEST:
      const { query } = action;
      return produce(state, (draft) => {
        draft.loading = true;
        draft.lastQuery = query;
      });

    case SEARCH_FETCH_SUCCESS:
      const { results } = action;
      return produce(state, (draft) => {
        draft.loading = false;
        draft.scrollIndex = 0;
        draft.results = results;
      });

    default:
      return state;
  }
}


// Analytics

export function gtmSearchEvents(action: SearchAction) {
  switch (action.type) {
    case SEARCH_OPEN:
    case SEARCH_CLOSE:
    case SEARCH_TOGGLE:
      return (
        act: SearchAction,
        prevState: { search: SearchState },
        nextState: { search: SearchState },
      ) => ({
        event: 'SendEvent',
        eventCategory: 'Search',
        eventAction: nextState.search.open ? 'Open' : 'Close',
      });

    case SEARCH_RESET:
      return () => ({
        event: 'SendEvent',
        eventCategory: 'Search',
        eventAction: 'Reset',
      });

    case SEARCH_FETCH_SUCCESS:
      return (act: SearchFetchSuccessAction) => ({
        event: 'SendEvent',
        eventCategory: 'Search',
        eventAction: 'Completed',
        eventLabel: act.query,
      });

    case SEARCH_FETCH_FAILURE:
      return (act: SearchFetchFailureAction) => ({
        event: 'SendEvent',
        eventCategory: 'Search',
        eventAction: 'Error',
        eventLabel: act.error.message,
      });

    default:
      return [];
  }
}

// DOM

export function addSearchEventListeners(
  document: Document,
  store: Store<{ search: SearchState }, SearchAction, Dispatch>,
) {
  // Bind clicks on search toggles
  Array.from(document.querySelectorAll('.js-search-toggle')).forEach((el) => {
    el.addEventListener('click', (event: MouseEvent) => {
      // Hide navigation on mobile
      const navList = document.querySelector('.category-navigation');
      if (navList instanceof HTMLElement) {
        if (navList.classList.contains('is-visible')) {
          navList.classList.remove('is-visible');
          const mainNav = document.querySelector('.main-navigation');
          if (mainNav instanceof HTMLElement) {
            mainNav.classList.remove('menu-open');
          }
        }
        else {
          // Open search
          store.dispatch(toggleSearch());
        }
        event.stopImmediatePropagation();
        event.preventDefault();
      }
    });
  });

  // Clicking anywhere outside of the search will close it
  document.addEventListener('click', (event: MouseEvent) => {
    const { target } = event;
    if (target instanceof Element && target.parentNode !== null) {
      if (!target.closest('#js-search-container')) {
        if (store.getState().search.open) {
          store.dispatch(closeSearch());
        }
      }
    }
  }, { passive: true });
}

/* eslint-disable no-new */
import type { Canceler } from 'axios';
import axios from 'axios';
import serialize from 'form-serialize';
import debounce from 'lodash.debounce';
import Glide from '@glidejs/glide';
import Tooltip from 'tooltip.js';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
const createFocusTrap = require('focus-trap');

import type { Facets } from './FacetList';
import FacetList from './FacetList';


// Main class

export default class FacetedProductList {
  container: HTMLElement;
  facets: FacetList;
  temporaryFacets: FacetList;
  isOverlay: boolean;
  cancel: null | Canceler;
  debouncedLoad: (scrollToTop: boolean) => void;
  deactivateFocusTrap: () => void;
  basePath: string;

  constructor(
    listContainer: HTMLElement,
    facets: Facets,
    basePath: string,
    page: number,
    sort: string,
    phrase: string = "",
  ) {
    this.container = listContainer;
    this.container.classList.add('has-js');
    this.basePath = basePath;
    this.facets = new FacetList(
      basePath,
      facets,
      page,
      sort,
      phrase,
    );
    this.temporaryFacets = this.facets.copy();

    this.debouncedLoad = debounce(
      (scrollToTop: boolean) => this.load(scrollToTop),
      200,
    );

    this.container.addEventListener('click', (event: MouseEvent) =>
      this.handleClick(event),
    );
    this.container.addEventListener('submit', (event: Event) =>
      this.handleSubmit(event),
    );
    this.container.addEventListener('change', (event: Event) =>
      this.handleChange(event),
    );

    Array.from(
      this.container.querySelectorAll(
        '.catalog-list-product__wrapper',
      ),
    ).forEach((el) => {
      if (!el) return;

      const glide = el.querySelector(
        '.glide'
      );

      if (!glide) return;

      el.addEventListener('mouseenter', () => this.initGlide(glide));
      el.addEventListener('focus', () => {
        this.initGlide(glide);
      });
    });

    this.initTooltips();
  }

  // Standard event handlers

  handleClick(event: MouseEvent) {
    // Make sure we're dealing with a link or a button
    const { target } = event;
    if (!(target instanceof Element)) {
      return;
    }
    const isLinkOrButton =
      target instanceof HTMLAnchorElement ||
      target instanceof HTMLButtonElement;
    const element = isLinkOrButton ? target : target.closest('a, button');
    if (
      !(element instanceof HTMLAnchorElement) &&
      !(element instanceof HTMLButtonElement)
    ) {
      return;
    }

    // Get the action
    const { action } = element.dataset;
    switch (action) {
      case 'toggleConstraint':
        this.toggleConstraint(event, element);
        break;

      case 'removeConstraint':
        this.removeConstraint(event, element);
        break;

      case 'applyConstraints':
        this.applyConstraints(event);
        break;

      case 'toggleFacet':
        this.toggleFacet(event, element);
        break;

      case 'paginate':
        this.paginate(event, element);
        break;

      case 'openMobileFilter':
        this.openMobileFilter(event);
        break;

      case 'closeMobileFilter':
        this.closeMobileFilter(event);
        break;

      default:
        break;
    }
  }

  handleSubmit(event: Event) {
    const { target } = event;
    if (!(target instanceof HTMLFormElement)) return;

    if (target.matches('.js-search-phrase-form')) {
      return this.handlePhraseSubmit(event);

    }

    if (target.matches('.js-sorting-form')) {
      this.sort(event, target);
    }
  }

  handleChange(event: Event) {
    const { target } = event;
    if (
      !(target instanceof HTMLSelectElement) ||
      target.id !== 'js-sorting-select'
    ) {
      return;
    }
    const form = target.closest('form');
    if (!(form instanceof HTMLFormElement)) {
      return;
    }
    this.sort(event, form);
  }

  handlePhraseSubmit(event) {
    event.preventDefault();
    const inputElement = event.target.querySelector('.js-search-phrase-input');
    this.temporaryFacets = this.temporaryFacets.clearSelection().withPhrase(inputElement.value);
    this.facets = this.temporaryFacets.copy();
    this.load(true);
  }

  // Sorting

  sort(event: Event, form: HTMLFormElement) {
    const { sort } = serialize(form, { hash: true });
    if (sort === null || sort === undefined) {
      return;
    }
    this.temporaryFacets = this.facets.withSort(sort.toString());
    this.facets = this.temporaryFacets.copy();
    this.load(true);
    event.preventDefault();
  }

  // Pagination

  paginate(event: Event, link: HTMLAnchorElement | HTMLButtonElement): void {
    const { page } = link.dataset;
    const pageNumber = parseInt(page, 10);
    if (Number.isNaN(pageNumber)) {
      return;
    }
    this.temporaryFacets = this.facets.withPage(pageNumber);
    this.facets = this.temporaryFacets.copy();
    this.load(true);
    event.preventDefault();
  }

  // Constraints

  toggleConstraint(event: Event, link: HTMLAnchorElement | HTMLButtonElement) {
    const { facet, constraint } = link.dataset;
    if (facet === undefined || constraint === undefined) {
      return;
    }

    this.temporaryFacets = this.temporaryFacets
      .withConstraintToggled(facet, constraint)
      .withPage(1);

    if (this.isOverlay) {
      // If we're in overlay mode, we will update display, but not apply facets yet.
      this.updateConstraints(this.temporaryFacets);
    } else {
      // If we're in regular mode, we update facets immediately and apply
      this.facets = this.temporaryFacets.copy();
      this.updateConstraints(this.facets);
      this.debouncedLoad(false);
    }
    event.preventDefault();
  }

  removeConstraint(event: Event, link: HTMLAnchorElement | HTMLButtonElement) {
    const { facet, constraint } = link.dataset;
    if (facet === undefined || constraint === undefined) {
      return;
    }

    this.temporaryFacets = this.facets
      .withConstraintRemoved(facet, constraint)
      .withPage(1);

    this.facets = this.temporaryFacets.copy();
    this.updateConstraints(this.facets);
    this.load(false);
    event.preventDefault();
  }

  applyConstraints(event: Event) {
    const { target } = event;
    if (!(target instanceof HTMLElement)) {
      return;
    }
    target.classList.add('is-loading');
    const inputElement = this.container.querySelector('.js-search-phrase-input');
    if (inputElement) {
      this.temporaryFacets = this.temporaryFacets.clearSelection().withPhrase(inputElement.value);
    }
    this.facets = this.temporaryFacets.copy();
    this.debouncedLoad(false);
    this.closeMobileFilter();
    event.preventDefault();
  }

  // Filter menu

  openMobileFilter(event?: Event) {
    this.container.classList.add('has-sidebar-overlay');
    this.isOverlay = true;

    // Add a scroll lock around the overlay element
    const sidebar = this.container.querySelector(
      '.js-faceted-product-list-overlay',
    );
    if (sidebar instanceof HTMLElement) {
      disableBodyScroll(sidebar);
    }

    // Create a focus trap
    const focusTrap = createFocusTrap(sidebar, {
      escapeDeactivates: false,
    });
    focusTrap.activate();
    this.deactivateFocusTrap = () => {
      focusTrap.deactivate();
      this.deactivateFocusTrap = null;
    };

    if (event) {
      event.preventDefault();
    }
  }

  closeMobileFilter(event?: Event) {
    this.container.classList.remove('has-sidebar-overlay');
    this.isOverlay = false;
    if (this.deactivateFocusTrap) {
      this.deactivateFocusTrap();
    }
    clearAllBodyScrollLocks();
    if (event) {
      event.preventDefault();
    }
  }

  // Facets

  toggleFacet(event: Event, link: HTMLAnchorElement | HTMLButtonElement) {
    const facetElement = link.closest('.facet--collapsible');
    if (!(facetElement instanceof HTMLElement)) {
      return;
    }

    const { facet } = facetElement.dataset;

    this.temporaryFacets = this.facets.withFacetToggled(facet);
    this.facets = this.temporaryFacets.copy();
    this.updateAllFacets();
    this.replaceState(this.facets.getUrl().stringify());
    event.preventDefault();
  }

  // Facet display

  updateAllFacets() {
    Array.from(this.container.querySelectorAll('.facet')).forEach((element) => {
      const { facet } = (element as HTMLElement).dataset;
      const collapsed = this.facets.isFacetCollapsed(facet);
      const toggle = element.querySelector('.js-facet-toggle');
      const isLinkOrButton =
        toggle instanceof HTMLAnchorElement ||
        toggle instanceof HTMLButtonElement;
      if (!isLinkOrButton) {
        return;
      }
      const controls = toggle.getAttribute('aria-controls');
      if (!controls) {
        return;
      }
      const content = document.getElementById(controls);
      if (!(content instanceof HTMLElement)) {
        return;
      }

      if (collapsed) {
        element.classList.add('is-collapsed');
        toggle.setAttribute('aria-expanded', 'false');
        content.setAttribute('aria-hidden', 'true');
        Array.from(content.querySelectorAll('a')).forEach((el) =>
          el.setAttribute('tabindex', '-1'),
        );
      } else {
        element.classList.remove('is-collapsed');
        toggle.setAttribute('aria-expanded', 'true');
        content.setAttribute('aria-hidden', 'false');
        Array.from(content.querySelectorAll('a')).forEach((el) =>
          el.removeAttribute('tabindex'),
        );
      }
    });
  }

  // Constraint display

  updateConstraints(facets: FacetList) {
    Array.from(this.container.querySelectorAll('.constraint')).forEach((el) =>
      this.updateConstraint(el as HTMLElement, facets),
    );
  }

  updateConstraint(element: HTMLElement, facets: FacetList) {
    const { facet, constraint } = element.dataset;

    // Update selected
    const selected = facets.isConstraintSelected(facet, constraint);
    if (selected) {
      element.classList.add('is-selected');
    } else {
      element.classList.remove('is-selected');
    }

    // Update count and/or unavailable
    const count = facets.getConstraintCount(facet, constraint);
    if (count > 0) {
      element.classList.remove('is-disabled');
    } else {
      element.classList.add('is-disabled');
    }

    const countElement = element.querySelector('.constraint__count');
    if (countElement instanceof HTMLElement) {
      countElement.innerText = `(${count})`;
    }
  }

  // Loading from server

  load(scrollToTop: boolean) {
    // Cancel pending request
    if (this.cancel) {
      this.cancel();
    }

    // Prepare new request
    const url = this.facets.getUrl();
    const options = {
      cancelToken: new axios.CancelToken((cancel) => {
        this.cancel = cancel;
      }),
    };

    this.container.classList.add('is-loading');
    axios
      .get(url.partial(), options)
      .then((response) => {
        this.cancel = null;

        // TODO: set title?
        // TODO: fire pageview?

        const phraseInput = document.querySelector('.js-search-phrase-input');
        if (phraseInput) {
          this.facets = new FacetList(
            this.basePath,
            response.data.new_facets,
            1,
            response.data.sort,
            response.data.phrase,
          );
          this.temporaryFacets = this.facets.copy();
          this.container.innerHTML = response.data.body;
        } else {
          this.container.innerHTML = response.data;
        }

        this.container.classList.remove('is-loading');
        this.replaceState(url.stringify());

        // set canonical URLs
        const canonicalLink = document.querySelector('link[rel="canonical"]');
        if (!(canonicalLink instanceof HTMLElement)) return;

        const canonicalDomainURL = canonicalLink.getAttribute('data-domain');
        const newCanonicalURL = `${location.protocol}//${canonicalDomainURL}${location.pathname}`;
        canonicalLink.setAttribute('href', newCanonicalURL);

        const alternateLinks = Array.from(
          document.querySelectorAll('link[rel="alternate"]'),
        );
        alternateLinks.forEach((link) => {
          const domainURL = link.getAttribute('data-domain');
          const newURL = `${location.protocol}//${domainURL}${location.pathname}${location.search}`;
          link.setAttribute('href', newURL);
        });

        this.initTooltips();
        if (scrollToTop) {
          window.scrollTo(0, 0);
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log('Cancelled!');
        }
      });
  }

  // State handling

  replaceState(url: string) {
    window.history.replaceState({}, document.title, url);
  }

  // Tooltips

  initTooltips() {
    Array.from(
      this.container.querySelectorAll(
        '.glide__slides .js-product-list-tooltip',
      ),
    ).forEach((el) => {
      const tooltipTitle = el.getAttribute('data-title');
      if (tooltipTitle) {
        new Tooltip(el as HTMLElement, {
          title: tooltipTitle,
          placement: 'top', // or bottom, left, right, and variations
          offset: '0, 8px',
          delay: { show: 250, hide: 0 },
          container: document.body,
          boundariesElement: document.body,
          arrowSelector: '.tooltip__arrow',
          innerSelector: '.tooltip__inner',
          template:
            '<div class="tooltip" role="tooltip"><div class="tooltip__arrow"></div><div class="tooltip__inner"></div></div>',
        });
        el.removeAttribute('data-title');
      }
    });
  }

  // Related carousel

  initGlide(glide: Element) {
    const variants = Array.from(glide.querySelectorAll('.glide__slide'));
    const variantControls = glide.querySelector('.glide__arrows');
    const controlsRight = glide.querySelector('.glide__arrow--right');
    const controlsLeft = glide.querySelector('.glide__arrow--left');

    if (
      variantControls === null ||
      controlsRight === null ||
      controlsLeft === null
    ) {
      return;
    }

    if (variants.length <= 4) {
      variantControls.classList.add('hidden');
    } else {
      variantControls.classList.remove('hidden');
    }

    const glideOptions = {
      perView: 4,
      gap: 2,
      bound: true,
      rewind: false,
      dragThreshold: false,
      swipeThreshold: false
    };
    const variantCarousel = new (Glide as any)(glide, glideOptions);

    // Hides leftmost arrow control at beginning and rightmost at end
    variantCarousel.on(['mount.after', 'run'], () => {
      if (variants.length > 4) {
        if (variantCarousel.index === 0) {
          controlsLeft.classList.add('hidden');
          controlsRight.classList.remove('hidden');
        } else if (variantCarousel.index >= variants.length - 4) {
          controlsRight.classList.add('hidden');
          controlsLeft.classList.remove('hidden');
        } else {
          controlsRight.classList.remove('hidden');
          controlsLeft.classList.remove('hidden');
        }
      }
    });

    variantCarousel.mount();
  }
}

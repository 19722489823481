/*
*   Controls tabular content sections, AKA: tabs

*   Part of this content is licensed according to the W3C Software License at
*   https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
*/

export default function createTabs() {
  function initTabs(target) {
    // For easy reference
    const keys = {
      end: 35,
      home: 36,
      left: 37,
      up: 38,
      right: 39,
      down: 40,
      delete: 46,
    };

    // Add or substract depending on key pressed
    const direction = {
      37: -1,
      38: -1,
      39: 1,
      40: 1,
    };

    const tablist = target.querySelector('[role="tablist"]');
    if (!(tablist instanceof HTMLElement)) return;

    let tabs = [];
    let panels = [];

    function generateArrays() {
      tabs = Array.from(target.querySelectorAll('[role="tab"]'));
      panels = Array.from(target.querySelectorAll('[role="tabpanel"]'));
    }

    generateArrays();

    // Only activate tab on focus if it still has focus after the delay
    function checkTabFocus(el) {
      const focused = document.activeElement;

      if (el === focused) {
        activateTab(el, false);
      }
    }

    //
    function focusEventHandler(event) {
      const e = event.target;

      checkTabFocus(e);
    }

    // Deactivate all tabs and tab panels
    function deactivateTabs() {
      for (let t = 0; t < tabs.length; t += 1) {
        tabs[t].setAttribute('tabindex', '-1');
        tabs[t].setAttribute('aria-selected', 'false');
        tabs[t].removeEventListener('focus', (event: KeyboardEvent) => {
          focusEventHandler(event);
        });
      }

      for (let p = 0; p < panels.length; p += 1) {
        panels[p].setAttribute('hidden', 'hidden');
      }
    }

    // Activates any given tab panel
    function activateTab(tab, setFocus) {
      if (!(tab instanceof HTMLElement)) return;

      const focus = setFocus || true;

      // Deactivate all other tabs
      deactivateTabs();

      // Remove tabindex attribute
      tab.removeAttribute('tabindex');

      // Set the tab as selected
      tab.setAttribute('aria-selected', 'true');

      // Get the value of aria-controls (which is an ID)
      const controls = tab.getAttribute('aria-controls');
      if (!(typeof controls === 'string')) return;

      // Remove hidden attribute from tab panel to make it visible
      const panel = document.getElementById(controls);
      if (!(panel instanceof HTMLElement)) return;

      panel.removeAttribute('hidden');

      // Set focus when required
      if (focus) {
        tab.focus();
      }
    }

    // Make a guess
    function focusFirstTab() {
      tabs[0].focus();
    }

    // Make a guess
    function focusLastTab() {
      tabs[tabs.length - 1].focus();
    }

    // Either focus the next, previous, first, or last tab
    // depening on key pressed
    function switchTabOnArrowPress(event: KeyboardEvent) {
      const pressed = event.keyCode;

      for (let x = 0; x < tabs.length; x += 1) {
        tabs[x].addEventListener('focus: KeyboardEvent', focusEventHandler);
      }

      if (direction[pressed]) {
        const el = event.target;

        if (el.index === undefined) return;

        if (tabs[el.index + direction[pressed]]) {
          tabs[el.index + direction[pressed]].focus();
        } else if (pressed === keys.left || pressed === keys.up) {
          focusLastTab();
        } else if (pressed === keys.right || pressed === keys.down) {
          focusFirstTab();
        }
      }
    }

    // When a tablist's aria-orientation is set to vertical,
    // only up and down arrow should function.
    // In all other cases only left and right arrow function.
    function determineOrientation(event: KeyboardEvent) {
      const key = event.keyCode;
      const vertical = tablist.getAttribute('aria-orientation') === 'vertical';
      let proceed = false;

      if (vertical) {
        if (key === keys.up || key === keys.down) {
          event.preventDefault();
          proceed = true;
        }
      } else if (!vertical) {
        if (key === keys.left || key === keys.right) {
          proceed = true;
        }
      }

      if (proceed) {
        switchTabOnArrowPress(event);
      }
    }

    // When a tab is clicked, activateTab is fired to activate it
    function clickEventListener(event: MouseEvent) {
      const tab = event.target;
      activateTab(tab, false);
    }

    // Handle keydown on tabs
    function keydownEventListener(event: KeyboardEvent) {
      const key = event.keyCode;

      switch (key) {
        case keys.end:
          event.preventDefault();
          // Activate last tab
          activateTab(tabs[tabs.length - 1]);
          break;
        case keys.home:
          event.preventDefault();
          // Activate first tab
          activateTab(tabs[0]);
          break;

        // Up and down are in keydown
        // because we need to prevent page scroll >:)
        case keys.up:
        case keys.down:
          determineOrientation(event);
          break;
        default:
          break;
      }
    }

    // Handle keyup on tabs
    function keyupEventListener(event: KeyboardEvent) {
      const key = event.keyCode;

      switch (key) {
        case keys.left:
        case keys.right:
          determineOrientation(event);
          break;
        default:
          break;
      }
    }

    // Bind listeners
    function addListeners(index) {
      tabs[index].addEventListener('click', (event: MouseEvent) => {
        clickEventListener(event);
      });
      tabs[index].addEventListener('keydown', (event: KeyboardEvent) => {
        keydownEventListener(event);
      });
      tabs[index].addEventListener('keyup', (event: KeyboardEvent) => {
        keyupEventListener(event);
      });

      tabs[index].index = index;
    }

    for (let i = 0; i < tabs.length; i += 1) {
      addListeners(i);
    }
  }

  const tabs = Array.from(document.querySelectorAll('.js-tabs'));
  tabs.forEach(initTabs);
}

// Types

type Options = {
  targetSelector?: string,
  inputSelector?: string,
  activeClass?: string,
  contentSelector?: string,
  onChange?: (value: HTMLElement) => void,
}

// Implementation
// TODO: handle a checkbox version of this

export default function choiceBox(
  container: HTMLElement | string,
  {
    targetSelector,
    inputSelector = 'input[type="radio"]',
    activeClass = 'is-active',
    contentSelector = '.choice-box__extra',
    onChange,
  }: Options = {},
) {
  // Handle getting a selector instead of an element
  let containerElement = container;
  if (typeof container === 'string') {
    containerElement = document.querySelector(container);
  }

  // Make sure our element is an actual DOM element
  if (!(containerElement instanceof HTMLElement)) {
    // TODO: throw error?
    return;
  }

  // If there are no target selector, we just use immediate children
  let targets;
  if (targetSelector === null || targetSelector === undefined) {
    targets = Array.from(containerElement.children);
  } else {
    targets = Array.from(containerElement.querySelectorAll(targetSelector));
  }

  const updateTarget = (target: HTMLElement, selected: boolean) => {
    let ariaExpanded;
    let ariaHidden;
    let ariaSelected;
    if (selected) {
      ariaHidden = 'false';
      ariaExpanded = 'true';
      ariaSelected = 'true';
      target.classList.add(activeClass);
    } else {
      ariaHidden = 'true';
      ariaExpanded = 'false';
      ariaSelected = 'false';
      target.classList.remove(activeClass);
    }

    // Set aria tags on extra content
    const content = target.querySelector(contentSelector);
    if (content instanceof HTMLElement) {
      content.setAttribute('aria-hidden', ariaHidden);
      if (target.getAttribute('data-adjust-tabindex') !== 'false') {
        const focusable = Array.from(content.querySelectorAll('button, select, input, a'));
        focusable.forEach((el: HTMLElement) => {
          if (selected) {
            el.removeAttribute('tabindex');
          } else {
            el.setAttribute('tabindex', '-1');
          }
        });
      }
    }

    // Set aria tags on input
    const input = target.querySelector(inputSelector);
    if (input instanceof HTMLInputElement) {
      input.setAttribute('aria-selected', ariaSelected);
    }
  };

  // Add event listeners to inputs
  // Doing it this way means we don't have to dynamically find
  // the target that belongs to a radio button, since it's in scope
  // in the closure.
  targets.forEach((target) => {
    const input = target.querySelector(inputSelector);

    if (input && input instanceof HTMLInputElement) {
      input.addEventListener('change', () => {
        // Disable other targets
        targets.filter(t => t !== target).forEach(t => updateTarget(t, false));

        // Enable this target
        updateTarget(target, true);

        // Fire callback if one exists
        if (onChange !== null && onChange !== undefined) {
          onChange(input);
        }
      });

      // Correct the current state
      if (input.checked) {
        targets.filter(t => t !== target).forEach(t => updateTarget(t, false));
        updateTarget(target, true);
      }
    }
  });
}

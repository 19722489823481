import React, { Component } from 'react';

type Props = {
  // inventoryList: number,
  name: string;
  product: number;
  quantity: number;
  inventory: number;
  maxQuantity: number;
  updateQuantity: (number1: number, number2: number, string?: string) => void;
  cartGroupId?: string;
  tabIndex?: Object;
};

type State = {
  quantity: number;
  inventoryMessage: string;
  showDropdown: boolean;
};

const MAX_DROPDOWN_QUANTITY = 10;

class CartItemQuantity extends Component<Props, State> {
  quantityOptions: Array<number> = Array.from(
    { length: MAX_DROPDOWN_QUANTITY },
    (_, i) => i + 1,
  );
  state = {
    quantity: this.props.quantity,
    inventoryMessage: '',
    showDropdown: this.props.quantity < MAX_DROPDOWN_QUANTITY,
  };

  componentDidMount = () => {
    if (this.props.inventory <= 0) {
      this.setState({ inventoryMessage: 'Out of stock ' });
    } else if (this.state.quantity > this.props.inventory) {
      this.setState({
        inventoryMessage: `Not enough inventory - only ${this.props.inventory} left in stock`,
      });
    } else if (this.state.quantity > this.props.maxQuantity) {
      this.setState({
        inventoryMessage: `Limited to ${this.props.maxQuantity} per order`,
      });
    }
  };

  // Standard quantities (1-9)
  handleSelectChange = (event: { currentTarget: { value: string; }; }) => {
    const newQuantity = parseInt(event.currentTarget.value, 10);

    this.setState(
      {
        quantity: newQuantity,
        showDropdown: newQuantity < MAX_DROPDOWN_QUANTITY,
      },
      // Handle state logic in callback because setState() is async
      () => {
        if (this.state.quantity > this.props.inventory) {
          this.updateQuantity();
          this.setState({
            inventoryMessage: `Not enough inventory - only ${this.props.inventory} left in stock`,
          });
        } else if (this.props.inventory <= 0) {
          this.setState({
            inventoryMessage: 'Out of stock',
          });
        } else if (this.state.quantity > this.props.maxQuantity) {
          this.updateQuantity();
          this.setState({
            quantity: this.props.maxQuantity,
            inventoryMessage: `Limited to ${this.props.maxQuantity} per order`,
          });
        } else if (this.state.quantity < MAX_DROPDOWN_QUANTITY) {
          this.updateQuantity();
          this.setState({ inventoryMessage: '' });
        } else if (this.state.quantity === MAX_DROPDOWN_QUANTITY) {
          this.setState({
            quantity: this.props.quantity,
            showDropdown: newQuantity < MAX_DROPDOWN_QUANTITY,
          });
        }
      },
    );
  };



  // Bulk quantities (10+)
  handleInputChange = (event: { currentTarget: { value: any; }; }) => {
    let dirtyInput = event.currentTarget.value;
    let newQuantity;

    // Clean input before setting state
    if (dirtyInput === '') {
      this.setState({ quantity: dirtyInput });
    } else if (dirtyInput.length > 3) {
      dirtyInput = dirtyInput.substring(0, 3);
      event.currentTarget.value = dirtyInput;
      newQuantity = parseInt(dirtyInput, 10);
      this.setState({ quantity: newQuantity });
    } else {
      newQuantity = parseInt(dirtyInput, 10);
      this.setState({ quantity: newQuantity });
    }
  };

  handleUpdateButtonClick = () => {
    if (this.state.quantity === 0) {
      this.setState({
        quantity: this.props.quantity,
        inventoryMessage: '',
      });
    } else if (this.state.quantity > this.props.inventory) {
      this.setState({
        inventoryMessage: `Not enough inventory - only ${this.props.inventory} left in stock`,
      });
    } else if (this.state.quantity > this.props.maxQuantity) {
      this.updateQuantity();
      this.setState({
        inventoryMessage: `Limited to ${this.props.maxQuantity} per order`,
      });
    } else if (this.props.inventory <= 0) {
      this.setState({ inventoryMessage: 'Out of stock' });
    } else {
      this.updateQuantity();
      this.setState({ inventoryMessage: '' });
    }
  };

  updateQuantity = () => {
    this.props.updateQuantity(
      this.props.product,
      this.state.quantity,
      this.props.cartGroupId,
    );
  };

  render() {
    const quantityId = `cart-widget-quantity-${this.props.product}`;

    const options = this.quantityOptions.map((qty) => {
      const label = qty === MAX_DROPDOWN_QUANTITY ? `${qty}+` : `${qty}`;
      return (
        <option value={qty} key={qty}>
          {label}
        </option>
      );
    });

    // Show dropdown if we've got less than max items, and we haven't switched
    // to an input field yet
    if (
      this.state.showDropdown &&
      this.state.quantity < MAX_DROPDOWN_QUANTITY
    ) {
      return (
        <span className="widget-item-quantity">
          <label htmlFor={quantityId} className="widget-item-quantity__label">
            Quantity:
            <select
              id={quantityId}
              className="widget-item-quantity__select"
              value={this.state.quantity}
              onChange={this.handleSelectChange}
              {...this.props.tabIndex}
            >
              {options}
              <option value="0">Remove</option>
            </select>
          </label>
          <span className="widget-item-quantity__message">
            {this.state.inventoryMessage}
          </span>
        </span>
      );
    }

    // Show an input field
    return (
      <span className="widget-item-quantity">
        <label htmlFor={quantityId} className="widget-item-quantity__label">
          Quantity:
          <input
            className="widget-item-quantity__input"
            id={quantityId}
            type="number"
            min="0"
            max="999"
            maxLength={3}
            value={this.state.quantity}
            onChange={this.handleInputChange}
            {...this.props.tabIndex}
          />
        </label>
        {this.state.quantity !== this.props.quantity && (
          <button
            type="button"
            className="button button--link button--small"
            onClick={this.handleUpdateButtonClick}
            {...this.props.tabIndex}
          >
            Update
          </button>
        )}
        <span className="widget-item-quantity__message">
          {this.state.inventoryMessage}
        </span>
      </span>
    );
  }
}

export default CartItemQuantity;

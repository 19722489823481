/* eslint import/prefer-default-export: "off" */
import type { Store } from 'redux';
import type { Dispatch } from './types';

import type { CartState } from './cart_widget/state';
import type { CartAction } from './cart_widget/actions';
import { addProducts, addCouponCode } from './cart_widget/actions';

export default function createQuickBuy(store: Store<{ cart: CartState }, CartAction, Dispatch>) {
  return (products: Array<Object>, couponCode: string) => {

    const quickBuyElement = document.querySelector('.quickbuy');

    store.dispatch(addProducts(products, 'quickbuy'))
      .then(() => {
        if (couponCode !== null && couponCode !== undefined) {
          return store.dispatch(addCouponCode(couponCode));
        }
        return Promise.resolve();
      })
      .then(() => {
        if (quickBuyElement instanceof HTMLElement) {
          quickBuyElement.classList.add('is-success');
        }
        window.location.href = '/cart';
      })
      .catch(() => {
        if (quickBuyElement instanceof HTMLElement) {
          quickBuyElement.classList.add('is-error');
        }
      });
  };
}

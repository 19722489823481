import queryString from 'query-string';
import Tooltip from 'tooltip.js';

import SelectOption from './options/SelectOption';
import LinkOption from './options/LinkOption';

declare var XL_WIDTH: number;

type ItemList = Array<{
  id: number;
  sku: string;
  description_id: string;
  form_id: string;
  addons_id: string;
  price_id: string;
  user_exclusive_class: string;
  user_exclusive_message_id: string;
  media_set_id: string;
  value_adds_id: string;
  options: {
    [key: string]: string;
  };
}>;

interface Option {
  update(selectedValue: string, availableValues: Set<string>): void;
  showError(): void;
  clearError(): void;
  getInitialSelected(): null | string;
}

type AlignmentData = {
  vertical: 'top' | 'bottom';
  horizontal: 'left' | 'right';
};

const defaultFormId = 'buy-form-default';
const unavailableFormId = 'buy-form-unavailable';

const defaultPriceId = 'buy-price-default';
const defaultUserId = 'buy-user-default';
const defaultUserMessageId = 'buy-user-message-default';

const defaultDescriptionId = 'buy-description-default';

// Main class

export default class BuySection {
  container: HTMLElement;
  items: ItemList;
  selected: Map<string, null | string>;
  options: Map<string, Option>;

  // Simple element collections
  descriptions: Array<HTMLElement>;
  forms: Array<HTMLElement>;
  addonContainers: Array<HTMLElement>;
  addons: Array<HTMLElement>;
  prices: Array<HTMLElement>;
  historicalPrices: Array<HTMLElement>;
  userExclusive: Array<HTMLElement>;
  userExclusiveMessage: Array<HTMLElement>;
  mediaSets: Array<HTMLElement>;
  valueAdds: Array<HTMLElement>;

  constructor(container: HTMLElement, items: ItemList) {
    this.container = container;
    this.items = items;

    // Set up simple elements
    this.descriptions = Array.from(
      this.container.querySelectorAll('.js-buy-description'),
    );
    this.forms = Array.from(
      this.container.querySelectorAll('.js-buy-section-form'),
    );
    this.addonContainers = Array.from(
      this.container.querySelectorAll('.js-buy-section-addons'),
    );
    this.addons = Array.from(
      this.container.querySelectorAll('.js-buy-section-addon'),
    );
    this.prices = Array.from(
      this.container.querySelectorAll('.js-buy-section-price'),
    );
    this.historicalPrices = Array.from(
      this.container.querySelectorAll('.js-buy-section-price-history'),
    );
    this.userExclusive = Array.from(
      this.container.querySelectorAll('.js-buy-section-user-exclusive'),
    );
    this.userExclusiveMessage = Array.from(
      this.container.querySelectorAll('.js-buy-section-user-exclusive-message'),
    );
    this.mediaSets = Array.from(
      this.container.querySelectorAll('.js-media-set'),
    );
    this.valueAdds = Array.from(
      this.container.querySelectorAll('.js-buy-section-value-adds'),
    );

    // Get option data
    this.options = this.getOptions('.js-buy-section-option');
    this.selected = this.getInitialSelected(this.options);

    // Attach default event handlers
    Array.from(
      this.container.querySelectorAll('.js-buy-section-default-button'),
    ).forEach((button) => {
      button.addEventListener('click', (event: Event) =>
        this.handleDefaultClick(event),
      );
    });

    this.initAddons();

    this.initShowMoreToggles();

    // Tooltips
    this.initTooltips();
    window.addEventListener('DOMContentLoaded', () => this.initBadges());
  }

  // Init populating

  getOptions(selector: string): Map<string, Option> {
    const options = new Map();
    const handler = (option: string, value?: string) =>
      this.handleOptionChange(option, value);

    Array.from(this.container.querySelectorAll(selector)).forEach((el) => {
      const option = el.getAttribute('data-option');
      const widget = el.getAttribute('data-widget');
      if (option && widget) {
        // Pick the right option class based on the widget type
        switch (widget) {
          case 'dropdown':
            options.set(
              option,
              new SelectOption(option, el as HTMLElement, handler),
            );
            break;
          case 'buttons':
          case 'swatches':
            options.set(
              option,
              new LinkOption(option, el as HTMLElement, handler),
            );
            break;

          default:
            break;
        }
      }
    });
    return options;
  }

  getInitialSelected(options: Map<string, Option>): Map<string, null | string> {
    const selected = new Map();
    options.forEach((option, key) => {
      selected.set(key, option.getInitialSelected());
    });
    return selected;
  }

  // Default button

  handleDefaultClick(event: Event) {
    this.selected.forEach((value, key) => {
      if (value === null) {
        const option = this.options.get(key);
        if (option) {
          option.showError();
        }
      }
    });
    event.preventDefault();
  }

  // Option handling

  handleOptionChange(option: string, value?: string) {
    const selected = new Map(this.selected);
    selected.set(option, value);

    const complete =
      Array.from(selected.values()).filter((v) => v === null).length === 0;
    const matching = this.getMatchingItems(selected);

    // Update display of option values
    this.options.forEach((obj, key) => {
      // Get what is currently selected for this option
      const selectedValue = selected.get(key);

      // Figure out what would be available if this option did not have a selection
      const otherSelected = new Map(selected);
      otherSelected.set(key, null);
      const availableItems = this.getMatchingItems(otherSelected);
      const availableValues = this.getAvailableOptionValues(
        key,
        availableItems,
      );

      // Ask option to update itself
      obj.update(selectedValue, availableValues);
    });

    // Handle updating of forms, galleries and texts
    if (matching.length === 1) {
      // We have exactly one matching item. This could mean
      // that we're done with selection, and the customer has
      // made all the choices.
      const selectedItem = matching[0];
      this.showElement(this.mediaSets, selectedItem.media_set_id);

      if (complete) {
        // Selection is complete, and we have a single item
        this.showElement(this.addonContainers, selectedItem.addons_id);
        this.showElement(this.prices, selectedItem.price_id);
        if (this.historicalPrices) {
          this.showElement(this.historicalPrices, selectedItem.sku);
        }
        this.showElement(this.userExclusive, selectedItem.user_exclusive_class);
        this.showElement(
          this.userExclusiveMessage,
          selectedItem.user_exclusive_message_id,
        );
        this.showElement(this.forms, selectedItem.form_id);
        this.showElement(this.descriptions, selectedItem.description_id);
        this.showElement(this.valueAdds, selectedItem.value_adds_id);
      } else {
        // selection isn't complete yet, use defaults
        this.showElement(this.prices, defaultPriceId);
        this.showElement(this.userExclusive, defaultUserId);
        this.showElement(this.userExclusiveMessage, defaultUserMessageId);
        this.showElement(this.forms, defaultFormId);
        this.showElement(this.descriptions, defaultDescriptionId);
        this.showElement(this.valueAdds, null);
      }
    } else if (matching.length === 0) {
      // We don't have any matches, so use the first item to select
      // a media set
      const firstItem = this.items[0];
      if (firstItem) {
        this.showElement(this.mediaSets, firstItem.media_set_id);
      }
      // Show the unavailable form, as well as default prices
      this.showElement(this.forms, unavailableFormId);
      this.showElement(this.prices, defaultPriceId);
      this.showElement(this.userExclusive, defaultUserId);
      this.showElement(this.userExclusiveMessage, defaultUserMessageId);
      this.showElement(this.descriptions, defaultDescriptionId);
      this.showElement(this.valueAdds, null);
    } else {
      // We've got multiple matches. We do not check for complete here
      // as having multiple matches once complete is probably a setup
      // error.
      const firstMatch = matching[0];
      this.showElement(this.mediaSets, firstMatch.media_set_id);
      this.showElement(this.forms, defaultFormId);
      this.showElement(this.prices, defaultPriceId);
      this.showElement(this.userExclusive, defaultUserId);
      this.showElement(this.userExclusiveMessage, defaultUserMessageId);
      this.showElement(this.descriptions, defaultDescriptionId);
      this.showElement(this.valueAdds, null);
    }

    const url = this.getUrl(selected);
    this.replaceState(url);
    this.selected = selected;
  }

  // State handling

  getUrl(selected: Map<string, null | string>): string {
    const path = `/${window.location.pathname.replace(/^\//, '')}`;
    const query = queryString.parse(window.location.search);
    selected.forEach((value, option) => {
      if (value === null || value === undefined) {
        if (Object.prototype.hasOwnProperty.call(query, option)) {
          delete query[option];
        }
      } else {
        query[option] = value;
      }
    });

    const qs = queryString.stringify(query, { arrayFormat: 'comma' });
    return `${path}${qs.length > 0 ? `?${qs}` : ''}`;
  }

  replaceState(url: string) {
    window.history.replaceState({}, document.title, url);
  }

  // Item matching

  getMatchingItems(selected: Map<string, null | string>) {
    const options = Array.from(selected.keys());

    const matching: ItemList = this.items.filter((item) => {
      // Find out how many of our options match this item
      const matches = options.filter((option) => {
        const value = selected.get(option);
        // if nothing has been selected for this option, it can match anything
        if (value === null) {
          return true;
        }
        // if the value matches the items value for this option, it's a direct match
        if (value === item.options[option]) {
          return true;
        }
        return false;
      });

      // If all options match, this item is match
      return matches.length === selected.size;
    });

    return matching;
  }

  getAvailableOptionValues(option: string, items: ItemList): Set<string> {
    return new Set(items.map((item) => item.options[option]));
  }

  initAddons() {
    if (!this.addons) {
      return;
    }

    this.addons.forEach((addon) => {
      const checkbox: HTMLInputElement = addon.querySelector('input[type="checkbox"]');

      if (!checkbox) {
        return;
      }

      const addonId = checkbox.getAttribute('data-addon-id');

      checkbox.addEventListener('change', () => {
        this.forms.forEach((form) => {
          if (form.classList.contains('is-visible')) {
            const formElement = form.querySelector('form');
            if (!formElement) return;
            if (checkbox.checked) {
              const newInput = document.createElement('input');
              newInput.type = 'hidden';
              newInput.name = 'product_id';
              newInput.value = addonId;
              newInput.classList.add('addon-product');
              formElement.insertBefore(newInput, formElement.firstChild);
            } else {
              const existingInput = formElement.querySelector('.addon-product');
              if (existingInput) {
                formElement.removeChild(existingInput);
              }
            }
          }
        });
      });
    });
  }

  // Tooltips
  initTooltips() {
    Array.from(
      this.container.querySelectorAll('.js-buy-section-tooltip'),
    ).forEach((el) => {
      const tooltipTitle = el.getAttribute('data-title');
      if (tooltipTitle) {
        new Tooltip(el as HTMLElement, {
          title: tooltipTitle,
          placement: 'top', // or bottom, left, right, and variations
          offset: '0, 8px',
          delay: { show: 250, hide: 0 },
          container: document.body,
          boundariesElement: document.body,
          arrowSelector: '.tooltip__arrow',
          innerSelector: '.tooltip__inner',
          template:
            '<div class="tooltip" role="tooltip"><div class="tooltip__arrow"></div><div class="tooltip__inner"></div></div>',
        });
        el.removeAttribute('data-title');
      }
    });
  }

  // Badges
  initBadges() {
    this.mediaSets.forEach((mediaSet) => {
      const mediaItems: HTMLElement[] = Array.from(mediaSet.querySelectorAll('.media-set__media'));
      mediaItems.forEach((media) => {
        const badgesContainer: HTMLElement = media.querySelector('.media-set__badges');
        if (!badgesContainer) return;
        const halfSize: boolean = media.classList.contains('media-set__media--half');
        const axis = badgesContainer.getAttribute('data-axis');
        const alignData: string[] = badgesContainer.getAttribute('data-align').split('-');
        const alignment: AlignmentData = {
          vertical: alignData[0] as AlignmentData['vertical'],
          horizontal: alignData[1] as AlignmentData['horizontal']
        };
        badgesContainer.style.opacity = '1';
        const badges: HTMLElement[] = Array.from(media.querySelectorAll('.media-set__badge'));
        let increment = 0;
        let zIndexIncrement = 100;
        badges.forEach((badge) => {
          badge.style.zIndex = `${zIndexIncrement - 1}`;
          badge.style[alignment.vertical] = axis === 'vertical' ? `${increment}px` : `0px`;
          badge.style[alignment.horizontal] = axis === 'horizontal' ? `${increment}px` : `0px`;

          increment += halfSize ? 140 : 200;
          zIndexIncrement -= 1;
        });
      });
    });
  }

  // Init description show more toggles
  createShowMoreToggleButton(): HTMLElement {
    const clone = document.querySelector('.js-show-more-toggle-clone');
    const showMoreToggle = clone?.cloneNode(true) as HTMLElement;
    showMoreToggle.classList.remove('hidden', 'js-show-more-toggle-clone');
    showMoreToggle.classList.add('is-collapsed');
    showMoreToggle.setAttribute('aria-label', 'Show more bullet points');
    showMoreToggle.setAttribute('aria-expanded', 'false');
    return showMoreToggle;
  }

  toggleListItemsVisibility(hiddenLiItems: HTMLElement[], showMoreToggle: HTMLElement) {
    hiddenLiItems.forEach((listItem) => {
      if (listItem.classList.contains('is-visible')) {
        listItem.classList.remove('is-visible');
      } else {
        listItem.classList.add('is-visible');
      }
    });

    if (showMoreToggle.classList.contains('is-collapsed')) {
      showMoreToggle.classList.remove('is-collapsed');
      showMoreToggle.setAttribute('aria-label', 'Show less bullet points');
      showMoreToggle.setAttribute('aria-expanded', 'true');
    } else {
      showMoreToggle.classList.add('is-collapsed');
      showMoreToggle.setAttribute('aria-label', 'Show more bullet points');
      showMoreToggle.setAttribute('aria-expanded', 'false');
    }
  }

  initializeList(list: HTMLUListElement, description: HTMLElement) {
    if (list.querySelectorAll('li').length <= 3) return;

    const hiddenLiItems: HTMLElement[] = Array.from(list.querySelectorAll('li:not(:nth-child(-n+3))'));
    const showMoreToggle = this.createShowMoreToggleButton();

    showMoreToggle.addEventListener('click', () => this.toggleListItemsVisibility(hiddenLiItems, showMoreToggle));

    list.appendChild(showMoreToggle);
  }

  initializeDescription(description: HTMLElement) {
    const lists = Array.from(description.querySelectorAll('ul'));
    if (!lists.length) return;

    lists.forEach((list) => {
      this.initializeList(list as HTMLUListElement, description as HTMLElement);
    });

    // we want to remove the showMoreToggle that has js-show-more-toggle and hidden classes as that was the one we cloned
    const showMoreToggleClone = description.querySelector('.js-show-more-toggle-clone');
    if (showMoreToggleClone) {
      description.removeChild(showMoreToggleClone);
    }
  }

  initShowMoreToggles() {
    this.descriptions.forEach((description) => {
      this.initializeDescription(description);
    });
  }

  // Utils
  showElement(elements: Array<HTMLElement>, idOrClass?: string) {
    elements.forEach((el) => {
      if (
        idOrClass &&
        (el.id === idOrClass || el.classList.contains(idOrClass))
      ) {
        el.classList.add('is-visible');
      } else {
        el.classList.remove('is-visible');
      }
    });
  }
}

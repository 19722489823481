export default class SelectOption {
  option: string;
  element: HTMLElement;
  selectHandler: (string1: string, string2?: string) => void;
  select: HTMLSelectElement;

  constructor(
    option: string,
    element: HTMLElement,
    selectHandler: (string1: string, string2?: string) => void,
  ) {
    this.option = option;
    this.element = element;
    this.selectHandler = selectHandler;

    const select = this.element.querySelector('select');
    if (!(select instanceof HTMLSelectElement)) {
      throw Error('Unable to find select element');
    }
    this.select = select;
    this.select.addEventListener('change', (event: Event) =>
      this.handleChange(event),
    );
  }

  handleChange(event: Event) {
    const { target } = event;
    if (!(target instanceof HTMLSelectElement)) {
      return;
    }
    const value = target.value !== '' ? target.value : null;
    this.selectHandler(this.option, value);
    event.preventDefault();
  }

  update(availableValues: Set<string>, selectedValue?: string): void {
    // Select boxes don't need a "selected" marked, that happens automatically
    // we do however need to mark the selectbox itself as potentially having a selection
    if (selectedValue) {
      this.select.classList.add('has-selection');
    } else {
      this.select.classList.remove('has-selection');
    }

    // Mark options which are not available as such
    Array.from(this.select.querySelectorAll('option')).forEach((option) => {
      if (!(option instanceof HTMLOptionElement)) {
        return;
      }
      const { value } = option;
      if (value) {
        // option.disabled = !availableValues.has(value);
      }
    });
  }

  getInitialSelected(): null | string {
    const initial = this.element.getAttribute('data-initial');
    return initial !== '' ? initial : null;
  }

  clearError() {}
  showError() {}
}

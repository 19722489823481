export default class ValidationResult {
  isValid: boolean;
  code: string;
  message: string;

  constructor(isValid: boolean, code: string, message: string) {
    this.isValid = isValid;
    this.code = code;
    this.message = message;
  }
}

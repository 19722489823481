import React, { Component } from 'react';

import type { Result } from '.';

type Props = {
  active: boolean;
  result: Result;
  navigate: any;
};

export default class SearchResult extends Component<Props> {
  handleClick = (event: Event) => {
    this.props.navigate(this.props.result);
    event.preventDefault();
  };

  render() {
    return (
      <li className="search-widget__result">
        <a
          href={this.props.result.page_url}
          className={this.props.active ? 'is-active' : ''}
          onClick={() => this.handleClick}
        >
          <span>{this.props.result.name}</span>
          <img src={this.props.result.image_url} alt="" />
        </a>
      </li>
    );
  }
}

declare global {
  interface Window {
    dataLayer: any;
  }
}

import FacetURL from './FacetURL';

// Types

export type Constraint = {
  value: string;
  slug: string;
  docs: Array<string>;
  selected: boolean;
};

export type Facet = {
  title: string;
  slug: string;
  collapsed: boolean;
  constraints: Array<Constraint>;
};

export type Facets = Array<Facet>;

// Facet list

export default class FacetList {
  basePath: string;
  facets: Facets;
  baseFacets: Facets;
  page: number;
  sort: string;
  phrase: string;
  constraintIndex: {
    [key: string]: Constraint;
  };
  facetIndex: {
    [key: string]: Facet;
  };

  constructor(basePath: string, facets: Facets, page: number, sort: string, phrase: string="") {
    this.basePath = basePath;
    this.facets = facets;
    this.page = page;
    this.sort = sort;
    this.phrase = phrase;

    this.constraintIndex = {};
    this.facets.forEach((facet) => {
      facet.constraints.forEach((constraint) => {
        this.constraintIndex[`${facet.slug}/${constraint.slug}`] = constraint;
      });
    });

    this.facetIndex = {};
    this.facets.forEach((facet) => {
      this.facetIndex[facet.slug] = facet;
    });
  }

  static getFacetSegment(facet: Facet): string {
    return facet.constraints
      .filter((c) => c.selected && c.slug)
      .map((c) => c.slug)
      .join('-');
  }

  getUrl(): FacetURL {
    let selected = [];

    const searchString = window.location.search;
    // eslint-disable-next-line compat/compat
    const params = new URLSearchParams(searchString);
    const inStock = params.get('in_stock') === 'on';

    for (let i = 0; i < this.facets.length; i++) {
      let facet_slug = this.facets[i].slug;
      let facet_selected = this.facets[i].constraints
        .filter((c) => c.selected && c.slug)
        .map((c) => [facet_slug, c.slug]);

      selected.push(...facet_selected);
    }

    let canonical_segment = selected.shift();
    if (inStock) {
      selected.push(['in_stock', 'on'])
    }

    if (canonical_segment === undefined && !inStock) {
      return new FacetURL(`${this.basePath}`, this.getQuery());
    } else {
      let query = this.getQuery();
      let grouped_facets = new Map();
      for (let i = 0; i < selected.length; i++) {
        let facet_slug = selected[i][0];
        let constraint_slug = selected[i][1];
        if (grouped_facets.has(facet_slug)) {
          grouped_facets.get(facet_slug).push(constraint_slug);
        } else {
          grouped_facets.set(facet_slug, [constraint_slug]);
        }
      }
      for (const facet_constraint of grouped_facets.entries()) {
        query[facet_constraint[0]] = facet_constraint[1].join('-');
      }

      return new FacetURL(`${this.basePath}/${canonical_segment[1]}`, query);
    }
  }

  getQuery() {
    const query: any = {
      collapsed: this.getCollapsedFacets(),
    };
    if (this.phrase) {
      query.phrase = this.phrase;
    }
    if (this.page > 1) {
      query.page = this.page;
    }
    if (this.sort) {
      query.sort = this.sort;
    }
    return query;
  }

  // State changes

  copy(): FacetList {
    return new FacetList(this.basePath, this.facets, this.page, this.sort, this.phrase);
  }

  clearSelection(): FacetList {
    this.facets.forEach( f => f.constraints.filter( c=> c.selected && c.slug).forEach( c => c.selected=false))
    return new FacetList(this.basePath, this.facets, this.page, this.sort, this.phrase);
  }

  withPage(page: number): FacetList {
    return new FacetList(this.basePath, this.facets, page, this.sort, this.phrase);
  }

  withSort(sort: string): FacetList {
    return new FacetList(this.basePath, this.facets, this.page, sort, this.phrase);
  }

  withPhrase(phrase: string): FacetList {
    return new FacetList(this.basePath, this.facets, null, null, phrase);
  }

  withFacetToggled(facet: string): FacetList {
    const facets = this.facets.map((f) => ({
      ...f,
      collapsed: f.slug === facet ? !f.collapsed : f.collapsed,
    }));
    return new FacetList(this.basePath, facets, this.page, this.sort, this.phrase);
  }

  withConstraintToggled(facet: string, constraint: string): FacetList {
    return this.withConstraintUpdated(facet, constraint, (c) => !c.selected);
  }

  trackConstraintEvents(facet: string, constraint: string) {
    if ('dataLayer' in window && typeof window.dataLayer.push === 'function') {
      var location = window.location.pathname.split('/')[1].replace('-', '_');
      window.dataLayer.push({
        event: 'filter_' + location,
        filter_type: facet + '_' + constraint,
      });
    }
  }

  withConstraintRemoved(facet: string, constraint: string): FacetList {
    return this.withConstraintUpdated(facet, constraint, () => false);
  }

  withConstraintApplied(facet: string, constraint: string): FacetList {
    return this.withConstraintUpdated(facet, constraint, () => true);
  }

  withConstraintUpdated(
    facet: string,
    constraint: string,
    selected: (c: Constraint) => boolean,
  ): FacetList {
    var valuechanged = false;
    const facets = this.facets.map((f) => ({
      ...f,
      constraints: f.constraints.map((c) => {
        if (f.slug === facet && c.slug === constraint)
          valuechanged = selected(c);
        return {
          ...c,
          selected:
            f.slug === facet && c.slug === constraint
              ? selected(c)
              : c.selected,
        };
      }),
    }));
    if (valuechanged) {
      this.trackConstraintEvents(facet, constraint);
    }
    return new FacetList(this.basePath, facets, this.page, this.sort, this.phrase);
  }

  // Constraint helpers

  isConstraintSelected(facet: string, constraint: string) {
    if (typeof this.constraintIndex[`${facet}/${constraint}`] !== 'undefined')
      return this.constraintIndex[`${facet}/${constraint}`].selected;
    else return false;
  }

  getConstraintCount(facet: string, constraint: string) {
    if (this.constraintIndex[`${facet}/${constraint}`] == undefined) return 0;
    let { docs } = this.constraintIndex[`${facet}/${constraint}`];
    let docs1: Set<string> = new Set(docs);

    this.facets.forEach((f) => {
      if (f.slug === facet) {
        return;
      }
      let applied = false;
      let facetDocs = new Set([]);
      f.constraints.forEach((c) => {
        if (c.selected) {
          facetDocs = new Set([...facetDocs, ...c.docs]);
          applied = true;
        }
      });
      if (applied) {
        docs1 = new Set([...docs1].filter((d) => facetDocs.has(d)));
      }
    });

    return docs1.size;
  }

  // Facet helpers

  isFacetCollapsed(facet: string): boolean {
    const { collapsed } = this.facetIndex[facet];
    return collapsed;
  }

  getCollapsedFacets() {
    return this.facets
      .filter((f) => f.collapsed)
      .map((f) => f.slug)
      .sort();
  }
}

/* eslint-disable no-new */

import Tooltip from 'tooltip.js';

export default function createTooltip() {
  const tooltips = Array.from(document.querySelectorAll('.js-tooltip'));

  if (tooltips) {
    tooltips.forEach((el) => {
      const tooltipTitle = el.getAttribute('title');
      const tooltipPlacement = el.getAttribute('data-placement');
      const tooltipOffset = el.getAttribute('data-offset');

      if (tooltipTitle) {
        const tooltip = new Tooltip(el, {
          title: tooltipTitle,
          placement: tooltipPlacement !== null ? tooltipPlacement : 'auto', // or top/bottom/left/right variations
          offset: tooltipOffset !== null ? tooltipOffset : '0,8px',
          delay: { show: 150 },
          container: document.body,
          boundariesElement: document.body,
          arrowSelector: '.tooltip__arrow',
          innerSelector: '.tooltip__inner',
          template:
            '<div class="tooltip" role="tooltip"><div class="tooltip__arrow"></div><div class="tooltip__inner"></div></div>',
        });
        el.removeAttribute('title');
        el.addEventListener('keydown', (event: KeyboardEvent) => {
          switch (event.keyCode) {
            case 27: // Escape
              tooltip.hide();
              event.preventDefault();
              break;

            case 13: // Enter
            case 32: // Space
              tooltip.toggle();
              event.preventDefault();
              break;

            default:
              break;
          }
        });
      }
    });
  }
}

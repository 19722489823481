import Cookies from 'js-cookie';

declare global {
  interface Window {
    cartCookieDomain: string;
  }
}

export const countrySelection = () => {
  const initCountrySubmit = () => {
    const countryModalForms = Array.from(document.querySelectorAll('.js-country-modal-form'));
    countryModalForms.forEach((form) => {
      form.addEventListener('submit', () => {
        Cookies.remove('sscartid', { path: '/', domain: window.cartCookieDomain });
        return;
      });
    });
  };

  const countryModalToggles = Array.from(document.querySelectorAll('.js-choose-country-modal-toggle'));
  countryModalToggles.forEach((toggle) => {
    toggle.addEventListener('click', () => {
      initCountrySubmit();
    });
  });

  const countryContainers = Array.from(document.querySelectorAll('.js-countries-container'));
  countryContainers.forEach(function (container) {
    const expand = container.querySelector('.js-country-show-more');
    if (expand) {
      expand.addEventListener('click', function (event) {
        event.preventDefault();
        container.classList.remove('is-collapsed');
      });
    }
  });
};

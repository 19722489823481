import React, { Component } from 'react';
import { connect } from 'react-redux';

import type { CartState } from './state';
import type { Dispatch } from '../types';
import type { Item, Addon, PostOrder } from './types';
import { openCart, closeCart } from './actions';

import CartHeader from './CartHeader';
import CartItemList from './CartItemList';

type Props = {
  cartId: string;
  open: boolean;
  isEmpty: boolean;
  checkoutURL: string;
  valueAdds: Array<Item>;
  items: Array<Item>;
  postOrders: Array<PostOrder>;
  quantity: number;
  currency: string;
  freeShipping: boolean;
  inventoryList: any;
  staticUrl: string;
  isLoginRequired: boolean;
  openPanel: () => void;
  closePanel: () => void;
  formatCurrency: (number: number, string: string) => string;
};

const mapStateToProps = (state: { cart: CartState }) => ({
  cartId: state.cart.data.id,
  open: state.cart.open,
  isEmpty: state.cart.data.is_empty,
  isLoginRequired: state.cart.data.is_login_required,
  checkoutURL: state.cart.data.checkout_url,
  items: state.cart.data.items,
  valueAdds: state.cart.data.value_adds,
  postOrders: state.cart.data.post_order,
  quantity: state.cart.data.quantity_incl_value_adds,
  currency: state.cart.data.currency,
  freeShipping: state.cart.data.estimated_shipping_price_base === 0,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  openPanel: () => dispatch(openCart()),
  closePanel: () => dispatch(closeCart()),
});

class CartWidget extends Component<Props> {
  handleMouseLeave = (event: React.MouseEvent) => {
    this.props.closePanel();
  };

  render() {
    // Tabindex
    const tabIndex = this.props.open ? {} : { tabIndex: -1 };
    const quantityWord = this.props.quantity === 1 ? 'item' : 'items';

    if (this.props.open) {
      if (this.props.isEmpty) {
        return (
          <div
            className="cart-widget js-cart-widget"
            onMouseLeave={this.handleMouseLeave}
          >
            <div className="courtesy-navigation__caret"></div>
            <div className="cart-widget__inner">
              <p className="cart-widget__title">
                Cart
                <small>{`(${this.props.quantity} ${quantityWord})`}</small>
              </p>
              <div className="simple-cart-widget__empty">
                <p>Your cart is currently empty.</p>
                <a
                  href="/gaming-products"
                  className="button button--orange button--solid button--block button--large"
                  {...tabIndex}
                >
                  Start shopping!
                </a>
              </div>
            </div>
          </div>
        );
      }
      return (
        <div
          className="cart-widget js-cart-widget"
          onMouseLeave={this.handleMouseLeave}
        >
          <div className="courtesy-navigation__caret"></div>
          <div className="cart-widget__inner">
            <p className="cart-widget__title">
              Cart
              <small>{`(${this.props.quantity} ${quantityWord})`}</small>
            </p>
            <CartItemList
              items={this.props.items}
              valueAdds={this.props.valueAdds}
              postOrders={this.props.postOrders}
              currency={this.props.currency}
              isLoginRequired={this.props.isLoginRequired}
              formatCurrency={this.props.formatCurrency}
              tabIndex={tabIndex}
            />
            <div className="cart-widget__bottom">
              <div className="simple-cart-widget__view-cart-button">
                <a
                  href="/cart"
                  className="button button--orange button--solid button--block"
                >
                  View Cart
                </a>
              </div>
              <div className="cart-widget__checkout-button">
                <form action={this.props.checkoutURL} method="POST">
                  <input
                    type="hidden"
                    name="cart_id"
                    value={this.props.cartId}
                  />
                  <button
                    type="submit"
                    className="button button--solid button--orange button--block button-no-wrap"
                    {...tabIndex}
                  >
                    🔒 Checkout
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CartWidget);

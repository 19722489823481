import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import html2canvas from 'html2canvas';
import { MeshPhongMaterial, MeshPhysicalMaterial } from 'three';

// Aerox 3 Option Dictionary
const AEROX_3_COLORS = {
  aqua: 0x2ad2c9,
  blue: 0x2ad2c9,
  turquoise: 0x2ad2c9,
  black: 0x312d2d,
  gray: 0xb1b3b3,
  grey: 0xb1b3b3,
  green: 0x00965e,
  jade: 0x00bf6f,
  mint: 0xbbe9de,
  onyx: 0x312d2d, // 010101,
  orange: 0xfc4c02,
  pink: 0xf57eb6,
  purple: 0xbb29bb,
  royal: 0x87189d,
  snow: 0xffffff,
  white: 0xffffff,
};

const config = {
  ghost: {
    buttons: {
      meshType: 2, //1: MeshPhysicalMaterial, 2: MeshPhongMaterial
      color: 0xffffff,
    },
    scroll_wheel: {
      meshType: 2,
      color: 0xb1b3b3,
    },
    shell: {
      meshType: 1,
    },
    logo: {
      color: 0xffffff
    }
  },
  onyx: {
    buttons: {
      meshType: 2, //1: MeshPhysicalMaterial, 2: MeshPhongMaterial
      color: 0x312d2d,
    },
    scroll_wheel: {
      meshType: 2,
      color: 0x312d2d,
    },
    shell: {
      meshType: 2,
      color: 0x312d2d,
      bump: true
    },
    logo: {
      color: 0xffffff
    },
  },
  jade: {
    buttons: {
      meshType: 2, //1: MeshPhysicalMaterial, 2: MeshPhongMaterial
      color: 0x312d2d,
    },
    scroll_wheel: {
      meshType: 2,
      color: 0x312d2d,
    },
    shell: {
      meshType: 2,
      color: 0x00bf6f,
      bump: true
    },
    logo: {
      color: 0x312d2d
    }
  },
  royal: {
    buttons: {
      meshType: 2, //1: MeshPhysicalMaterial, 2: MeshPhongMaterial
      color: 0x312d2d,
    },
    scroll_wheel: {
      meshType: 2,
      color: 0x312d2d,
    },
    shell: {
      meshType: 2,
      color:0x87189d,
      bump: true
    },logo: {
      color: 0x312d2d
    }
  },
  snow: {
    buttons: {
      meshType: 2, //1: MeshPhysicalMaterial, 2: MeshPhongMaterial
      color: 0x312d2d,
    },
    scroll_wheel: {
      meshType: 2,
      color: 0x312d2d,
    },
    shell: {
      meshType: 2,
      color: 0xffffff,
      bump: true
    },logo: {
      color: 0x312d2d
    }
  },
};

const AEROX_3_COLOR_MATERIALS = {
  ghost: {},
};

const AEROX_3_MATERIALS = {
  ptfe: '',
  virgin_grade: '',
  ceramic: '',
};

export default function threeDimensionalModelSetup(
  container: HTMLElement,
  canvas: HTMLCanvasElement,
  modelUrl: string,
) {
  // Constants
  const MODEL_PATH = modelUrl;
  const BRAID_PATH = `${window.staticUrl}img/three-dimensional-assets/aerox-3/textures/braided_rope.png`;
  const SHELL_TEXTURE_PATH = `${window.staticUrl}img/three-dimensional-assets/aerox-3/textures/shell_texture.png`;

  let cameraFar = 2;
  let theModel;

  // Init scene
  const scene = new THREE.Scene();

  // Init renderer
  const renderer = new THREE.WebGLRenderer({
    canvas,
    antialias: false,
    alpha: true,
  });

  renderer.setClearColor(0xe1e1e1, 0);

  // renderer.shadowMap.enabled = true;
  // renderer.setPixelRatio(window.devicePixelRatio);
  container.appendChild(renderer.domElement);

  // Add camera
  let camera = new THREE.PerspectiveCamera(
    45,
    window.innerWidth / window.innerHeight,
    0.1,
    1000,
  );
  camera.position.z = cameraFar;
  camera.position.x = 0;
  scene.add(camera);

  // Texture Loading
  var textureLoader = new THREE.TextureLoader();

  const braidTexture = textureLoader.load(BRAID_PATH);
  const bumpTexture = textureLoader.load(SHELL_TEXTURE_PATH)

  var u_time = { value: 0.0 };

  // Init object loader
  let loader = new GLTFLoader();

  const shell_material = new THREE.MeshPhysicalMaterial({
    bumpMap: bumpTexture,
    bumpScale: 0.0002,
    emissive: new THREE.Color(0xd4d4d4),
    emissiveIntensity: 0.45,
    opacity: 0.8,
    transmission: 0.5,
    thickness: 5,
    roughness: 0.6,
    // transmissionMap: ghostTexture,
    // envMap: ghostTexture,
    // normalMap: ghostTexture,
    specularColor: 0xffffff,
    specularIntensity: 1,
    sheenColor: 0xffffff, //from 0.0 to 1.0. Default is 0.0.
    sheen: 1, //from 0.0 to 1.0. Default is 0.0.
    sheenRoughness: 0, //from 0.0 to 1.0. Default is 1.0.
    reflectivity: 1, //default is 0.5
    ior: 2, //from 1.0 to 2.333. default is 1.5
  });

  const modelLoadPromise = new Promise((resolve) => {
    loader.load(
      MODEL_PATH,
      function (gltf) {
        theModel = gltf.scene;

        theModel.traverse((o) => {
          if (o.isMesh) {
            o.castShadow = false;
            o.receiveShadow = true;
          }
        });

        // Set model initial scale
        theModel.scale.set(0.1, 0.1, 0.1); //set(0.0012, 0.0012, 0.0012);
        theModel.position.y = 0.25;
        theModel.rotation.y = Math.PI;
        theModel.rotation.z = Math.PI / 100;
        theModel.rotation.x = Math.PI / 2;

        //Initial color
        theModel.traverse((obj) => {
          if (obj instanceof THREE.Mesh) {
            var cloned = obj.material.clone();
            if (obj.parent.name === 'shell') {
              cloned = shell_material;
            } else if (obj.parent.name === 'cable-braid')
              cloned = new THREE.MeshBasicMaterial({
                map: braidTexture,
              });
            else if (obj.parent.name === 'lightguide') {
              cloned = new THREE.ShaderMaterial({
                uniforms: {
                  u_time: u_time,
                },
                // lights: true,
                vertexShader: `
                uniform float u_time;
                varying vec2 vUv;

                void main() {
                  vUv.y = position.z + u_time;
                  gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
                }
              `,
                fragmentShader: `
              #ifdef GL_ES
              precision highp float;
              #endif

              uniform float u_time;
              varying vec2 vUv;
              vec3 hsb2rgb( in vec3 c ){
                  vec3 rgb = clamp(abs(mod(c.x*3.0+vec3(0.0,4.0,2.0),
                                          6.0)-3.0)-1.0,
                                  0.0,
                                  1.0 );
                  rgb = rgb*rgb*(4.0-2.0*rgb);
                  return c.z * mix(vec3(1.0), rgb, c.y);
              }
              void main() {
                vec2 st = vUv;
                st *= 0.1 * 0.5;
                st -= vec2(u_time*0.4);
                vec3 color = vec3(0.0);

                color = hsb2rgb(vec3(st.y,1.0,1.0));

                gl_FragColor = vec4(color, 1.0);
              }
              `,
              });
            } else {
              cloned = new THREE.MeshPhongMaterial({
                color: new THREE.Color(AEROX_3_COLORS['onyx']),
                shininess: 30,
              });
            }
            // cloned.shininess = 10;
            cloned.specular = new THREE.Color(0x404040);
            obj.material = cloned;
          }
        });
        // Add model to scene
        scene.add(theModel);
        resolve(theModel);
      },
      undefined,
      function (error) {
        console.error(error);
      },
    );
  });

  // Add lights
  let hemiLight = new THREE.HemisphereLight(0xd6d6d6, 0x404040, 0.6); //(0xffffff, 0xffffff, 0.61);(0xffeeb1, 0x080820, 4)
  hemiLight.position.set(0, 50, 0);
  scene.add(hemiLight);

  //dir lights
  let dirLight1 = new THREE.DirectionalLight(0xd6d6d6, 0.6); //0.7
  dirLight1.position.set(-5, 1, 8);
  dirLight1.castShadow = true;
  scene.add(dirLight1);

  let dirLight2 = new THREE.DirectionalLight(0xd6d6d6, 0.6); //0.7
  dirLight2.position.set(5, -1, -8);
  dirLight2.castShadow = true;
  scene.add(dirLight2);

  //add fog
  const color = 0xffffff; // white
  const near = 10;
  const far = 100;
  scene.fog = new THREE.Fog(color, near, far);

  // Internal RGB lights

  let rgbLeft = new THREE.PointLight(0x00ffa0, 5, 1, 2);
  rgbLeft.position.set(-0.2, -0.3, 0.05);
  scene.add(rgbLeft);

  let rgbRight = new THREE.PointLight(0x00ffa0, 5, 1, 2);
  rgbRight.position.set(0.2, -0.3, 0.05);
  scene.add(rgbRight);

  // Add controls
  let controls = new OrbitControls(camera, renderer.domElement);
  controls.enableDamping = true;
  controls.enablePan = false;
  controls.dampingFactor = 0.1;
  controls.maxDistance = Math.PI;
  // controls.autoRotate = false; // Toggle this if you'd like the chair to automatically rotate
  // controls.autoRotateSpeed = 0.2; // 30

  //vars for color animation
  var clock = new THREE.Clock();

  const takeScreenshot = () => {
    // reset 3D model position and prep for screenshot
    controls.reset();
    animate();

    // screenshot the 3D model

    // return the Promise, so ew can chain on it later if we need to run a callback
    return html2canvas(canvas, {
      backgroundColor: null,
      scale: 0.5,
    }).then((screenshot) => {
      // translate canvas element into a url string
      const imageUrl = screenshot.toDataURL('image/png');

      const imageIdArray = [];
      const childProductInputs = Array.from(
        document.querySelectorAll(
          '.js-buy-section-form.is-custom input[data-relationship="child"]',
        ),
      );
      childProductInputs.forEach((input) => {
        imageIdArray.push(Number(input.getAttribute('value')));
      });
      const sortedImageIdArray = imageIdArray.sort((a, b) => a - b);
      const imageLocalStorageId = sortedImageIdArray.join('-');

      // set new item in localStorage
      try {
        localStorage.setItem(`custom-product-${imageLocalStorageId}`, imageUrl);
      } catch (err) {
        console.log('Storage failed:', err);
      }
    });
  };

  function animate() {
    controls.update();
    renderer.render(scene, camera);
    requestAnimationFrame(animate);

    if (resizeRendererToDisplaySize(renderer)) {
      const canvas = renderer.domElement;
      camera.aspect = canvas.clientWidth / canvas.clientHeight;
      camera.updateProjectionMatrix();
    }
    let t = clock.getElapsedTime();
    u_time.value = t;
    var h = 1 - ((t * 0.175) % 1);
    var s = 1.0;
    var l = 0.16;
    rgbLeft.color.setHSL(h, s, l);
    rgbRight.color.setHSL(h, s, l);

    renderer.render(scene, camera);
  }

  // Function - New resizing method
  function resizeRendererToDisplaySize(renderer) {
    const canvas = renderer.domElement;
    let width = window.innerWidth;
    let height = window.innerHeight;
    let canvasPixelWidth = canvas.width / window.devicePixelRatio;
    let canvasPixelHeight = canvas.height / window.devicePixelRatio;

    const needResize =
      canvasPixelWidth !== width || canvasPixelHeight !== height;
    if (needResize) {
      renderer.setSize(width, height, false);
    }
    return needResize;
  }

  function updateModel(partName: string, partFunction: string, value: string) {
    switch (partFunction) {
      case 'Toggle Visibility': //'model_part_visibility':
        let modelPiecesForVis = [];
        if (partName.includes(' ')) {
          modelPiecesForVis = partName.split(' ');
        } else {
          modelPiecesForVis.push(partName);
        }
        modelPiecesForVis.forEach((piece) => {
          theModel.traverse((obj) => {
            if (obj.name == piece) {
              if (value === 'wired') obj.visible = true;
              else obj.visible = false;
            }
          });
        });
        break;
      case 'Change Color': //'model_part_color':
        var modelPiecesForColor = [];
        if (partName.includes(' ')) {
          modelPiecesForColor = partName.split(' ');
        } else {
          modelPiecesForColor.push(partName);
        }

        theModel.traverse((obj) => {
          if (
            modelPiecesForColor.includes(obj.parent.name) &&
            obj instanceof THREE.Mesh
          ) {
            if (config[value] && config[value][obj.parent.name]) {
              if (config[value][obj.parent.name].meshType == 1)
                obj.material = shell_material;
              else {
                var newMaterial = new MeshPhongMaterial({
                  color: new THREE.Color(config[value][obj.parent.name].color),
                  shininess: 30,
                  specular: new THREE.Color(0x404040),
                });
                if (config[value][obj.parent.name].bump)
                {
                  newMaterial.bumpScale  = 0.0002;
                  newMaterial.bumpMap = bumpTexture;
                }
                obj.material = newMaterial;
              }
            } else {
              var cloned = new THREE.MeshPhongMaterial({
                color: new THREE.Color(AEROX_3_COLORS[value]),
                shininess: 30,
                specular: new THREE.Color(0x404040),
              });
              // if (obj.parent.name === "cable-braid")
              //   cloned.map = braidTexture;
              obj.material = cloned;
            }
          }
        });
        break;
      case 'Change Material': // model_part_material':
        const material = AEROX_3_MATERIALS[value];
        // const newMaterial = new THREE.MeshPhongMaterial({
        //    color:
        //    shininess:
        // });
        break;
      default:
        break;
    }
  }

  // Link buy section options to model
  const allOptions = Array.from(
    document.querySelectorAll('.js-option-value-link'),
  );
  allOptions.forEach((option) => {
    option.addEventListener('click', (event: Event) => {
      const el = event.target;
      const optionWrapper = el.closest('.js-buy-section-option');

      if (el instanceof HTMLElement && optionWrapper instanceof HTMLElement) {
        const modelPartName = optionWrapper.getAttribute(
          'data-model-part-name',
        );
        const modelPartFunction = optionWrapper.getAttribute(
          'data-model-part-function',
        );
        const newValue = el.getAttribute('data-value');
        updateModel(modelPartName, modelPartFunction, newValue);
      }
    });
  });

  // set global handlers in the Steelseries namespace
  window.SteelSeries.onModelLoadPromise = modelLoadPromise;
  window.SteelSeries.screenshotThreeDimensionalModel = takeScreenshot;

  animate();
}

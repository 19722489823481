import queryString from 'query-string';
import Tooltip from 'tooltip.js';

import SelectOption from './options/SelectOption';
import LinkOption from './options/LinkOption';
import { queryAllByAltText } from '@testing-library/react';

type ItemList = Array<{
  id: number;
  sku: string;
  name_id: string;
  description_id: string;
  form_id: string;
  price_id: string;
  user_exclusive_class: string;
  user_exclusive_message_id: string;
  media_set_id: string;
  value_adds_id: string;
  options: {
    [key: string]: string;
  };
  product_family_options: {
    [key: string]: string;
  };
}>;

interface Option {
  update(selectedValue: string, availableValues: Set<string>): void;
  showError(): void;
  clearError(): void;
  getInitialSelected(): null | string;
}

const defaultFormId = 'buy-form-default';
const unavailableFormId = 'buy-form-unavailable';
const defaultStickyFormId = 'product-sticky-form-default';
const unavailableStickyFormId = 'product-sticky-form-unavailable';

const defaultPriceId = 'buy-price-default';
const defaultStickyPriceId = 'product-sticky-price-default';
const defaultUserId = 'buy-user-default';
const defaultUserMessageId = 'buy-user-message-default';

const defaultNameId = 'buy-name-default';
const defaultDescriptionId = 'buy-description-default';

// Main class

export default class BuySectionv2 {
  container: HTMLElement;
  optionsContainer: HTMLElement | null;

  selectedTabContainer?: HTMLElement;
  items: ItemList;
  selected: Map<string, null | string>;
  options: Map<string, Option>;

  // Simple element collections
  names: Array<HTMLElement>;
  descriptions: Array<HTMLElement>;
  forms: Array<HTMLElement>;
  stickyForms?: Array<HTMLElement>;
  prices: Array<HTMLElement>;
  stickyPrices?: Array<HTMLElement>;
  userExclusive: Array<HTMLElement>;
  userExclusiveMessage: Array<HTMLElement>;
  mediaSets: Array<HTMLElement>;
  valueAdds: Array<HTMLElement>;
  chargerIcons: Array<HTMLElement>;

  // Custom
  activeTab: boolean;
  isCustomizableProduct: boolean;

  // TODO: might not need these
  groups: Array<HTMLElement>;
  groupOptions: Map<string, Option>;
  groupLinkClasses: Array<Object>;

  constructor(container: HTMLElement, items: ItemList) {
    this.container = container;
    this.optionsContainer = this.container.querySelector(
      '.buy-section__tab-content.js-buy-tab-panel',
    );

    if (this.container.querySelector('#ready-to-ship-tab'))
      this.optionsContainer =
        this.container.querySelector('#ready-to-ship-tab');
    this.items = items;

    // Get option data
    this.options = this.getOptions('.js-buy-section-option');
    this.selected = this.getInitialSelected(this.options);

    // Set up simple elements
    this.names = Array.from(this.container.querySelectorAll('.js-buy-names'));
    this.descriptions = Array.from(
      this.container.querySelectorAll('.js-buy-description'),
    );
    this.forms = Array.from(
      this.container.querySelectorAll('.js-buy-section-form'),
    );
    this.stickyForms = Array.from(
      document.querySelectorAll('.js-product-sticky-nav-form'),
    );
    this.prices = Array.from(
      this.container.querySelectorAll('.js-buy-section-price'),
    );
    this.stickyPrices = Array.from(
      document.querySelectorAll('.js-product-sticky-nav-price'),
    );
    this.userExclusive = Array.from(
      this.container.querySelectorAll('.js-buy-section-user-exclusive'),
    );
    this.userExclusiveMessage = Array.from(
      this.container.querySelectorAll('.js-buy-section-user-exclusive-message'),
    );
    this.mediaSets = Array.from(
      this.container.querySelectorAll('.js-media-set'),
    );
    this.valueAdds = Array.from(
      this.container.querySelectorAll('.js-buy-section-value-adds'),
    );
    this.chargerIcons = Array.from(
      this.container.querySelectorAll('.js-charger-icon'),
    );

    // Set up customizable buy section
    this.isCustomizableProduct = this.container.classList.contains(
      'buy-section--customize',
    );
    this.activeTab = false;
    this.groupLinkClasses = [];

    if (this.isCustomizableProduct) {
      this.activeTab = this.setActiveTab('.js-buy-tab-control');

      // Tab event handler
      Array.from(
        this.container.querySelectorAll('.js-buy-tab-control'),
      ).forEach((tab) => {
        tab.addEventListener('click', (event: Event) => {
          this.activeTab = this.setActiveTab('.js-buy-tab-control');
          // initialize again with the selected tab
          if (this.activeTab) {
            this.onTabChange();
          }
        });
      });
    } else {
      this.activeTab = true;
    }

    this.onTabChange();

    // Attach default event handlers
    Array.from(
      this.optionsContainer.querySelectorAll('.js-buy-section-default-button'),
    ).forEach((button) => {
      button.addEventListener('click', (event: Event) => {
        this.handleDefaultClick(event);
      });
    });

    Array.from(
      this.optionsContainer.querySelectorAll('.js-buy-section-reset'),
    ).forEach((reset) => {
      reset.setAttribute(
        'data-original-state',
        window.location.pathname + window.location.search,
      );
      reset.addEventListener('click', (event: Event) => {
        // this.handleResetClick(event);  ????
      });
    });

    // Tooltips
    this.initTooltips();
  }

  onTabChange() {
    this.selected.forEach((value, key) => {
      this.handleOptionChange(key, value);
    });
    if (this.activeTab) {
      const url = this.getUrl(this.selected);
      this.replaceState(url);
    }
  }

  setActiveTab(className: string) {
    const tabs = Array.from(this.container.querySelectorAll(className));
    let activeTabId = '';

    tabs.forEach((tab) => {
      if (tab.getAttribute('aria-selected') === 'true') {
        activeTabId = tab.getAttribute('aria-controls');
      }
    });
    return activeTabId === 'ready-to-ship-tab';
  }

  // Init populating
  getOptions(selector: string): Map<string, Option> {
    const options = new Map();
    const handler = (option: string, value?: string) => {
      this.handleOptionChange(option, value);
    };

    Array.from(this.optionsContainer.querySelectorAll(selector)).forEach(
      (el) => {
        const option = el.getAttribute('data-option');
        const widget = el.getAttribute('data-widget');
        if (option && widget) {
          // Pick the right option class based on the widget type
          switch (widget) {
            case 'dropdown':
              options.set(
                option,
                new SelectOption(option, el as HTMLElement, handler),
              );
              break;
            case 'buttons':
            case 'swatches':
              options.set(
                option,
                new LinkOption(option, el as HTMLElement, handler),
              );
              break;

            default:
              break;
          }
        }
      },
    );
    return options;
  }

  getInitialSelected(options: Map<string, Option>): Map<string, null | string> {
    const selected = new Map();
    options.forEach((option, key) => {
      selected.set(key, option.getInitialSelected());
      this.handleOptionChange(key, option.getInitialSelected());
    });
    return selected;
  }

  // Default button

  handleDefaultClick(event: Event) {
    this.selected.forEach((value, key) => {
      if (value === null) {
        const option = this.options.get(key);
        if (option) {
          option.showError();
        }
      }
    });
    event.preventDefault();
  }

  // Option handling

  handleOptionChange(option: string, value: null | string) {
    const selected = new Map(this.selected);
    selected.set(option, value);

    const complete =
      Array.from(selected.values()).filter((v) => v === null).length === 0;
    const matching = this.getMatchingItems(selected);

    // Update display of option values
    this.options.forEach((obj, key) => {
      // Get what is currently selected for this option
      const selectedValue = selected.get(key);

      // Figure out what would be available if this option did not have a selection
      const otherSelected = new Map(selected);
      otherSelected.set(key, null);
      const availableItems = this.getMatchingItems(otherSelected);
      const availableValues = this.getAvailableOptionValues(
        key,
        availableItems,
      );

      // Ask option to update itself

      obj.update(selectedValue, availableValues);
    });

    if (this.activeTab) {
      if (matching.length === 1) {
        // Handle updating of forms, galleries and texts
        // We have exactly one matching item. This could mean
        // that we're done with selection, and the customer has
        // made all the choices.
        const selectedItem = matching[0];
        this.showElement(this.mediaSets, selectedItem.media_set_id);

        if (complete) {
          // Selection is complete, and we have a single item
          this.showElement(this.prices, selectedItem.price_id);
          if (this.stickyPrices) {
            this.showElement(this.stickyPrices, selectedItem.price_id);
          }
          this.showElement(
            this.userExclusive,
            selectedItem.user_exclusive_class,
          );
          this.showElement(
            this.userExclusiveMessage,
            selectedItem.user_exclusive_message_id,
          );
          this.showElement(this.forms, selectedItem.form_id);
          if (this.stickyForms) {
            this.showElement(this.stickyForms, selectedItem.form_id);
          }
          this.showElement(this.names, selectedItem.name_id);
          this.showElement(this.descriptions, selectedItem.description_id);
          this.showElement(this.valueAdds, selectedItem.value_adds_id);
          this.showElement(this.chargerIcons, 'js-charger-icon-'+selectedItem.id.toString());
        } else {
          // selection isn't complete yet, use defaults
          this.showElement(this.prices, defaultPriceId);
          if (this.stickyPrices) {
            this.showElement(this.stickyPrices, defaultStickyPriceId);
          }
          this.showElement(this.userExclusive, defaultUserId);
          this.showElement(this.userExclusiveMessage, defaultUserMessageId);
          this.showElement(this.forms, defaultFormId);
          if (this.stickyForms) {
            this.showElement(this.stickyForms, defaultStickyFormId);
          }
          this.showElement(this.names, defaultNameId);
          this.showElement(this.descriptions, defaultDescriptionId);
          this.showElement(this.valueAdds, null);
          // this.showElement(this.chargerIcons, 'js-charger-icon-'+defaultChargerIconId);
        }
      } else if (matching.length === 0) {
        // We don't have any matches, so use the first item to select
        // a media set
        const firstItem = this.items[0];
        if (firstItem) {
          this.showElement(this.mediaSets, firstItem.media_set_id);
        }
        // Show the unavailable form, as well as default prices
        this.showElement(this.forms, unavailableFormId);
        if (this.stickyForms) {
          this.showElement(this.stickyForms, unavailableStickyFormId);
        }
        this.showElement(this.prices, defaultPriceId);
        if (this.stickyPrices) {
          this.showElement(this.stickyPrices, defaultStickyPriceId);
        }
        this.showElement(this.userExclusive, defaultUserId);
        this.showElement(this.userExclusiveMessage, defaultUserMessageId);
        this.showElement(this.names, defaultNameId);
        this.showElement(this.descriptions, defaultDescriptionId);
        this.showElement(this.valueAdds, null);
      } else {
        // We've got multiple matches. We do not check for complete here
        // as having multiple matches once complete is probably a setup
        // error.
        const firstMatch = matching[0];
        this.showElement(this.mediaSets, firstMatch.media_set_id);
        this.showElement(this.forms, defaultFormId);
        if (this.stickyForms) {
          this.showElement(this.stickyForms, defaultStickyFormId);
        }
        this.showElement(this.prices, defaultPriceId);
        if (this.stickyPrices) {
          this.showElement(this.stickyPrices, defaultStickyPriceId);
        }
        this.showElement(this.userExclusive, defaultUserId);
        this.showElement(this.userExclusiveMessage, defaultUserMessageId);
        this.showElement(this.names, defaultNameId);
        this.showElement(this.descriptions, defaultDescriptionId);
        this.showElement(this.valueAdds, null);
      }
      const url = this.getUrl(selected);
      this.replaceState(url);
    }
    this.selected = selected;
  }

  // State handling

  getUrl(selected: Map<string, null | string>): string {
    const path = `/${window.location.pathname.replace(/^\//, '')}`;
    const searchString = window.location.search;
    // eslint-disable-next-line compat/compat
    const params = new URLSearchParams(searchString);
    const isAdmin = params.has('edit');
    const query: { [key: string]: string } = {};

    // add utm tags to query if there are any
    for (const key of params.keys()) {
      const value = params.get(key);
      if (key.startsWith('utm_')) {
        if (value === null || value === undefined) {
          if (Object.prototype.hasOwnProperty.call(query, key)) {
            delete query[key];
          }
        } else {
          query[key] = value;
        }
      }
    }

    // add product options to query
    selected.forEach((value, option) => {
      if (value === null || value === undefined) {
        if (Object.prototype.hasOwnProperty.call(query, option)) {
          delete query[option];
        }
      } else {
        query[option] = value;
      }
    });

    let qs = queryString.stringify(query, { arrayFormat: 'comma' });
    if (qs.length > 0 && isAdmin) qs = `edit=true&${qs}`;
    return `${path}${qs.length > 0 ? `?${qs}` : ''}`;
  }

  replaceState(url: string) {
    window.history.replaceState({}, document.title, url);
  }

  // Item matching

  getMatchingItems(selected: Map<string, null | string>) {
    const options = Array.from(selected.keys());

    const matching: ItemList = this.items.filter((item) => {
      // Find out how many of our options match this item
      const matches = options.filter((option) => {
        const value = selected.get(option);
        // if nothing has been selected for this option, it can match anything
        if (value === null) {
          return true;
        }
        // if the value matches the items value for this option, it's a direct match
        if (value === item.product_family_options[option]) {
          // TODO FAMILY OPTIONS
          return true;
        }
        return false;
      });

      // If all options match, this item is match
      return matches.length === selected.size;
    });

    return matching;
  }

  getAvailableOptionValues(option: string, items: ItemList): Set<string> {
    return new Set(items.map((item) => item.product_family_options[option]));
  }

  // Tooltips

  initTooltips() {
    Array.from(
      this.container.querySelectorAll('.js-buy-section-tooltip'),
    ).forEach((el) => {
      const tooltipTitle = el.getAttribute('data-title');
      if (tooltipTitle) {
        new Tooltip(el as HTMLElement, {
          title: tooltipTitle,
          placement: 'top', // or bottom, left, right, and variations
          offset: '0, 14px',
          delay: { show: 250, hide: 0 },
          container: document.body,
          boundariesElement: document.body,
          arrowSelector: '.tooltip__arrow',
          innerSelector: '.tooltip__inner',
          template:
            '<div class="tooltip" role="tooltip"><div class="tooltip__arrow"></div><div class="tooltip__inner"></div></div>',
        });
        el.removeAttribute('data-title');
      }
    });
  }

  // Utils

  showElement(elements: Array<HTMLElement>, idOrClass: null | string) {
    elements.forEach((el) => {
      if (
        idOrClass &&
        (el.id === idOrClass || el.classList.contains(idOrClass))
      ) {
        el.classList.add('is-visible');
      } else {
        el.classList.remove('is-visible');
      }
    });
  }
}

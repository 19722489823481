import Cookies from 'js-cookie';
import querystring from 'query-string';

export default function captureUTM(cookieName: string, expiryDays: number = 30) {
  const params = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_term',
  ];
  const parsed = querystring.parse(location.search);

  if (!parsed) {
    return
  }

  // Extract utm tags from query string
  const result = {};
  params.forEach((param) => {
    if (param in parsed && parsed[param] !== '') {
      result[param] = parsed[param];
    }
  });

  // If we got utm tags, put them in a cookie for use in the backend segment tracking
  if (Object.keys(result).length > 0) {
    Cookies.set(cookieName, JSON.stringify(result), {
      expires: expiryDays,
    })
  }
}

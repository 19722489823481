import React, { Component } from 'react';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';

import type { Dispatch } from '../types';
import type {
  Item,
  Addon,
  PostOrder,
  InventoryList,
} from '../cart_widget/types';
import type { CartState } from '../cart_widget/state';
import { setQuantity } from '../cart_widget/actions';

import CartHeader from './CartHeader';
import CartItemList from './CartItemList';
import CartTotals from './CartTotals';
import CartFooter from './CartFooter';
import CartCheckoutButton from './CartCheckoutButton';

declare global {
  interface Window {
    cartCookieKey: any;
  }
}

type Props = {
  isLoaded: boolean;
  cartId: string;
  isEmpty: boolean;
  isLoginRequired: boolean;
  checkoutURL: string;
  loginRequiredURL: string;
  addons: Array<Addon>;
  items: Array<Item>;
  valueAdds: Array<Item>;
  postOrders: Array<PostOrder>;
  quantity: number;
  currency: string;
  freeShipping: boolean;
  freeShippingDifference?: number;
  couponCode?: string;
  staticUrl?: string;
  inventoryList: Array<InventoryList>;
  formatCurrency: (arg0: number, arg1: string) => string;
  updateQuantity: (number1: number, number2: number, string?: string) => void;
};

const mapStateToProps = (state: { cart: CartState; }) => ({
  isLoaded: state.cart.isLoaded,
  cartId: state.cart.data.id,
  isEmpty: state.cart.data.is_empty,
  isLoginRequired: state.cart.data.is_login_required,
  checkoutURL: state.cart.data.checkout_url,
  loginRequiredURL: state.cart.data.login_required_url,
  items: state.cart.data.items,
  addons: state.cart.addons,
  valueAdds: state.cart.data.value_adds,
  postOrders: state.cart.data.post_order,
  quantity: state.cart.data.quantity_incl_value_adds,
  currency: state.cart.data.currency,
  freeShipping: state.cart.data.estimated_shipping_price_base === 0,
  couponCode: state.cart.data.coupon_code,
  country: state.cart.data.country,
});

// TODO js/types.ts needs looking at for type Dispatch
const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateQuantity: (pid: number, newQuantity: number, cgid?: string) =>
    dispatch(setQuantity(pid, newQuantity, cgid)),
});

class Cart extends Component<Props> {

  isCheckoutEnabled = () => {
    for (var i = 0; i < this.props.items.length; i++) {
      if (this.props.items[i].quantity > this.props.items[i].max_quantity)
        return false;
    }
    return true;
  };

  render() {
    if (this.props.isLoaded) {
      if (this.props.isEmpty) {
        return (
          <div>
            <h1>Shopping Cart</h1>
            <p>Your cart is currently empty.</p>
            <a
              href="/gaming-products"
              className="button button--orange button--solid button--block button--large cart-empty-button"
            >
              Start shopping!
            </a>
          </div>
        );
      }
      return (
        <div className="cart__main">
          <div className="cart-left">
            <div className="cart__header">
              <h1>Cart</h1>
              <span>
                ({this.props.quantity}{' '}
                {`${this.props.quantity === 1 ? 'item' : 'items'}`})
              </span>
            </div>
            <div className="cart__items">
              <CartItemList
                items={this.props.items}
                valueAdds={this.props.valueAdds}
                postOrders={this.props.postOrders}
                currency={this.props.currency}
                inventoryList={this.props.inventoryList}
                formatCurrency={this.props.formatCurrency}
                updateQuantity={this.props.updateQuantity}
                tabIndex={{ tabIndex: '0' }}
                isLoginRequired={this.props.isLoginRequired}
              />
            </div>
          </div>

          <div className="cart-right js-cart-page-summary">
            <div className="cart-right-wrapper">
              <h2>Total</h2>
              <CartTotals
                couponCode={this.props.couponCode}
                formatCurrency={this.props.formatCurrency}
              />
              <CartCheckoutButton
                cartId={this.props.cartId}
                isLoginRequired={this.props.isLoginRequired}
                loginRequiredURL={this.props.loginRequiredURL}
                checkoutURL={this.props.checkoutURL}
                isCheckoutEnabled={this.isCheckoutEnabled()}
              />
              <CartFooter
                freeShipping={this.props.freeShipping}
                staticUrl={this.props.staticUrl}
                country={this.props.country}
              />
            </div>
          </div>
        </div>
      );
    }
    // No cart cookie means empty cart
    else if (Cookies.get(window.cartCookieKey) === undefined) {
      return (
        <div>
          <h1>Shopping Cart</h1>
          <p>Your cart is currently empty.</p>
          <a
            href="/gaming-products"
            className="button button--orange button--solid button--block button--large cart-empty-button"
          >
            Start shopping!
          </a>
        </div>
      );
    }
    // Loading state
    return (
      <div className="container--cart-page-loading">
        <span className="button is-loading"></span>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Cart);

import Big from 'big.js';
import CurrencyPattern from './CurrencyPattern';

// Types

type Currency = {
  symbol: string;
  precision?: number;
};

type Currencies = {
  [name: string]: Currency;
};

type Options = {
  pattern?: string;
  decimal?: string;
  group?: string;
  currencies?: Currencies;
  noDecimals?: boolean;
};


// Implementation

export default class CurrencyFormatter {
  pattern: CurrencyPattern;
  decimalSymbol: string;
  groupSymbol: string;
  currencies: Currencies;
  noDecimals: boolean;

  constructor(options: Options = {}) {
    this.pattern = CurrencyPattern.parse(options.pattern || '¤#,##0.00');
    this.decimalSymbol = options.decimal || '.';
    this.groupSymbol = options.group || ',';
    this.currencies = options.currencies || {};
    this.noDecimals = options.noDecimals || false;
  }

  format(value: number | string | Big, currencyCode: string): string {
    let currency;
    const precision = this.noDecimals ? 0 : 2;

    if (Object.prototype.hasOwnProperty.call(this.currencies, currencyCode)) {
      currency = this.currencies[currencyCode];
      currency.precision = precision;
    } else {
      currency = {
        symbol: currencyCode,
        precision: precision,
      };
    }
    return this.pattern.apply(
      value,
      currency.symbol,
      this.groupSymbol,
      this.decimalSymbol,
      currency.precision,
    );
  }
}

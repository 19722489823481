/**
 * Controls collapsible content sections, AKA: accordions
 */

type AccordionTypes = 'showOne' | 'showMany';

export default function createAccordions() {
  function initAccordion(target: HTMLElement) {
    const type = target.getAttribute('data-accordion-type');
    const activeClass = 'is-active';

    const button = target.querySelector('.js-collapsible-toggle');
    const controls = button.getAttribute('aria-controls');
    const content = document.getElementById(controls);
    const contentLinks = Array.from(content.querySelectorAll('a'));

    // Ensure that aria tabindex is set correctly on collapsed elements
    let isExpanded = target.classList.contains(activeClass);
    if (!isExpanded) {
      contentLinks.forEach((el) => {
        el.setAttribute('tabindex', '-1');
      });
    }

    const handleClose = (accordion: HTMLElement) => {
      const currToggle = accordion.querySelector('.js-collapsible-toggle');
      const currControls = currToggle.getAttribute('aria-controls');
      const currContent = document.getElementById(currControls);
      const currContentLinks = Array.from(currContent.querySelectorAll('a'));

      accordion.classList.remove(activeClass);
      currToggle.setAttribute('aria-expanded', 'false');
      currContentLinks.forEach((el) => {
        el.setAttribute('tabindex', '-1');
      });
    };

    const handleOpen = (accordion: HTMLElement) => {
      const currToggle = accordion.querySelector('.js-collapsible-toggle');
      const currControls = currToggle.getAttribute('aria-controls');
      const currContent = document.getElementById(currControls);
      const currContentLinks = Array.from(currContent.querySelectorAll('a'));

      if (type === 'showOne') {
        accordions.forEach((a) => {
          handleClose(a);
        });
      }

      accordion.classList.add(activeClass);
      currToggle.setAttribute('aria-expanded', 'true');
      currContentLinks.forEach((el) => {
        // check for unavailable link and keep its tabindex -1
        const unavailable = el.getAttribute('data-state') === 'unavailable';
        if (!unavailable) {
          el.setAttribute('tabindex', '');
        }
      });
    };

    // Add event listener
    button.addEventListener('click', () => {
      isExpanded = target.classList.contains(activeClass);

      if (isExpanded) {
        // We need to close this section
        handleClose(target);
      } else {
        // We need to open this section
        handleOpen(target);
      }
    });
  }

  const accordions: HTMLElement[] = Array.from(document.querySelectorAll('.js-collapsible'));
  accordions.forEach(initAccordion);
}

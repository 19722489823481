import serialize from 'form-serialize';
import FormFieldValidator from './FormFieldValidator';

// Types

type ValidHandler = (event: Event, data: Object | string) => boolean;
type InvalidHandler = (event: Event) => void;

type Options = {
  fieldSelector?: string;
  focusFirstInvalidField?: boolean;
  onValid?: ValidHandler;
  onInvalid?: InvalidHandler;
};

// Implementation

export default class FormValidator {
  form: HTMLFormElement;
  fields: Array<FormFieldValidator>;
  focusFirstInvalidField: boolean;
  onValid: null | ValidHandler;
  onInvalid: null | InvalidHandler;

  constructor(
    element: HTMLFormElement | string,
    {
      fieldSelector = '.form-field',
      focusFirstInvalidField = true,
      onValid,
      onInvalid,
    }: Options = {},
  ) {
    if (typeof element === 'string') {
      this.form = document.querySelector(element);
    } else {
      this.form = element;
    }

    if (!(this.form instanceof HTMLFormElement)) {
      throw Error('FormValidator must be instantiated with a form');
    }

    this.focusFirstInvalidField = focusFirstInvalidField;
    this.onValid = onValid;
    this.onInvalid = onInvalid;

    // We will handle form validation and reporting with javascript
    this.form.setAttribute('novalidate', '');
    this.form.addEventListener('submit', (event) => {
      this.onSubmit(event);
    });

    // Instantiate form field validators
    const fields: Array<HTMLElement> = Array.from(
      this.form.querySelectorAll(fieldSelector),
    );
    this.fields = fields
      .filter((field) => !field.hasAttribute('data-novalidate'))
      .map((field) => new FormFieldValidator(field));
  }

  // Validation

  validate(): boolean {
    const results = this.fields.map((f) => f.validate());
    return results.every((result) => result.isValid);
  }

  focusFirstField() {
    for (let f of this.fields) {
      if (f.field.classList.contains('is-invalid')) {
        let invalidField: HTMLElement = f.field.querySelector(
          '.form-field__widget',
        );
        invalidField.focus();
        return;
      }
    }
  }

  // Handlers

  onSubmit(event: Event) {
    const isValid = this.validate();

    // If the form isn't valid, stop the event and
    // call the invalid handler if we have one
    if (!isValid) {
      event.preventDefault();
      if (this.focusFirstInvalidField) {
        this.focusFirstField();
      }
      if (this.onInvalid) {
        this.onInvalid(event);
      }
      return;
    }

    // If we have a valid handler, call it and let
    // it stop processing if it returns false
    const data = serialize(this.form, { hash: true });
    if (this.onValid) {
      if (this.onValid(event, data) === false) {
        event.preventDefault();
      }
    }
  }
}

import React from 'react';

type Props = {
  choiceName: string;
  choiceType: string;
};

// Item Description Details
// Mobile only includes "choiceName", not "choiceType"
// Example: (Desktop) - Language: English
//          (Mobile) - English | Qty: 1
const CartItemChoice = (props: Props) => {
  if (props.choiceType === null || props.choiceName === null) {
    return null;
  }

  const { choiceType } = props;
  const choiceLabel = choiceType.charAt(0).toUpperCase() + choiceType.slice(1);

  return (
    <span className="widget-item__choice">
      <span className="widget-item__choice--type">{`${choiceLabel}: `}</span>
      {props.choiceName}
    </span>
  );
};

export default CartItemChoice;

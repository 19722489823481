import Big from 'big.js';
import type { RM } from 'big.js';

// Types

type Grouping = {
  first: number;
  rest: number;
};

type Precision = {
  min: number;
  max: number;
};

// Implementation

const prefixPattern = "((?:'[^']*'|[^0-9@#.,])*)";
const numberPattern = '([0-9@#.,E+]*)';
const suffixPattern = '(.*)';

const numberRegExp = new RegExp(prefixPattern + numberPattern + suffixPattern);

export default class CurrencyPattern {
  pattern: string;
  positive: string;
  negative: string;
  grouping: Grouping;
  int: Precision;
  frac: Precision;
  rounding: RM;

  constructor(
    pattern: string,
    positive: string,
    negative: string,
    grouping: Grouping,
    int: Precision,
    frac: Precision,
  ) {
    this.pattern = pattern;
    this.positive = positive;
    this.negative = negative;
    this.grouping = grouping;
    this.int = int;
    this.frac = frac;

    this.rounding = 2; // See big.js, this corresponds to ROUND_HALF_EVEN, same as Babel
  }

  apply(
    value: number | string | Big,
    currencySymbol: string,
    groupSymbol: string,
    decimalSymbol: string,
    currencyPrecision?: number,
  ): string {
    const val = Big(value.toString());

    const rounded = val.round(this.frac.max, this.rounding).abs();
    const [int, frac] = rounded.toFixed(this.frac.max).split('.', 2);

    const integer = this.formatInteger(int, groupSymbol);
    const fraction = this.formatFraction(frac, decimalSymbol);

    const template = val.gte(0) ? this.positive : this.negative;

    let number = template.replace('{}', integer + fraction);
    if (currencyPrecision === 0) {
      number = template.replace('{}', integer);
    }

    if (number.includes('¤')) {
      // TODO: handle more currency symbol/names
      number = number.replace('¤', currencySymbol);
    }

    return number;
  }

  formatInteger(value: string, groupSymbol: string): string {
    const width = value.length;
    let left = value;

    if (width < this.int.min) {
      left = '0'.repeat(this.int.min - width) + left;
    }
    let groupSize = this.grouping.first;
    let grouped = '';
    while (left.length > groupSize) {
      grouped = groupSymbol + left.slice(-groupSize) + grouped;
      left = left.slice(0, -groupSize);
      groupSize = this.grouping.rest;
    }
    return left + grouped;
  }

  formatFraction(value: string, decimalSymbol: string): string {
    let val = value;
    if (typeof value === 'undefined') val = '0';
    if (val.length < this.frac.min) {
      val += '0'.repeat(this.frac.min - val.length);
    }
    if (
      this.frac.max === 0 ||
      (this.frac.min === 0 && parseInt(val, 10) === 0)
    ) {
      return '';
    }
    while (val.length > this.frac.min && val.slice(val.length - 1) === '0') {
      val = val.substring(0, val.length - 1);
    }
    return decimalSymbol + val;
  }

  static parse(pattern: string | CurrencyPattern): CurrencyPattern {
    if (pattern instanceof CurrencyPattern) {
      return pattern;
    }

    let number;
    let positive;
    let negative;
    let integerPart;
    let fractionPart;

    // Handle negative and positive patters being different
    if (pattern.includes(';')) {
      const [pos, neg] = pattern.split(';', 2);
      ({ template: positive, number } = this.parseNumber(pos));
      ({ template: negative } = this.parseNumber(neg));
    } else {
      ({ template: positive, number } = this.parseNumber(pattern));
      ({ template: negative } = this.parseNumber(`-${pattern}`));
    }

    // TODO: handle E
    // TODO: handle @

    // Split into integer and fraction parts
    if (number.includes('.')) {
      const parts = number.split('.');
      integerPart = parts.slice(0, -1).join('.');
      fractionPart = parts.slice(-1).join();
    } else {
      integerPart = number;
      fractionPart = '';
    }

    // Figure out precision
    const int = this.parsePrecision(integerPart);
    const frac = this.parsePrecision(fractionPart);

    // Figure out grouping
    const grouping = this.parseGrouping(integerPart);

    return new CurrencyPattern(
      pattern,
      positive,
      negative,
      grouping,
      int,
      frac,
    );
  }

  static parseNumber(pattern: string): { template: string; number: string; } {
    let prefix = '';
    let number = '';
    let suffix = '';

    const parts = pattern.match(numberRegExp);
    if (parts !== null && parts !== undefined && parts.length === 4) {
      [, prefix, number, suffix] = parts;
    }

    if (number === '') {
      number = '#,##0.00';
    }

    return {
      template: `${prefix}{}${suffix}`,
      number,
    };
  }

  static parsePrecision(pattern: string): Precision {
    let min = 0;
    let max = 0;

    for (let i = 0; i < pattern.length; i += 1) {
      const char = pattern.charAt(i);
      if (char === '0') {
        min += 1;
        max += 1;
      } else if (char === '#') {
        max += 1;
      } else if (char !== ',') {
        break;
      }
    }

    return { min, max };
  }

  static parseGrouping(integer: string): Grouping {
    const width = integer.length;
    let g1 = integer.lastIndexOf(',');
    if (g1 === -1) {
      return { first: Infinity, rest: Infinity };
    }

    let g2 = integer.substring(0, g1).lastIndexOf(',');
    g1 = width - g1 - 1;
    if (g2 === -1) {
      return { first: g1, rest: g1 };
    }
    g2 = width - g1 - g2 - 2;

    return { first: g1, rest: g2 };
  }
}

import axios from 'axios';
import type { AxiosPromise } from 'axios';
import type { Addon, Cart } from './types';

type AddProducts = Array<{ product: number; quantity: number }>;

// API

export function fetchCart(): AxiosPromise<Object> {
  return axios.get('/api/v3/cart');
}

export function fetchCartAddons(): AxiosPromise<Array<Addon>> {
  return axios.get('/api/v3/cart/available-add-ons');
}

export function addCouponCode(couponCode: string): AxiosPromise<Cart> {
  return axios.patch('/api/v3/cart', {
    coupon_code: couponCode,
  });
}

export function updateProductQuantity(
  productId: number,
  quantity: number,
  cartGroupId?: string,
): AxiosPromise<Cart> {
  if (cartGroupId) {
    return axios.patch(`/api/v3/cart/items/${productId}/${cartGroupId}`, {
      quantity,
    });
  }
  return axios.patch(`/api/v3/cart/items/${productId}`, {
    quantity,
  });
}

export function addProducts(
  products: AddProducts,
  source?: string,
): AxiosPromise<Cart> {
  return axios.post('/api/v3/cart', {
    items: products,
    source,
  });
}

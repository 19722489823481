import { common_passwords } from './common_passwords';

var commonPasswordsArr = [];
var passwordStrengthBar, barSpanWeak, barSpanMedium, barSpanStrong, indicatorText;

function setupPasswordStrengthBar() {
    //add password strength bar
    passwordStrengthBar = document.createElement("div");
    passwordStrengthBar.className = 'indicator';
    barSpanWeak = document.createElement('span');
    barSpanWeak.className = 'weak';
    barSpanMedium = document.createElement('span');
    barSpanMedium.className = 'medium';
    barSpanStrong = document.createElement('span');
    barSpanStrong.className = 'strong';
    indicatorText = document.createElement('div');
    indicatorText.className = 'indicatorText';
    passwordStrengthBar.appendChild(barSpanWeak);
    passwordStrengthBar.appendChild(barSpanMedium);
    passwordStrengthBar.appendChild(barSpanStrong);
}

function validatePassword1() {
    let no = 0;
    let regExpWeak = /[a-z]/;
    let regExpMedium = /\d+/;
    let regExpStrong = /.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/;
    let regexDigits = /^[0-9]+$/;
    let helpTextArr = this.parentElement.querySelectorAll('[id$="_help"] ul li');
    barSpanWeak.className = '';
    barSpanMedium.className = '';
    barSpanStrong.className = '';
    indicatorText.className = 'indicatorText';
    if (this.value.length > 2) {
        if (regexDigits.test(this.value)) {
            helpTextArr[2].style.color = 'red';
        }
        else {
            helpTextArr[2].style.color = 'green';
        }
        if (this.value.length < 8) {
            helpTextArr[0].style.color = 'red';
        }
        else {
            helpTextArr[0].style.color = 'green';
        }
        if (common_passwords.includes(this.value)) {
            helpTextArr[1].style.color = 'red';
        }
        else {
            helpTextArr[1].style.color = 'green';
        }
        passwordStrengthBar.style.display = "block";
        passwordStrengthBar.style.display = "flex";
        if (this.value.length >= 3 && (this.value.match(regExpWeak) || this.value.match(regExpMedium) || this.value.match(regExpStrong))) no = 1;
        if (this.value.length >= 8 && ((this.value.match(regExpWeak) && this.value.match(regExpMedium)) || (this.value.match(regExpMedium) && this.value.match(regExpStrong)) || (this.value.match(regExpWeak) && this.value.match(regExpStrong)))) no = 2;
        if (this.value.length >= 8 && this.value.match(regExpWeak) && this.value.match(regExpMedium) && this.value.match(regExpStrong)) no = 3;

        if (no == 1) {
            barSpanWeak.classList.add('weak');
            barSpanWeak.classList.add("active");
            indicatorText.style.display = "block";
            indicatorText.textContent = "Password Strength: weak";
            indicatorText.classList.add("weak");
        }
        if (no == 2) {
            barSpanWeak.classList.add("active");
            barSpanWeak.classList.add('medium');
            barSpanMedium.classList.add("active");
            barSpanMedium.classList.add("medium");
            indicatorText.style.display = "block";
            indicatorText.textContent = "Password Strength: medium";
            indicatorText.classList.add("medium");
        }
        if (no == 3) {
            barSpanWeak.classList.add("active");
            barSpanWeak.classList.add('strong');
            barSpanMedium.classList.add("active");
            barSpanMedium.classList.add("strong");
            barSpanStrong.classList.add("active");
            barSpanStrong.classList.add("strong");
            indicatorText.style.display = "block";
            indicatorText.textContent = "Password Strength: strong";
            indicatorText.classList.add("strong");
        }
    }
    else {
        helpTextArr[0].removeAttribute('style');
        helpTextArr[1].removeAttribute('style');
        helpTextArr[2].removeAttribute('style');
        indicatorText.style.display = "none";
        indicatorText.className = '';
    }
}

export default function validateSignupForm(
    form: HTMLFormElement,
) {
    if (form instanceof HTMLFormElement) {
        setupPasswordStrengthBar();
        let passwordInput = form.querySelector('[id$="_password1"]');
        let helpTextDiv = form.querySelector('[id$="_password1_help"]');

        if (passwordInput && helpTextDiv) {
            helpTextDiv.appendChild(passwordStrengthBar);
            helpTextDiv.appendChild(indicatorText);
            passwordInput.oninput = validatePassword1;
        }
    }
}

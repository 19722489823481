import React from 'react';

import type { ChildProduct } from '../cart_widget/types';

import CartItemQuantity from './CartItemQuantity';
import CartItemChoice from '../cart_widget/CartItemChoice';
import SvgLock from '../components/svg/solid/Lock';
import SvgLockOpen from '../components/svg/solid/LockOpen';

type Props = {
  product: number,
  mediumThumbUrl: string,
  productPageUrl: string,
  name: string,
  choiceName: string,
  choiceType: string,
  quantity: number,
  maxQuantity: number,
  lineMsrp: number,
  linePrice: number,
  linePriceRpa: number,
  price: number,
  cartGroupId?: string,
  cartGroupRelation: string,
  childProducts: Array<ChildProduct> | null,
  isMembersOnly: boolean,
  isLoginRequired: boolean,
  inventory: number,
  currency: string,
  isUpsell: boolean,
  isBundle: boolean,
  isReducedPriceAddon: boolean,
  forceStaticQuantity: boolean,
  tabIndex?: Object,
  formatCurrency: (number: number, string: string) => string,
  updateQuantity: (number1: number, number2: number, string?: string) => void,
};

const CartItem = (props: Props) => {
  const isCustomizableProduct = props.cartGroupRelation === 'parent' && props.childProducts !== null;
  const hasChildren = isCustomizableProduct || props.isBundle;

  let price = '';
  let msrp = '';

  if (isCustomizableProduct && !props.isBundle) {
    let totalWithChildren = props.linePrice;
    props.childProducts.map(({ childPrice }) => {
      totalWithChildren += childPrice;
    });
    price = props.formatCurrency(totalWithChildren, props.currency);
  } else if (props.isBundle) {
    let totalPriceChildren = 0;
    props.childProducts.map(({ childPrice }) => {
      totalPriceChildren += childPrice;
    });
    price = props.formatCurrency(props.linePrice, props.currency);
    msrp = props.linePrice < totalPriceChildren ? props.formatCurrency(totalPriceChildren, props.currency) : null;
  } else if (props.isReducedPriceAddon) {
    price = props.formatCurrency(props.linePriceRpa, props.currency);
    msrp = props.linePriceRpa < props.lineMsrp ? props.formatCurrency(props.lineMsrp, props.currency) : null;
  } else {
    price = props.formatCurrency(props.linePrice, props.currency);
    msrp = props.linePrice < props.lineMsrp ? props.formatCurrency(props.lineMsrp, props.currency) : null;
  }
  const unitPrice = props.formatCurrency(props.price, props.currency);
  let membersOnly = null;
  let customProductImage = null;

  const svgLock = props.isLoginRequired ? (
    <SvgLock ariaHidden={true} title="" />
  ) : (
    <SvgLockOpen ariaHidden={true} title="" />
  );

  if (isCustomizableProduct && !props.isBundle) {
    const getCustomImageId = (children: Array<ChildProduct>) => {
      const imageIdArray: Array<number> = [];
      children.forEach((child) => {
        imageIdArray.push(child.shopProductId);
      });
      const sortedImageIdArray = imageIdArray.sort((a, b) => a - b);
      const imageLocalStorageId = sortedImageIdArray.join('-');
      return imageLocalStorageId;
    };

    const customImageId = getCustomImageId(props.childProducts);
    try {
      customProductImage = localStorage.getItem(
        `custom-product-${customImageId}`,
      );
    } catch (err) {
      console.log('localStorage image does not exist', err);
    }
  }

  if (props.isMembersOnly) {
    membersOnly = (
      <div className="widget-item__user-exclusive">
        {svgLock}Member exclusive
      </div>
    );
  }

  return (
    <tr className="widget-item">
      <td className="widget-item__thumbnail">
        <a
          href={props.productPageUrl}
          style={{
            backgroundImage:
              isCustomizableProduct && customProductImage
                ? `url('${customProductImage.toString()}')`
                : `url(${props.mediumThumbUrl})`,
          }}
          aria-hidden="true"
          tabIndex={-1}
        >
          <span className="sr-only">{props.name}</span>
        </a>
      </td>

      <td className="widget-item__name">
        <a
          href={props.productPageUrl}
          className="widget-item__link"
          {...props.tabIndex}
        >
          {props.name}
        </a>

        <div className="widget-item__description">
          {membersOnly}
          <CartItemChoice
            choiceType={props.choiceType}
            choiceName={props.choiceName}
          />

          {!props.forceStaticQuantity ? (
            <CartItemQuantity
              key={props.quantity}
              name={props.name}
              product={props.product}
              cartGroupId={props.cartGroupId}
              quantity={props.quantity}
              maxQuantity={props.maxQuantity}
              inventory={props.inventory}
              updateQuantity={props.updateQuantity}
              tabIndex={props.tabIndex}
            />
          ) : (
            `Quantity: ${props.quantity}`
          )}
          {hasChildren && (
            <div className="widget-item__children">
              <div className="widget-item__children-label">Included</div>
              <ul className="widget-item__children-list">
                {props.childProducts.map(
                  ({ productName, cartGroupId, shopProductId }) => (
                    <li
                      key={`${productName}-${cartGroupId}`}
                      data-shop-product-id={shopProductId}
                    >
                      {productName}
                    </li>
                  ),
                )}
              </ul>
            </div>
          )}
          {isCustomizableProduct && (
            <p className="widget-item__children-message">
              Made-to-order items cannot be canceled, returned, or exchanged.{' '}
              <a
                target="_blank"
                href="https://support.steelseries.com/hc/en-us/articles/221466947-Return-Policy"
              >
                Learn more
              </a>
            </p>
          )}
        </div>
      </td>

      <td className="widget-item__price">
        <span className="sr-only" aria-live="polite">
          {`${props.quantity} of ${props.name} at ${unitPrice} each`}
        </span>
        <div className="price-wrapper">
          {price}
          <div className="widget-item__msrp">
            {msrp}
          </div>
        </div>
        <div className="widget-item__remove">
          <button
            onClick={() =>
              props.updateQuantity(props.product, 0, props.cartGroupId)
            }
            type="button"
          >
            <span className="sr-only">
              Remove {props.quantity} {props.name} from your cart
            </span>
          </button>
        </div>
      </td>
    </tr>
  );
};

export default CartItem;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import FocusTrap from 'focus-trap-react';

import type { Dispatch } from '../types';
import type { SearchState, Result } from '.';
import { fetchSearch, scrollSearch, closeSearch } from '.';
import SearchField from './SearchField';
import SearchResultList from './SearchResultList';
import SearchCloseButton from './SearchCloseButton';

const DEBOUNCE_WAIT = 500;

type Props = {
  open: boolean,
  scrollIndex: number,
  results: Array<Result>,
  search: (string) => void,
  scroll: (number) => void,
  closeWidget: () => void,
};


const mapStateToProps = (state: { search: SearchState; }) => ({
  open: state.search.open,
  results: state.search.results,
  scrollIndex: state.search.scrollIndex,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  search: debounce((query: string) => dispatch(fetchSearch(query)), DEBOUNCE_WAIT),
  scroll: (scrollIndex: number) => dispatch(scrollSearch(scrollIndex)),
  closeWidget: () => dispatch(closeSearch()),
});

type ViewAllProps = {
  phrase: string,
}

class SearchViewAll extends Component<ViewAllProps> {
  render () {
    if (!this.props.phrase) return ('')
    return (
      <ul className={'search-widget__result-list'}>
        <li className={'search-widget__result'}>
          <a href={'search-catalog?phrase='+this.props.phrase}>
            <span>View all</span>
          </a>
        </li>
      </ul>
    )}
}

class SearchWidget extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      phrase: ''
    }
  }

  scrollUp = () => {
    let prevIndex = this.props.scrollIndex - 1;
    if (prevIndex < 0) {
      prevIndex = this.props.results.length - 1;
    }
    this.props.scroll(prevIndex);
  };

  scrollDown = () => {
    let nextIndex = this.props.scrollIndex + 1;
    if (nextIndex >= this.props.results.length) {
      nextIndex = 0;
    }
    this.props.scroll(nextIndex);
  };

  navigate = (result: Result) => {
    let res = result;
    if (res === undefined || res === null) {
      res = this.props.results[this.props.scrollIndex];
    }
    if (res !== undefined && res !== null) {
      // @TODO: move to a shared function?
      // @TODO: seems nasty to have mess with window in here?
      const pageUrl = res.page_url;

      if ('dataLayer' in window && typeof window.dataLayer.push === 'function') {
        window.dataLayer.push({
          event: 'SendEvent',
          eventCategory: 'Search',
          eventAction: 'Navigate',
          eventLabel: res.page_url,
          eventTimeout: 1000,
          eventCallback: () => {
            window.location = pageUrl;
          },
        });
      } else {
        window.location = pageUrl;
      }
    }
  };

  navigateResultsPage = (input: string) => {
    if (input.length < 2) return;
    const trimmedInput = input.trim();
    const searchUrl = `/search-catalog?phrase=${trimmedInput}`;
    window.location.href = searchUrl;
  };

  handleInputChange = (phrase: string) => {
    this.setState({phrase: phrase})
  }

  render() {
    // @TODO: maybe there should be a loading and/or empty state?
    if (!this.props.open) {
      return null;
    }

    return (
      <div
        className={`search-widget ${this.props.open ? 'is-open' : ''}`}
        role="search"
        aria-expanded={this.props.open}
      >
        <FocusTrap
          focusTrapOptions={{
            escapeDeactivates: false,
            clickOutsideDeactivates: true,
            allowOutsideClick: true,
          }}
        >
          <div className="search-widget__inner">
            <div className="search-widget--caret" />
            <SearchField
              search={this.props.search}
              scrollUp={this.scrollUp}
              scrollDown={this.scrollDown}
              navigate={this.navigate}
              navigateResultsPage={this.navigateResultsPage}
              handleInputChange={this.handleInputChange}
            />
            <SearchViewAll phrase={this.state.phrase} />
            <SearchResultList
              results={this.props.results}
              navigate={this.navigate}
            />
            <SearchCloseButton closeWidget={this.props.closeWidget} />
          </div>
        </FocusTrap>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchWidget);

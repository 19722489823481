import React, { Component } from 'react';
import { connect } from 'react-redux';

import { openCart } from './actions';

import type { CartState } from './state';
import type { Dispatch } from '../types';

type Props = {
  open: boolean;
  quantity: number;
  enableCartWidget: string;
  openPanel: () => void;
};

const mapStateToProps = (state: { cart: CartState }) => ({
  open: state.cart.open,
  quantity: state.cart.data.quantity_incl_value_adds,
});

const mapDispatchToProps = (dispatch: any) => ({
  openPanel: () => dispatch(openCart()),
});

class CartIcon extends Component<Props> {
  // Open widget
  handleMouseEnter = (event: React.MouseEvent) => {
    if (this.props.enableCartWidget === '') {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
    this.props.openPanel();
  };

  render() {
    const quantityWord = this.props.quantity === 1 ? 'item' : 'items';

    return (
      <a
        href="/cart"
        className={`button button--link simple-cart-icon ${this.props.enableCartWidget}`}
        aria-label={`Cart (${this.props.quantity} ${quantityWord}), opens dialog`}
        onMouseEnter={this.handleMouseEnter}
      >
        <span className="courtesy-navigation__icon">
          <svg
            aria-hidden="true"
            role="img"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
          >
            <path d="M551.991 64H129.28l-8.329-44.423C118.822 8.226 108.911 0 97.362 0H12C5.373 0 0 5.373 0 12v8c0 6.627 5.373 12 12 12h78.72l69.927 372.946C150.305 416.314 144 431.42 144 448c0 35.346 28.654 64 64 64s64-28.654 64-64a63.681 63.681 0 0 0-8.583-32h145.167a63.681 63.681 0 0 0-8.583 32c0 35.346 28.654 64 64 64 35.346 0 64-28.654 64-64 0-17.993-7.435-34.24-19.388-45.868C506.022 391.891 496.76 384 485.328 384H189.28l-12-64h331.381c11.368 0 21.177-7.976 23.496-19.105l43.331-208C578.592 77.991 567.215 64 551.991 64zM240 448c0 17.645-14.355 32-32 32s-32-14.355-32-32 14.355-32 32-32 32 14.355 32 32zm224 32c-17.645 0-32-14.355-32-32s14.355-32 32-32 32 14.355 32 32-14.355 32-32 32zm38.156-192H171.28l-36-192h406.876l-40 192z" />
          </svg>
          <span className="navigation-cart__badge">
            {`${this.props.quantity ? this.props.quantity : ''}`}
          </span>
        </span>
      </a>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CartIcon);

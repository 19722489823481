import queryString from 'query-string';

// Helper class to represent facet urls

export default class FacetURL {
  path: string;
  query: Object;

  constructor(path: string, query: Object) {
    this.path = path;
    this.query = query;
  }

  static unparse(path: string, query: Object) {
    const qs = queryString.stringify(query, { arrayFormat: 'comma' });
    return `${path}${qs.length > 0 ? `?${qs}` : ''}`;
  }

  partial() {
    const query = { ...this.query, partial: true };
    return FacetURL.unparse(this.path, query);
  }

  stringify() {
    return FacetURL.unparse(this.path, this.query);
  }

  withQuery(query: Object) {
    const newQuery = { ...this.query, ...query };
    return new FacetURL(this.path, newQuery);
  }
}

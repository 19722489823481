import React from 'react';
import type {
  LoginRequiredCheckoutButtonType,
  ProceedToCheckoutButtonType,
} from '../cart_widget/types';

type Props = {
  cartId: string;
  isLoginRequired: boolean;
  loginRequiredURL: string;
  checkoutURL: string;
  isCheckoutEnabled: boolean;
};

const LoginRequiredCheckoutButton = (
  props: LoginRequiredCheckoutButtonType,
) => {
  return (
    <div className="cart__checkout-button">
      <a
        href={props.loginRequiredURL}
        className="button button--solid button--purple button--block button--large"
      >
        Log in to check out
      </a>
    </div>
  );
};

const ProceedToCheckoutButton = (props: ProceedToCheckoutButtonType) => {
  return (
    <div className="cart__checkout-button js-mobile-checkout-button">
      <form action={props.checkoutURL} method="post">
        <input type="hidden" name="cart_id" value={props.cartId} />
        <button
          type="submit"
          className={`button button--solid button--orange button--block button--large ${props.isCheckoutEnabled ? "" : "is-inactive"}`}
        >
          🔒 Secure Checkout
        </button>
      </form>
    </div>
  );
};

const CartCheckoutButton = (props: Props) => {
  if (props.isLoginRequired) {
    return (
      <LoginRequiredCheckoutButton loginRequiredURL={props.loginRequiredURL} />
    );
  }
  return (
    <ProceedToCheckoutButton
      checkoutURL={props.checkoutURL}
      cartId={props.cartId}
      isCheckoutEnabled={props.isCheckoutEnabled}
    />
  );
};

export default CartCheckoutButton;

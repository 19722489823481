import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import type { Item, PostOrder, InventoryList } from '../cart_widget/types';
import CartItem from './CartItem';
import CartValueAdd from '../cart_widget/CartValueAdd';
import CartPostOrder from '../cart_widget/CartPostOrder';

// Props

type Props = {
  items: Array<Item>;
  valueAdds: Array<Item>;
  postOrders: Array<PostOrder>;
  currency: string;
  tabIndex?: Object;
  inventoryList: Array<InventoryList>;
  formatCurrency: (number: number, string: string) => string;
  updateQuantity: (number1: number, number2: number) => void;
  isLoginRequired: boolean;
};

type ChildProduct = {
  shopProductId: number;
  cartGroupId: string;
  productName: string;
  childPrice: number;
};

// Parent of CartItem
const CartItemList = (props: Props) => {
  const getInventory = (sku: string, list: InventoryList[]) => {
    const itemSku = sku;
    const allInventory = list;

    if (allInventory instanceof Array) {
      for (let i = 0; i < allInventory.length; i++) {
        if (allInventory[i].sku === itemSku) return allInventory[i].inventory;
      }
    }
  };

  // init children products
  const childProducts: Array<ChildProduct> = [];
  function initChildren(child: ChildProduct[]) {
    props.items.map((item) => {
      if (item.cart_group_relation === 'child' || item.cart_group_relation === 'bundle_child') {
        child.push({
          shopProductId: item.product,
          cartGroupId: item.cart_group_id,
          productName: item.name,
          childPrice: item.line_price,
        });
      }
      return false;
    });
  }
  initChildren(childProducts);
  // Slice to make shallow copy
  // Reverse so items will be added on top
  const allItems = props.items
    .slice(0)
    .reverse()
    .map((item) => {
      if ((item.cart_group_relation !== 'child') && (item.cart_group_relation !== 'bundle_child')) {
        const currentChildren = childProducts.filter(
          (child) => child.cartGroupId === item.cart_group_id,
        );

        return (
          <CSSTransition
            key={`item-${item.cart_group_id}-${item.product}`}
            timeout={600}
            classNames="cartItemTransition"
          >
            <CartItem
              product={item.product}
              name={item.name}
              choiceName={item.choice_name}
              choiceType={item.choice_type}
              mediumThumbUrl={item.medium_thumb_url}
              productPageUrl={item.product_page_url}
              quantity={item.quantity}
              maxQuantity={item.max_quantity}
              lineMsrp={item.line_msrp}
              linePrice={item.line_price}
              linePriceRpa={item.line_price_rpa}
              isUpsell={item.is_upsell}
              isBundle={item.is_bundle}
              isReducedPriceAddon={item.is_reduced_price_addon}
              forceStaticQuantity={item.force_static_quantity}
              price={item.price}
              cartGroupId={item.cart_group_id ? item.cart_group_id : null}
              cartGroupRelation={item.cart_group_relation}
              childProducts={currentChildren}
              isMembersOnly={item.is_members_only}
              isLoginRequired={props.isLoginRequired}
              currency={props.currency}
              inventory={getInventory(item.sku, props.inventoryList)}
              formatCurrency={props.formatCurrency}
              updateQuantity={props.updateQuantity}
              tabIndex={props.tabIndex}
            />
          </CSSTransition>
        );
      }
    });

  const valueAdds = props.valueAdds.map((valueAdd) => (
    <CSSTransition
      key={`valueadd-${valueAdd.product}`}
      timeout={600}
      classNames="cartItemTransition"
    >
      <CartValueAdd
        key={`valueadd-${valueAdd.product}`}
        product={valueAdd.product}
        name={valueAdd.name}
        mediumThumbUrl={valueAdd.medium_thumb_url}
        productPageUrl={valueAdd.product_page_url}
        quantity={valueAdd.quantity}
        linePrice={valueAdd.line_price}
        price={valueAdd.price}
        currency={props.currency}
        formatCurrency={props.formatCurrency}
        tabIndex={props.tabIndex}
      />
    </CSSTransition>
  ));

  const postOrders = props.postOrders.map((postOrder) => (
    <CSSTransition
      key={`postorder-${postOrder.id}`}
      timeout={600}
      classNames="cartItemTransition"
    >
      <CartPostOrder
        key={`postorder-${postOrder.id}`}
        name={postOrder.name}
        description={postOrder.description}
        meta={postOrder.meta}
        value={postOrder.value}
        currency={props.currency}
        formatCurrency={props.formatCurrency}
      />
    </CSSTransition>
  ));

  return (
    <div className="cart-widget__items-list cart-widget__section">
      <table role="presentation">
        <TransitionGroup component="tbody">
          {allItems}
          {valueAdds.length > 0 || postOrders.length > 0 ? (
            <tr className="widget-item--value-add-separator">
              <td>Included Free</td>
              <td colSpan={2}>
                <hr />
              </td>
            </tr>
          ) : null}
          {valueAdds}
          {postOrders}
        </TransitionGroup>
      </table>
    </div>
  );
};

export default CartItemList;

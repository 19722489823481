import React from 'react';

import type { Result } from '.';
import SearchResult from './SearchResult';

type Props = {
  results: Array<Result>,
  navigate: any,
};

export default function SearchResultList({ results, navigate }: Props) {
  return (
    <ul
      className="search-widget__result-list"
      aria-live="polite"
      aria-label="Search results"
    >
      {results.map(r => (
        <SearchResult
          key={r.id}
          result={r}
          active={false}
          navigate={navigate}
        />
      ))}
    </ul>
  );
}

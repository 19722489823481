import Big from 'big.js';
import CurrencyFormatter from '../currency/CurrencyFormatter';

// Types

type Options = {
  shippingSelector?: string,
  salesTaxSelector?: string,
  totalSelector?: string,
  zeroText?: string,
  zeroClass?: string,
}


// Implementation

export default function updateShippingPrice(
  element: HTMLElement,
  {
    shippingSelector = '.js-summary-shipping',
    salesTaxSelector = '.js-summary-sales-tax',
    totalSelector = '.js-summary-total',
    zeroText = 'Free',
    zeroClass = 'cart-total__free',
  }: Options = {},
) {
  const formatter = new CurrencyFormatter(window.currencyFormat || {});

  const currency = element.getAttribute('data-currency');
  const subtotal = element.getAttribute('data-calculation-subtotal');
  const salesTax = element.getAttribute('data-calculation-sales-tax');
  const methodPrice = element.getAttribute('data-price');
  const methodSalesTax = element.getAttribute('data-sales-tax');

  if (!currency || !subtotal || !salesTax || !methodPrice || !methodSalesTax) {
    return;
  }

  // Calculate a new total
  const newSalesTax = Big(salesTax).plus(methodSalesTax);
  const newTotal = Big(subtotal).plus(methodPrice).plus(methodSalesTax);

  // Format output
  const shippingFree = Big(methodPrice).eq(0);
  const shippingHTML = shippingFree ? zeroText : formatter.format(methodPrice, currency);
  const totalHTML = formatter.format(newTotal, currency);
  const salesTaxHTML = formatter.format(newSalesTax, currency);

  // Set output
  const shippingElements = Array.from(document.querySelectorAll(shippingSelector));
  shippingElements.forEach((el) => {
    el.innerHTML = shippingHTML;
    if (shippingFree) {
      el.classList.add(zeroClass);
    } else {
      el.classList.remove(zeroClass);
    }
  });
  const totalElements = Array.from(document.querySelectorAll(totalSelector));
  totalElements.forEach((el) => {
    el.innerHTML = totalHTML;
  });
  const salesTaxElements = Array.from(document.querySelectorAll(salesTaxSelector));
  salesTaxElements.forEach((el) => {
    el.innerHTML = salesTaxHTML;
  });
}

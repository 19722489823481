declare global {
  var Stripe: any;
}

// Types

type Options = {
  fontBreakpoint: number;
};

type StripeEvent = {
  error: any;
  elementType: any;
  brand?: string;
};

// Constants

const stripeSettings = {
  classes: {
    base: 'form-field__widget--stripe',
    complete: 'is-complete',
    empty: 'is-empty',
    focus: 'is-focused',
    invalid: 'is-invalid',
  },
  style: {
    base: {
      color: '#161616',
      fontFamily:
        '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      fontWeight: '400',
      fontSize: '16px',
      fontSmoothing: 'antialiased',

      '::placeholder': {
        color: '#999999',
      },
    },
    invalid: {
      color: '#444444',
    },
  },
};

// Implementation

export default class StripePaymentMethod {
  // TODO: tighten up these types
  fontBreakpoint: number;
  stripe: any;
  cardNumberErrors: any;
  cardNumberField: any;
  cardNumber: any;
  cardExpiryErrors: any;
  cardExpiryField: any;
  cardExpiry: any;
  cardCvcErrors: any;
  cardCvcField: any;
  cardCvc: any;
  sourceInput: HTMLInputElement;
  icons: Array<HTMLElement>;

  constructor(
    publishableKey: string,
    methodCode: string,
    fontBreakpoint = 992,
  ) {
    this.fontBreakpoint = fontBreakpoint;

    this.icons = [];
    const iconsContainer = Array.from(
      document.querySelectorAll(`.${methodCode}-icons`),
    );
    iconsContainer.forEach((icon) => {
      if (icon instanceof HTMLElement) {
        Array.from(icon.querySelectorAll('[data-brand]')).forEach((i) => {
          this.icons.push(i as HTMLElement);
        });
      }
    });

    const sourceElement = document.querySelector(
      `#id_${methodCode}-stripe_source`,
    );
    if (!(sourceElement instanceof HTMLInputElement)) {
      throw Error(
        `Missing stripe source input. Expected '#id_${methodCode}-stripe_source'`,
      );
    }
    this.sourceInput = sourceElement;

    // Grab the html elements we need
    this.cardNumberErrors = document.querySelector(
      `#${methodCode}-card_number-errors`,
    );
    this.cardNumberField = document.querySelector(`#${methodCode}-card_number`);

    this.cardExpiryErrors = document.querySelector(
      `#${methodCode}-card_expiry-errors`,
    );
    this.cardExpiryField = document.querySelector(`#${methodCode}-card_expiry`);

    this.cardCvcErrors = document.querySelector(
      `#${methodCode}-card_cvc-errors`,
    );
    this.cardCvcField = document.querySelector(`#${methodCode}-card_cvc`);

    // Initialise stripe
    this.stripe = Stripe(publishableKey);
    const elements = this.stripe.elements();

    this.cardNumber = elements.create('cardNumber', stripeSettings);
    this.cardNumber.mount(this.cardNumberField);
    this.cardNumber.on('change', (e: StripeEvent) => this.cardNumberChanged(e));
    this.cardNumber.on('change', (e: StripeEvent) => {
      this.fieldChanged(this.cardNumber, this.cardNumberErrors, e);
    });

    this.cardExpiry = elements.create('cardExpiry', stripeSettings);
    this.cardExpiry.mount(this.cardExpiryField);
    this.cardExpiry.on('change', (e: StripeEvent) => {
      this.fieldChanged(this.cardExpiry, this.cardExpiryErrors, e);
    });

    this.cardCvc = elements.create('cardCvc', stripeSettings);
    this.cardCvc.mount(this.cardCvcField);
    this.cardCvc.on('change', (e: StripeEvent) => {
      this.fieldChanged(this.cardCvc, this.cardCvcErrors, e);
    });

    // Handle "responsive" style updates
    window.addEventListener('resize', this.windowResized.bind(this));
  }

  focus() {
    this.focusFirstIncompleteField();
  }

  submit(options: {}): Promise<void> {
    // TODO: use billing address from options
    return new Promise((resolve, reject) => {
      this.stripe.createSource(this.cardNumber).then((result: any) => {
        // Handle error cases
        if (result.error) {
          // handle errors?
          this.focusFirstIncompleteField();
          return reject(new Error('Error at Stripe createSource Promise'));
        }
        // Set value
        this.sourceInput.value = result.source.id;
        return resolve();
      });
    });
  }

  // Helpers

  focusFirstIncompleteField() {
    if (!this.cardNumberField.classList.contains('is-complete')) {
      this.cardNumber.focus();
    } else if (!this.cardExpiryField.classList.contains('is-complete')) {
      this.cardExpiry.focus();
    } else if (!this.cardCvcField.classList.contains('is-complete')) {
      this.cardCvc.focus();
    }
  }

  // Event handlers

  cardNumberChanged(event: StripeEvent) {
    if (this.icons === null || this.icons === undefined) {
      return;
    }

    if (event.brand && event.brand !== 'unknown') {
      this.icons.forEach((icon) => {
        if (icon.getAttribute('data-brand') === event.brand) {
          icon.classList.remove('is-dimmed');
        } else {
          icon.classList.add('is-dimmed');
        }
      });
    } else {
      this.icons.forEach((icon) => icon.classList.remove('is-dimmed'));
    }
  }

  fieldChanged(
    field: any,
    errorElement: null | HTMLElement,
    event: StripeEvent,
  ) {
    if (errorElement instanceof HTMLElement) {
      if (event.error) {
        this.addMessage(field, errorElement, event.error.message);
      } else {
        this.removeMessage(errorElement);
      }
    }
  }

  windowResized() {
    let size = '16px';
    if (window.innerWidth <= this.fontBreakpoint) {
      size = '14px';
    }

    const style = { style: { base: { fontSize: size } } };
    this.cardNumber.update(style);
    this.cardExpiry.update(style);
    this.cardCvc.update(style);
  }

  // Messages

  addMessage(field: any, errorElement: HTMLElement, message: string) {
    // Remove existing messages for this field
    this.removeMessage(errorElement);

    // Create new message
    const newMessage = document.createElement('li');
    newMessage.textContent = message;
    newMessage.addEventListener('click', () => field.focus());
    errorElement.appendChild(newMessage);
    errorElement.classList.remove('is-empty');
  }

  removeMessage(errorElement: HTMLElement) {
    errorElement.classList.add('is-empty');

    const errors = Array.from(errorElement.querySelectorAll('li'));
    setTimeout(
      () =>
        errors.forEach((e) => {
          if (e.parentNode === errorElement) {
            errorElement.removeChild(e);
          }
        }),
      400,
    );
  }
}

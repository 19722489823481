import type { Dispatch, Thunk } from '../types';
import type { Cart, Addon } from './types';
import * as api from './api';

export const CART_OPEN = 'CART_OPEN';
export const CART_CLOSE = 'CART_CLOSE';
export const CART_ADD_COUPON = 'CART_ADD_COUPON';
export const CART_ADD_COUPON_SUCCESS = 'CART_ADD_COUPON_SUCCESS';
export const CART_ADD_COUPON_FAILURE = 'CART_ADD_COUPON_FAILURE';
export const CART_FETCH_REQUEST = 'CART_FETCH_REQUEST';
export const CART_FETCH_SUCCESS = 'CART_FETCH_SUCCESS';
export const CART_FETCH_FAILURE = 'CART_FETCH_FAILURE';
export const CART_ADD_PRODUCT_REQUEST = 'CART_ADD_PRODUCT_REQUEST';
export const CART_ADD_PRODUCT_SUCCESS = 'CART_ADD_PRODUCT_SUCCESS';
export const CART_ADD_PRODUCT_FAILURE = 'CART_ADD_PRODUCT_FAILURE';
export const CART_SET_QUANTITY_REQUEST = 'CART_SET_QUANTITY_REQUEST';
export const CART_SET_QUANTITY_SUCCESS = 'CART_SET_QUANTITY_SUCCESS';
export const CART_SET_QUANTITY_FAILURE = 'CART_SET_QUANTITY_FAILURE';
export const CART_FETCH_ADDONS_REQUEST = 'CART_FETCH_ADDONS_REQUEST';
export const CART_FETCH_ADDONS_SUCCESS = 'CART_FETCH_ADDONS_SUCCESS';
export const CART_FETCH_ADDONS_FAILURE = 'CART_FETCH_ADDONS_FAILURE';

// Cart Fetch Action Types
export type CartOpenAction = {
  type: 'CART_OPEN';
};

export type CartCloseAction = {
  type: 'CART_CLOSE';
};

export type CartAddCouponAction = {
  type: 'CART_ADD_COUPON';
};

export type CartAddCouponSuccessAction = {
  type: 'CART_ADD_COUPON_SUCCESS';
  data: Cart;
};

export type CartAddCouponFailureAction = {
  type: 'CART_ADD_COUPON_FAILURE';
  error: string;
};

export type CartAddProductRequestAction = {
  type: 'CART_ADD_PRODUCT_REQUEST';
};

export type CartAddProductSuccessAction = {
  type: 'CART_ADD_PRODUCT_SUCCESS';
  data: Cart;
};

export type CartAddProductFailureAction = {
  type: 'CART_ADD_PRODUCT_FAILURE';
  error: Object;
};

export type CartSetQuantityRequestAction = {
  type: 'CART_SET_QUANTITY_REQUEST';
};

export type CartSetQuantitySuccessAction = {
  type: 'CART_SET_QUANTITY_SUCCESS';
  productId: number;
  quantity: number;
  data: Cart;
};

export type CartSetQuantityFailureAction = {
  type: 'CART_SET_QUANTITY_FAILURE';
  error: Object;
};

export type CartFetchAddonsAction = {
  type: 'CART_FETCH_ADDONS_REQUEST';
};

export type CartAddonsSuccessAction = {
  type: 'CART_FETCH_ADDONS_SUCCESS';
  data: Array<Addon>;
};

export type CartAddonsFailureAction = {
  type: 'CART_FETCH_ADDONS_FAILURE';
  error: Object;
};

export type CartFetchRequestAction = {
  type: 'CART_FETCH_REQUEST';
};

export type CartFetchSuccessAction = {
  type: 'CART_FETCH_SUCCESS';
  data: Cart;
};

export type CartFetchFailureAction = {
  type: 'CART_FETCH_FAILURE';
  error: Object;
};

export type CartAction =
  | CartOpenAction
  | CartCloseAction
  | CartAddCouponAction
  | CartAddCouponSuccessAction
  | CartAddCouponFailureAction
  | CartAddProductRequestAction
  | CartAddProductSuccessAction
  | CartAddProductFailureAction
  | CartSetQuantityRequestAction
  | CartSetQuantitySuccessAction
  | CartSetQuantityFailureAction
  | CartFetchAddonsAction
  | CartAddonsSuccessAction
  | CartAddonsFailureAction
  | CartFetchRequestAction
  | CartFetchSuccessAction
  | CartFetchFailureAction;

export function openCart(): CartOpenAction {
  return {
    type: CART_OPEN,
  };
}

export function closeCart(): CartCloseAction {
  return {
    type: CART_CLOSE,
  };
}

export function addCouponRequest(): CartAddCouponAction {
  return {
    type: CART_ADD_COUPON,
  };
}

export function addCouponSuccess(data: any): CartAddCouponSuccessAction {
  return {
    type: CART_ADD_COUPON_SUCCESS,
    data,
  };
}

export function addCouponFailure(error: string): CartAddCouponFailureAction {
  return {
    type: CART_ADD_COUPON_FAILURE,
    error,
  };
}

export function addProductRequest(): CartAddProductRequestAction {
  return {
    type: CART_ADD_PRODUCT_REQUEST,
  };
}

export function addProductSuccess(data: any): CartAddProductSuccessAction {
  return {
    type: CART_ADD_PRODUCT_SUCCESS,
    data,
  };
}

export function addProductFailure(error: Object): CartAddProductFailureAction {
  return {
    type: CART_ADD_PRODUCT_FAILURE,
    error,
  };
}

export function setQuantityRequest(): CartSetQuantityRequestAction {
  return {
    type: CART_SET_QUANTITY_REQUEST,
  };
}

export function setQuantitySuccess(
  productId: number,
  quantity: number,
  data: any,
  cartGroupId?: string,
): CartSetQuantitySuccessAction {
  return {
    type: CART_SET_QUANTITY_SUCCESS,
    quantity,
    data,
    productId,
  };
}

export function setQuantityFailure(
  error: Object,
): CartSetQuantityFailureAction {
  return {
    type: CART_SET_QUANTITY_FAILURE,
    error,
  };
}

export function fetchAddonsRequest(): CartFetchAddonsAction {
  return {
    type: CART_FETCH_ADDONS_REQUEST,
  };
}

export function fetchAddonsSuccess(
  data: Array<Addon>,
): CartAddonsSuccessAction {
  return {
    type: CART_FETCH_ADDONS_SUCCESS,
    data,
  };
}

export function fetchAddonsFailure(error: Object): CartAddonsFailureAction {
  return {
    type: CART_FETCH_ADDONS_FAILURE,
    error,
  };
}

export function fetchCartRequest(): CartFetchRequestAction {
  return {
    type: CART_FETCH_REQUEST,
  };
}

export function fetchCartSuccess(data: any): CartFetchSuccessAction {
  return {
    type: CART_FETCH_SUCCESS,
    data,
  };
}

export function fetchCartFailure(error: Object): CartFetchFailureAction {
  return {
    type: CART_FETCH_FAILURE,
    error,
  };
}

// Async actions

export function fetchAddons(): Thunk {
  return (dispatch: Dispatch) => {
    dispatch(fetchAddonsRequest());
    return api.fetchCartAddons().then(
      (response) => {
        dispatch(fetchAddonsSuccess(response.data));
      },
      (error) => {
        dispatch(fetchAddonsFailure(error));
      },
    );
  };
}

export function fetchCart(): Thunk {
  return (dispatch: Dispatch) => {
    dispatch(fetchCartRequest());
    return api.fetchCart().then(
      (response) => {
        dispatch(fetchCartSuccess(response.data));
        dispatch(fetchAddons());
      },
      (error) => {
        dispatch(fetchCartFailure(error));
      },
    );
  };
}

export function addProducts(
  products: Array<any>,
  source?: string,
  errorCallback?: () => void,
): Thunk {
  return (dispatch) => {
    dispatch(addProductRequest());
    return api.addProducts(products, source).then(
      (response) => {
        dispatch(addProductSuccess(response.data));
        dispatch(fetchAddons());
      },
      (error) => {
        dispatch(addProductFailure(error));
        if (errorCallback) {
          errorCallback();
        }
      },
    );
  };
}

export function setQuantity(pid: number, qty: number, cgid?: string): Thunk {
  return (dispatch) => {
    dispatch(setQuantityRequest());
    return api.updateProductQuantity(pid, qty, cgid).then(
      (response) => {
        dispatch(setQuantitySuccess(pid, qty, response.data, cgid));
        dispatch(fetchAddons());
      },
      (error) => {
        dispatch(setQuantityFailure(error));
      },
    );
  };
}

export function addCouponCode(couponCode: string): Thunk {
  return (dispatch) => {
    dispatch(addCouponRequest());
    return api.addCouponCode(couponCode).then(
      (response) => {
        dispatch(addCouponSuccess(response.data));
      },
      (error) => {
        dispatch(addCouponFailure(error.response.data.fields.coupon_code[0]));
      },
    );
  };
}

function isHighContrastMode(doc: Document, body: HTMLElement) {
  /*
  Test whether we are in high contrast mode. This is done by:
    1. Adding a div to the page
    2. Setting the color of the div to an unusual color, not likely to be used
    3. Reading back the computed color of the div
    4. Checking whether the computed color matches the color we set:
      - If it matches, we do not have high contrast mode
      - If it does not match, the browser is probably running in high contrast mode
  */
  const testColor = 'rgb(31,41,59)';

  // Create test div
  const testDiv = document.createElement('div');
  testDiv.style.color = testColor;
  body.appendChild(testDiv);

  // Read computed color value
  let computedColor = document.defaultView.getComputedStyle(testDiv, null)
    .color;
  computedColor = computedColor.replace(/ /g, '');

  // Remove test element from body
  body.removeChild(testDiv);

  return computedColor !== testColor;
}

export default function addHighContrastClass(doc: Document) {
  const { body } = doc;
  if (!(body instanceof HTMLElement)) {
    return;
  }
  if (isHighContrastMode(doc, body)) {
    body.classList.add('high-contrast');
  } else {
    body.classList.remove('high-contrast');
  }
}

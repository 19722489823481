import React from "react";

type Props = {
  country: string;
  staticUrl?: string;
};

interface PaymentLogo {
  name: string;
  fileName: string;
  staticUrl: string;
  className?: string;
};

interface PaymentLogoProps extends PaymentLogo {
  key?: number;
}

const PaymentLogo = (props: PaymentLogoProps) => {
  const src = `${props.staticUrl}img/checkout/payment-icons/${props.fileName}`;
  const alt = `${props.name} Logo`;
  const className = props.className ? props.className : "widget-payment__icon-card";
  return (<img className={className} src={src} alt={alt} />);
};

const CartPaymentIcons = (props: Props) => {
  const staticUrl = props.staticUrl;
  if (staticUrl !== null) {
    let icons: PaymentLogo[] = [];
    icons.push({
      name: "Paypal",
      fileName: "paypal-logo-16px.png",
      staticUrl: staticUrl,
      className: "widget-payment__icon--paypal"
    });
    if (props.country === "US") {
      icons.push({
        name: "Affirm",
        fileName: "affirm_payment_icon-16px.png",
        staticUrl: staticUrl,
        className: "widget-payment__icon--affirm"
      });
    }
    icons.push({
      name: "Amex",
      fileName: "amex.png",
      staticUrl: staticUrl
    });
    icons.push({
      name: "Discover",
      fileName: "discover.png",
      staticUrl: staticUrl
    });
    icons.push({
      name: "Mastercard",
      fileName: "mastercard.png",
      staticUrl: staticUrl
    });
    icons.push({
      name: "Visa",
      fileName: "visa.png",
      staticUrl: staticUrl
    });

    return (
      <div className="widget-payment__icons">
        {icons.map((icon, index) => {
          return <PaymentLogo key={index} {...icon} />;
        })}
      </div>
    );

  }
  return null;
};

export default CartPaymentIcons;

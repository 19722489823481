import axios from 'axios';
import type { AxiosPromise } from 'axios';

// Types

type Options = {
  formatURL?: string,
  // rowIndexAttribute?: string,
}

type Subdivision = {
  id: string,
  name: string,
};

type AddressFormat = {
  format: string,
  grouped_fields: Array<Array<string>>,
  required: Array<string>,
  postal_code_pattern: string,
  locality_label: string,
  sub_locality_label: string,
  postal_code_label: string,
  administrative_area_label: string,
};

type AddressFormatResponse = {
  id: string,
  name: string,
  address_format: AddressFormat,
  subdivisions: Array<Subdivision>,
};

// Constants

const addressFields = [
  'name_line',
  'organization',
  'address_line1',
  'address_line2',
  'locality',
  'sub_locality',
  'postal_code',
  'sorting_code',
  'administrative_area',
];


// Implementation

export default class AddressForm {
  addressContainer: HTMLElement;
  unusedContainer: HTMLElement;
  rows: Array<HTMLElement>;
  formatURL: string;
  requiredHTML: string;
  fields: {
    [name: string]: HTMLElement
  };

  constructor(
    addressContainer: HTMLElement,
    {
      formatURL = '/api/v1/addressing/',
    }: Options = {},
  ) {
    this.formatURL = formatURL;
    this.addressContainer = addressContainer;

    const unusedContainer = addressContainer.querySelector('.js-address-unused');
    if (!(unusedContainer instanceof HTMLElement)) {
      throw Error('No unused field container on address');
    }
    this.unusedContainer = unusedContainer;

    // Update aria tags on unused fields
    this.unusedContainer.setAttribute('aria-hidden', 'true');
    Array.from(this.unusedContainer.querySelectorAll('input, select'))
      .forEach(w => w.setAttribute('tabindex', '-1'));

    this.rows = Array.from(addressContainer.querySelectorAll('.js-address-row'));

    // Build a field lookup
    this.fields = {};
    const fields = addressContainer.querySelectorAll('.form-field');
    Array.from(fields).forEach((field) => {
      const fieldName = field.getAttribute('data-field-name');
      if (fieldName && addressFields.includes(fieldName)) {
        this.fields[fieldName] = field;
      }
    });

    // Attach event handler
    const countrySelect = addressContainer.querySelector('.form-field[data-field-name="country"] select');
    if (countrySelect instanceof HTMLSelectElement) {
      countrySelect.addEventListener('change', () => this.update(countrySelect.value));
    }
  }

  update(countryCode: string) {
    this.getFormat(countryCode)
      .then((response) => {
        const { address_format: addressFormat, subdivisions } = response.data;

        this.updateSubdivisions(subdivisions);
        this.updateLayout(addressFormat.grouped_fields);
        this.updateRequired(addressFormat.required);
        this.updateLabels(addressFormat);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // API

  getFormat(countryCode: string): AxiosPromise<AddressFormatResponse> {
    const url = this.formatURL + countryCode;
    return axios.get(url);
  }

  // Subdivisions

  updateSubdivisions(subdivisions: Array<Subdivision>) {
    const adminSelect = this.fields.administrative_area.querySelector('select');
    if (adminSelect instanceof HTMLSelectElement) {
      // Remove current options
      Array.from(adminSelect.querySelectorAll('option'))
        .filter(option => option instanceof HTMLOptionElement && option.value !== '')
        .forEach(option => adminSelect.removeChild(option));

      // Set new options
      subdivisions.forEach((sub) => {
        const option = document.createElement('option');
        option.setAttribute('value', sub.id);
        option.innerHTML = sub.name;
        adminSelect.appendChild(option);
      });

      // Reset selection
      adminSelect.selectedIndex = 0;
    }
  }

  // Layout

  updateLayout(format: Array<Array<string>>) {
    // Move every field in the row to the unused row
    this.rows.forEach((row) => {
      const rowFields = row.querySelectorAll('.form-field');
      Array.from(rowFields).forEach((f) => {
        Array.from(f.querySelectorAll('input, select')).forEach(w => w.setAttribute('tabindex', '-1'));
        this.unusedContainer.appendChild(f);
      });
    });

    // Add fields to rows based on the format
    let rowIndex = 0;
    format.forEach((rowFormat) => {
      let fieldsAdded = 0;
      const row = this.rows[rowIndex];
      if (row !== undefined) {
        rowFormat.forEach((fieldName) => {
          const field = this.getField(fieldName);
          if (field !== undefined) {
            row.appendChild(field);
            Array.from(field.querySelectorAll('input, select')).forEach(w => w.removeAttribute('tabindex'));
            fieldsAdded += 1;
          }
        });
      }
      // Only advance the row index if we added any fields
      if (fieldsAdded > 0) {
        rowIndex += 1;
      }
    });
  }

  // Required

  updateRequired(required: Array<string>) {
    // Mark all the address fields
    Object.keys(this.fields).forEach((fieldName) => {
      const field = this.fields[fieldName];
      if (fieldName === 'name_line' || required.includes(fieldName)) {
        this.updateFieldRequired(field, true);
      } else {
        this.updateFieldRequired(field, false);
      }
    });

    // Any field that has ended up in unused must *not* be required
    const unusedFields = this.unusedContainer.querySelectorAll('.form-field');
    Array.from(unusedFields).forEach((unusedField) => {
      this.updateFieldRequired(unusedField, false);
    });
  }

  updateFieldRequired(field: HTMLElement, required: boolean) {
    // TODO: maybe just show or hide with css?
    // Handle the label
    const label = field.querySelector('label');
    if (label instanceof HTMLElement) {
      let requiredElement = label.querySelector('.form-field__required-mark');
      if (requiredElement instanceof HTMLElement) {
        // If we have an element, but the field is no longer required, remove it
        if (required === false) {
          label.removeChild(requiredElement);
        }
      } else if (required === true) {
        // We don't have an element, so add one
        requiredElement = document.createElement('span');
        requiredElement.className = 'form-field__required-mark';
        requiredElement.innerHTML = '(Required)';

        // Add it after the first textnode
        const { firstChild } = label;
        if (firstChild !== null && firstChild !== undefined) {
          const { nextSibling } = firstChild;
          if (nextSibling !== null && nextSibling !== undefined) {
            label.insertBefore(requiredElement, nextSibling.nextSibling);
          } else {
            label.appendChild(requiredElement);
          }
        }
      }
    }

    // Handle the widget itself
    const widgets = field.querySelectorAll('input, select');
    Array.from(widgets).forEach((widget) => {
      if (widget instanceof HTMLInputElement || widget instanceof HTMLSelectElement) {
        if (required === true) {
          widget.setAttribute('required', '');
        } else {
          widget.removeAttribute('required');
        }
      }
    });
  }

  // Labels

  updateLabels(format: AddressFormat) {
    this.updateFieldLabel(this.fields.locality, format.locality_label);
    this.updateFieldLabel(this.fields.sub_locality, format.sub_locality_label);
    this.updateFieldLabel(this.fields.postal_code, format.postal_code_label);
    this.updateFieldLabel(this.fields.administrative_area, format.administrative_area_label);
  }

  updateFieldLabel(field: HTMLElement, label: string) {
    const labelElement = field.querySelector('label');
    if (labelElement instanceof HTMLElement) {
      const { firstChild } = labelElement;
      if (firstChild instanceof Node && firstChild.nodeType === Node.TEXT_NODE) {
        firstChild.nodeValue = label;
      }
    }
  }

  // Helpers

  getField(name: string) {
    return this.fields[name];
  }
}

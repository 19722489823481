import queryString from 'query-string';
import Tooltip from 'tooltip.js';

import SelectOption from './options/SelectOption';
import LinkOptionv2 from './options/LinkOptionv2';

type ItemList = Array<Item>;

type Item = {
  id: number;
  shop_product_id: number;
  price: number;
  stock_quantity: number;
  sku: string;
  name_id: string;
  name: string;
  description_id: string;
  form_id: string;
  form_type: string;
  is_members_only: boolean;
  notify_me_id: number;
  price_id: string;
  user_exclusive_class: string;
  user_exclusive_message_id: string;
  media_set_id: string;
  value_adds_id: string;
  is_upsell: boolean;
  options: {
    [key: string]: string;
  };
  product_family_options: {
    [key: string]: string;
  };
};

interface Option {
  update(selectedValue: string, availableValues: Set<string>): void;
  updatePrice(selectedValue: string, price: number): void;
  updateOutOfStock(selectedValue?: string): void;
  showError(): void;
  clearError(): void;
  getInitialSelected(): null | string;
  getDefaultSelected(): null | string;
}

type SoloOptions = Map<string, Option>;
type GroupedOptions = Map<string, Map<string, Option>>;
type AllOptions = SoloOptions | GroupedOptions;
type Selected = Map<string, null | string>;

const defaultFormId = 'buy-form-default';
const defaultStickyFormId = 'product-sticky-form-default';
const unavailableFormId = 'buy-form-unavailable';
const unavailableStickyFormId = 'product-sticky-form-unavailable';

const defaultPriceId = 'buy-price-default';
const defaultStickyPriceId = 'product-sticky-price-default';
const defaultUserId = 'buy-user-default';
const defaultUserMessageId = 'buy-user-message-default';

const defaultNameId = 'buy-name-default';
const defaultDescriptionId = 'buy-description-default';

export default class BuySectionCustomization {
  container: HTMLElement;
  currency: string;
  optionsContainer: HTMLElement | null;
  items: ItemList;
  customProductItem: Item;
  selected: Map<string, null | string>;
  options: AllOptions;
  groupedOptions: AllOptions;
  descriptions: Array<HTMLElement>;
  forms: Array<HTMLElement>;
  stickyForms?: Array<HTMLElement>;
  prices: Array<HTMLElement>;
  stickyPrices?: Array<HTMLElement>;
  userExclusive: Array<HTMLElement>;
  userExclusiveMessage: Array<HTMLElement>;
  mediaSets: Array<HTMLElement>;
  valueAdds: Array<HTMLElement>;

  // Custom
  activeTab: boolean;

  constructor(
    container: HTMLElement,
    items: ItemList,
    customItem: Item,
    currency: string,
  ) {
    this.container = container;
    this.currency = currency;
    this.items = items;
    this.customProductItem = customItem;
    this.optionsContainer = this.container.querySelector('#customize-tab');

    this.descriptions = Array.from(
      this.container.querySelectorAll('.js-buy-description'),
    );
    this.forms = Array.from(
      this.container.querySelectorAll('.js-buy-section-form'),
    );
    this.stickyForms = Array.from(
      document.querySelectorAll('.js-product-sticky-nav-form'),
    );
    this.prices = Array.from(
      this.container.querySelectorAll('.js-buy-section-price'),
    );
    this.stickyPrices = Array.from(
      document.querySelectorAll('.js-product-sticky-nav-price'),
    );
    this.mediaSets = Array.from(
      this.container.querySelectorAll('.js-media-set'),
    );
    this.valueAdds = Array.from(
      this.container.querySelectorAll('.js-buy-section-value-adds'),
    );

    this.groupedOptions = this.getGroupedOptions(
      '.js-buy-section-group-option',
    );
    this.options = this.getOptions(this.groupedOptions as GroupedOptions);
    this.selected = this.getInitialSelected();
    this.setActiveTab('.js-buy-tab-control');

    this.userExclusive = Array.from(
      this.container.querySelectorAll('.js-buy-section-user-exclusive'),
    );
    this.userExclusiveMessage = Array.from(
      this.container.querySelectorAll('.js-buy-section-user-exclusive-message'),
    );

    // Tab event handler
    Array.from(this.container.querySelectorAll('.js-buy-tab-control')).forEach(
      (tab) => {
        tab.addEventListener('click', (event: Event) => {
          this.setActiveTab('.js-buy-tab-control');
        });
      },
    );

    if (this.optionsContainer instanceof HTMLElement) {
      Array.from(
        this.optionsContainer.querySelectorAll('.js-buy-section-reset'),
      ).forEach((reset) => {
        reset.setAttribute(
          'data-original-state',
          window.location.pathname + window.location.search,
        );
        reset.addEventListener('click', (event: Event) => {
          this.handleResetClick(event);
        });
      });
    }
  }

  onTabChange() {
    if (this.activeTab) {
      this.showElement(this.mediaSets, this.customProductItem.media_set_id);
      this.handleFormWithSelect(
        this.selected,
        this.groupedOptions as GroupedOptions,
      );
      this.showElement(this.prices, this.customProductItem.price_id);
      this.showElement(this.valueAdds, this.customProductItem.value_adds_id);
      this.showElement(
        this.descriptions,
        this.customProductItem.description_id,
      );
      if (this.stickyPrices) {
        this.showElement(this.stickyPrices, this.customProductItem.price_id);
      }
      const url = this.getUrl(this.selected);
      this.replaceState(url);
    }
  }

  setActiveTab(className: string) {
    const tabs = Array.from(this.container.querySelectorAll(className));
    let activeTabId = '';

    tabs.forEach((tab) => {
      if (tab.getAttribute('aria-selected') === 'true') {
        activeTabId = tab.getAttribute('aria-controls');
      }
    });
    let isActive = activeTabId === 'customize-tab';
    this.activeTab = isActive;
    if (this.activeTab) {
      this.onTabChange();
    }
  }

  // Build map of all options
  getGroupedOptions(groupSelector: string): AllOptions | GroupedOptions {
    const handler = (option: string, value?: string, groupKey?: string) =>
      this.handleOptionChangeCustomize(option, value, groupKey);
    const groupOptions: Array<HTMLElement> = Array.from(this.optionsContainer.querySelectorAll(groupSelector));

    // Handle widget types
    const options = this.handleOptionWidgets(groupOptions, handler);
    return options;
  }

  // Init populating
  getOptions(options: GroupedOptions): AllOptions {
    let map = new Map<string, Option>();
    options.forEach((group) => {
      group.forEach((value, key) => {
        map.set(key, value);
      });
    });
    return map;
  }

  handleOptionWidgets(options: Array<HTMLElement>, handler: any): AllOptions {
    const optionsMap = new Map();

    options.forEach((el) => {
      const option = el.getAttribute('data-option');
      const widget = el.getAttribute('data-widget');

      if (option && widget) {
        // Pick the right option class based on the widget type
        switch (widget) {
          case 'dropdown':
            optionsMap.set(option, new SelectOption(option, el, handler));
            break;
          case 'buttons':
          case 'swatches':
            optionsMap.set(option, new LinkOptionv2(option, el, handler));
            break;
          case 'groupOption':
            // Create map of group option values and add as 1 value entry
            const groupOptions: Array<HTMLElement> = Array.from(
              el.querySelectorAll('.js-buy-section-option'),
            );
            const groupOptionValues = this.handleOptionWidgets(
              groupOptions,
              handler,
            );
            if (groupOptionValues instanceof Map)
              optionsMap.set(option, groupOptionValues);
            break;
          default:
            break;
        }
      }
    });
    return optionsMap;
  }

  getInitialSelected(): Selected {
    const selected = new Map();
    this.options.forEach((option, key) => {
      if (!(option instanceof Map)) {
        selected.set(key, option.getInitialSelected());
      }
    });

    return selected;
  }

  getDefaultSelected(): Selected {
    const selected = new Map();
    this.options.forEach((option, key) => {
      if (!(option instanceof Map)) {
        selected.set(key, option.getDefaultSelected());
      }
    });

    return selected;
  }

  uiSelectedOptions() {
    this.selected.forEach((value, key) => {
      let el: HTMLElement = this.optionsContainer.querySelector(`.js-buy-section-option[data-option=${key}] .js-option-value-link[data-value=${value}]`);
      if (el) el.click();
    });
  }

  // Default button

  handleDefaultClick(event: MouseEvent) {
    this.selected.forEach((value, key) => {
      if (value === null) {
        const option = this.options.get(key);
        if (option && !(option instanceof Map)) {
          option.showError();
        }
      }
    });
    event.preventDefault();
  }

  handleFormWithSelect(
    selected: Map<string, null | string>,
    options: GroupedOptions,
  ) {
    //for each main component, see if all have been selected
    const optionsArray: Array<Item> = [];
    let componentsSelected = true;
    options.forEach((component, componentName) => {
      let optionsSelected = true;
      const optionsList: Array<string> = [];
      component.forEach((option, optionName) => {
        if (selected.get(optionName) == null) optionsSelected = false;
        optionsList.push(optionName);
      });
      if (optionsSelected) {
        for (let index = 0; index < this.items.length; index++) {
          const formElement = this.items[index];
          let found = true;
          optionsList.forEach((nameOfSelected) => {
            if (
              formElement.options[nameOfSelected] !=
              selected.get(nameOfSelected)
            ) found = false;
          });
          if (found) {
            optionsArray.push(formElement);
            break;
          }
        }
      } else {
        componentsSelected = false;
      }
    });
    if (componentsSelected) {
      //all components are selected
      this.showElement(this.forms, this.customProductItem.form_id);
      this.showElement(this.valueAdds, this.customProductItem.value_adds_id);
      if (this.stickyForms) {
        this.showElement(this.stickyForms, this.customProductItem.form_id);
      }

      const formWrappers = Array.from(
        document.querySelectorAll(
          `#${this.customProductItem.form_id}, .${this.customProductItem.form_id}`,
        ),
      );
      formWrappers.forEach((formWrapper) => {
        if (formWrapper instanceof HTMLElement) {
          const formPrio = [
            'add-to-cart',
            'sign-in-to-buy',
            'notify-me',
            'notify-me-authenticated',
            'sign-in-to-notify',
            'out-of-stock',
          ];
          const childrenFormTypes: Array<string> = [];
          optionsArray.forEach((childProduct) => {
            childrenFormTypes.push(childProduct.form_type);
          });
          formPrio.forEach((formType) => {
            if (childrenFormTypes.includes(formType)) {
              formWrapper.setAttribute('data-form-type', formType);
            }
          });
          const forms = Array.from(formWrapper.querySelectorAll('[data-form]'));
          forms.forEach((form) => {
            if (form.getAttribute('data-form') === 'add-to-cart') {
              const formEl = form.querySelector('form');
              if (!(formEl instanceof HTMLFormElement)) return;
              const inputArr = Array.from(formEl.querySelectorAll('input'));
              inputArr.forEach((input) => {
                if (input.getAttribute('name') === 'product_id') {
                  formEl.removeChild(input);
                }
              });
              let price = 0;
              price += this.customProductItem.price;

              optionsArray.forEach((productToAddToForm) => {
                price += productToAddToForm.price;
                const addToCartInputCustomElems =
                  document.createElement('input');
                addToCartInputCustomElems.type = 'hidden';
                addToCartInputCustomElems.name = 'product_id';
                addToCartInputCustomElems.setAttribute(
                  'data-relationship',
                  'child',
                );
                addToCartInputCustomElems.value =
                  productToAddToForm.shop_product_id.toString();
                formEl.insertBefore(
                  addToCartInputCustomElems,
                  formEl.firstChild,
                );
              });

              //Add the customizable product
              const addToCartInputCustomMainElem =
                document.createElement('input');
              addToCartInputCustomMainElem.type = 'hidden';
              addToCartInputCustomMainElem.name = 'product_id';
              addToCartInputCustomMainElem.setAttribute(
                'data-relationship',
                'parent',
              );
              addToCartInputCustomMainElem.value =
                this.customProductItem.shop_product_id.toString();
              formEl.insertBefore(
                addToCartInputCustomMainElem,
                formEl.firstChild,
              );

              //Add the group_id
              // const inputGroupId = document.createElement('input');
              // inputGroupId.type = 'hidden';
              // inputGroupId.name = 'group_id';
              // inputGroupId.value = Math.floor(Math.random() * 100000).toString();
              // form.insertBefore(inputGroupId, form.firstChild);

              this.showElement(this.forms, this.customProductItem.form_id);

              let priceSpan = this.container.querySelector(
                '#' + this.customProductItem.price_id + ' .js-span-price',
              );
              let stickyPriceSpan = document.querySelector(
                `.${this.customProductItem.price_id} .js-sticky-span-price`,
              );
              if (priceSpan instanceof HTMLElement) {
                priceSpan.textContent = window.SteelSeries.formatCurrency(price, this.currency);
               }
              if (stickyPriceSpan instanceof HTMLElement) {
                stickyPriceSpan.textContent = window.SteelSeries.formatCurrency(price, this.currency);
              }
            }

            if (form.getAttribute('data-form') === 'notify-me') {
              const itemLists = Array.from(
                document.querySelectorAll('.notify-me__sku-list'),
              );
              itemLists.forEach((list) => {
                const itemListArr = Array.from(list.querySelectorAll('li'));
                itemListArr.forEach((item) => {
                  list.removeChild(item);
                });
              });

              optionsArray.forEach((productToAddToNotifyItemList) => {
                if (productToAddToNotifyItemList.form_type === 'notify-me') {
                  itemLists.forEach((list) => {
                    const newItem = document.createElement('li');
                    newItem.classList.add('notify-me__sku-list-item');
                    newItem.innerHTML = productToAddToNotifyItemList.name;
                    list.appendChild(newItem);
                  });
                }
              });

              const itemCount = document.querySelector(
                '.js-notifyme-modal-count',
              );
              if (itemCount instanceof HTMLElement) {
                let count = 0;
                itemCount.innerHTML = count.toString();

                optionsArray.forEach((productToAddToForm) => {
                  if (productToAddToForm.form_type === 'notify-me') {
                    count += 1;
                    itemCount.innerHTML = count.toString();
                  }
                });
              }

              const formEl = form.querySelector('form');
              // if (!(formEl instanceof HTMLFormElement)) return;
              if (formEl instanceof HTMLFormElement) {
                const inputArr = Array.from(formEl.querySelectorAll('input'));
                inputArr.forEach((input) => {
                  if (input.getAttribute('name') === 'child_item_id') {
                    formEl.removeChild(input);
                  }
                });

                if (optionsArray.length > 0) {
                  const notifyInputCustomElems =
                    document.createElement('input');
                  notifyInputCustomElems.type = 'hidden';
                  notifyInputCustomElems.name = 'child_item_id';
                  const listNotifyMeIds: Array<string> = [];
                  optionsArray.forEach((productToAddToForm, index) => {
                    listNotifyMeIds.push(
                      productToAddToForm.notify_me_id.toString(),
                    );
                  });
                  notifyInputCustomElems.value = listNotifyMeIds.toString();
                  formEl.insertBefore(
                    notifyInputCustomElems,
                    formEl.firstChild,
                  );
                }
                formEl.setAttribute(
                  'action',
                  `/notify-me-only/${this.customProductItem.notify_me_id.toString()}`,
                );
              }
            }
          });
        }
      });
    } else {
      // selection isn't complete yet, use defaults
      this.showElement(this.prices, defaultPriceId);
      this.showElement(this.userExclusive, defaultUserId);
      this.showElement(this.userExclusiveMessage, defaultUserMessageId);
      this.showElement(this.forms, defaultFormId);
      // this.showElement(this.descriptions, defaultDescriptionId);
      // this.showElement(this.valueAdds, null);
    }
  }

  // Reset button (customizable only)
  handleResetClick(event: Event) {
    this.selected = this.getDefaultSelected();
    this.uiSelectedOptions();
  }

  // Option handling
  // Option handling for customize product
  handleOptionChangeCustomize(
    option: string,
    value?: string,
    groupKey?: string,
  ) {
    const selected = new Map(this.selected);
    selected.set(option, value);

    if (this.activeTab) {
      // Update display of option values
      for (const entry of (this.groupedOptions as GroupedOptions)
        .get(groupKey)
        .entries()) {
        // Get what is currently selected for this option
        const selectedValue = selected.get(entry[0]);
        const availableValues = this.getAvailableOptionValues(
          entry[0],
          this.items,
          selected,
          groupKey,
        );

        // Ask option to update itself
        (this.options as SoloOptions)
          .get(entry[0])
          .update(selectedValue, availableValues);
      }
    }

    //For the option selected (i.e. Wired), get the buttons for that option (connectivity)
    //Find the item that matches that section (connectivity, color), with the color in the value set and the connectivity in all the options
    let grOps = new Map((this.groupedOptions as GroupedOptions).get(groupKey));
    const otherSet = new Map<string, string>();
    for (let k of grOps.keys()) {
      if (k === option) grOps.delete(k);
      else otherSet.set(k, selected.get(k));
    }
    //Find items that match the selection
    const filteredItems = this.items.filter((value) => {
      let res = true;
      for (let k of otherSet.keys()) {
        if (value.options[k] !== otherSet.get(k)) {
          res = false;
          break;
        }
      }
      return res;
    });
    const smallest = filteredItems.sort((a, b) => a.price - b.price)[0].price;
    const reduced = filteredItems.reduce(function (filtered, itemInReduce) {
      if (itemInReduce.price > smallest && itemInReduce.is_upsell) {
        const someNewValue: { [key: string]: any; } = {
          price: itemInReduce.price,
        };
        someNewValue.key = option;
        someNewValue.value = itemInReduce.options[option];
        filtered.push(someNewValue);
      }
      return filtered;
    }, []);

    reduced.forEach((element) => {
      (this.options as SoloOptions)
        .get(element.key)
        .updatePrice(
          element.value,
          (parseInt((element.price * 100).toFixed()) -
            parseInt((smallest * 100).toFixed())) /
          100,
        );
    });

    // out of stock icon
    const reducedOutOfStock = filteredItems.reduce(function (
      filtered,
      itemInReduce,
    ) {
      if (itemInReduce.stock_quantity <= 0) {
        const someNewValue: { [key: string]: any; } = {};
        someNewValue.key = option;
        someNewValue.value = itemInReduce.options[option];
        filtered.push(someNewValue);
      }
      return filtered;
    }, []);
    reducedOutOfStock.forEach((element) => {
      (this.options as SoloOptions)
        .get(element.key)
        .updateOutOfStock(element.value);
    });

    // this.showElement(this.forms, 'customize-buy-section');
    if (this.activeTab) {
      this.handleFormWithSelect(
        selected,
        this.groupedOptions as GroupedOptions,
      );
      const url = this.getUrl(selected);
      this.replaceState(url);
    }
    this.selected = selected;
  }

  // State handling
  getUrl(selected: Selected): string {
    const path = `/${window.location.pathname.replace(/^\//, '')}`;
    const searchString = window.location.search;
    // eslint-disable-next-line compat/compat
    const params = new URLSearchParams(searchString);
    const isAdmin = params.has('edit');
    const query: { [key: string]: string; } = {};

    // add utm tags to query if there are any
    for (const key of params.keys()) {
      const value = params.get(key);
      if (key.startsWith('utm_')) {
        if (value === null || value === undefined) {
          if (Object.prototype.hasOwnProperty.call(query, key)) {
            delete query[key];
          }
        } else {
          query[key] = value;
        }
      }
    }

    // add product options to query
    selected.forEach((value, option) => {
      if (value === null || value === undefined) {
        if (Object.prototype.hasOwnProperty.call(query, option)) {
          delete query[option];
        }
      } else {
        query[option] = value;
      }
    });

    let qs = queryString.stringify(query, { arrayFormat: 'comma' });
    if (qs.length > 0 && isAdmin) qs = `edit=true&${qs}`;
    return `${path}${qs.length > 0 ? `?${qs}` : ''}`;
  }

  replaceState(url: string) {
    window.history.replaceState({}, document.title, url);
  }

  getAvailableOptionValues(
    option: string,
    items: ItemList,
    selected: Map<string, string>,
    groupKey?: string,
  ): Set<string> {
    let listMatchOption = items.filter(
      (item) => item.options[option] !== undefined,
    ); //list of items that have that option 'mouse-feet-color'
    for (const entry of (this.groupedOptions as GroupedOptions)
      .get(groupKey)
      .entries()) {
      if (entry[0] !== option)
        listMatchOption = listMatchOption.filter(
          (item) => item.options[entry[0]] === selected.get(entry[0]),
        );
    }
    return new Set(listMatchOption.map((item) => item.options[option])); //gray black red not colors not mathing in items
  }

  // Tooltips
  initTooltips() {
    Array.from(
      this.container.querySelectorAll('.js-buy-section-tooltip'),
    ).forEach((el) => {
      const tooltipTitle = el.getAttribute('data-title');
      if (tooltipTitle) {
        new Tooltip(el as HTMLElement, {
          title: tooltipTitle,
          placement: 'top', // or bottom, left, right, and variations
          offset: '0, 14px',
          delay: { show: 250, hide: 0 },
          container: document.body,
          boundariesElement: document.body,
          arrowSelector: '.tooltip__arrow',
          innerSelector: '.tooltip__inner',
          template:
            '<div class="tooltip" role="tooltip"><div class="tooltip__arrow"></div><div class="tooltip__inner"></div></div>',
        });
        el.removeAttribute('data-title');
      }
    });
  }

  // Utils

  showElement(elements: Array<HTMLElement>, idOrClass?: string) {
    elements.forEach((el) => {
      if (
        idOrClass &&
        (el.id === idOrClass || el.classList.contains(idOrClass))
      ) {
        el.classList.add('is-visible');
      } else {
        el.classList.remove('is-visible');
      }
    });
  }
}

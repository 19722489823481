import CurrencyFormatter from '../../currency/CurrencyFormatter';

declare global {
  interface Window {
    currencyFormat: any;
  }
}
export default class LinkOptionv2 {
  option: string;
  element: HTMLElement;
  errorText: string;
  errorElement?: HTMLElement;
  selectHandler: (string1: string, string2?: string, string3?: string) => void;
  links: Array<HTMLElement>;
  prices: Array<HTMLElement>;
  formatCurrency: (arg0: number, arg1: string) => string;

  constructor(
    option: string,
    element: HTMLElement,
    selectHandler: (
      string1: string,
      string2?: string,
      string3?: string,
    ) => void,
  ) {
    this.option = option;
    this.element = element;
    this.selectHandler = selectHandler;
    this.links = Array.from(
      this.element.querySelectorAll('a.js-option-value-link'),
    );
    this.errorElement = this.element.querySelector(
      '.js-buy-section-option-error',
    );
    this.errorText = this.element.getAttribute('data-error-text');

    this.prices = Array.from(
      this.element.querySelectorAll('.js-option_upscale-price'),
    );
    const formatter = new CurrencyFormatter({ ...window.currencyFormat, noDecimals: true });
    this.formatCurrency = (value, currencyCode) => formatter.format(value, currencyCode);

    this.links.forEach((link) => {
      // tooltips
      const hasTooltip =
        link.hasAttribute('data-tooltip-header') &&
        link.hasAttribute('data-tooltip-body');

      if (hasTooltip) {
        const parent = link.parentElement;
        if (!(parent instanceof HTMLElement)) return;
        const tooltipContainer = parent.querySelector('.js-info-tooltip');
        if (!(tooltipContainer instanceof HTMLElement)) return;
        const tooltip = tooltipContainer.querySelector(
          '.buy-section-option__value__info-tooltip',
        );
        if (!(tooltip instanceof HTMLElement)) return;
        const closeButton = tooltip.querySelector('button');
        if (!(closeButton instanceof HTMLButtonElement)) return;

        const header = link.getAttribute('data-tooltip-header');
        const body = link.getAttribute('data-tooltip-body');
        if (!(header && body)) return;

        const headerElement = tooltip.querySelector(
          '.value__info-tooltip__header-content',
        );
        const bodyElement = tooltip.querySelector('.value__info-tooltip__body');
        if (
          !(
            headerElement instanceof HTMLElement &&
            bodyElement instanceof HTMLElement
          )
        ) {
          return;
        }

        headerElement.textContent = header;
        bodyElement.textContent = body;
        const tooltipHeight = tooltipContainer.offsetHeight;

        link.addEventListener('click', (event: MouseEvent) => {
          this.handleTooltip(
            event,
            tooltipContainer,
            tooltipHeight,
            closeButton,
          );
        });
      }
      link.addEventListener('click', (event: MouseEvent) => {
        this.handleClick(event);
      });
    });
  }

  handleClick(event: MouseEvent) {
    const { target } = event;
    if (!(target instanceof HTMLAnchorElement)) {
      return;
    }
    const value = target.getAttribute('data-value');
    if (!value) {
      throw Error('Missing value on element');
    }

    const widget = this.element.getAttribute('data-widget');
    const label = this.element.querySelector('.js-swatch-label');
    if (widget && widget === 'swatches' && label instanceof HTMLElement) {
      label.classList.add('is-selected');
      label.textContent = value;
    }

    let groupElement: HTMLElement = this.element.closest(
      '.js-buy-section-group-option',
    );
    let groupKey: string;
    if (groupElement instanceof HTMLElement) {
      groupKey = groupElement.getAttribute('data-option');
    } else {
      groupKey = null;
    }

    this.selectHandler(this.option, value, groupKey);
    event.preventDefault();
  }

  handleTooltip(
    event: MouseEvent,
    tooltipContainer: HTMLElement,
    tooltipHeight: number,
    close: HTMLButtonElement,
  ) {
    const { target } = event;
    if (!(target instanceof HTMLAnchorElement)) return;

    // reset all variables
    const allTooltips = this.element.querySelectorAll('.js-info-tooltip');
    allTooltips.forEach((tooltip) => {
      if (tooltip.classList.contains('is-active')) {
        tooltip.classList.remove('is-active');
        this.element.style.setProperty('--height', 'unset');
        this.element.style.setProperty('--cursor-left', '0');
        this.element.style.setProperty('--cursor-right', '0');
        this.element.style.setProperty('--link-bottom', '0');
      }
    });
    this.links.forEach((link) => {
      const parent = link.parentElement;
      if (!(parent instanceof HTMLElement)) return;
      if (parent.classList.contains('tooltip-active')) {
        parent.classList.remove('tooltip-active');
      }
    });

    this.element.style.setProperty('--height', `${tooltipHeight.toString()}px`);

    // mouse X position, defines carrot location
    const optionRect = this.element.getBoundingClientRect();
    const middle = optionRect.width / 2;
    const linkCenter = middle / 2 - 16;
    const optionX = event.clientX - optionRect.left;

    // if the user didn't click with mouse e.g. page load
    if (optionX <= 0) {
      this.element.style.setProperty('--cursor-right', 'unset');
      this.element.style.setProperty('--cursor-left', 'unset');
      this.element.style.setProperty('--caret-visibility', 'hidden');
    } else if (optionX < middle) {
      this.element.style.setProperty(
        '--cursor-left',
        `${linkCenter.toString()}px`,
      );
      this.element.style.setProperty('--cursor-right', 'unset');
      this.element.style.setProperty('--caret-visibility', 'visible');
    } else if (optionX > middle) {
      this.element.style.setProperty(
        '--cursor-right',
        `${linkCenter.toString()}px`,
      );
      this.element.style.setProperty('--cursor-left', 'unset');
      this.element.style.setProperty('--caret-visibility', 'visible');
    }

    const linkTop = target.offsetTop;
    const linkHeight = target.offsetHeight;
    const linkBottom = linkTop + linkHeight;
    this.element.style.setProperty(
      '--link-bottom',
      `${linkBottom.toString()}px`,
    );

    const parent = target.parentElement;
    if (!(parent instanceof HTMLElement)) return;

    parent.classList.add('tooltip-active');
    tooltipContainer.classList.add('is-active');
    close.addEventListener('click', () => {
      parent.classList.remove('tooltip-active');
      tooltipContainer.classList.remove('is-active');
      this.element.style.setProperty('--height', 'unset');
      this.element.style.setProperty('--cursor-left', '0');
      this.element.style.setProperty('--link-bottom', '0');
    });
    event.preventDefault();
  }

  updatePrice(selectedValue: string, price: number): void {
    if (selectedValue) {
      this.clearError();
    }

    this.links.forEach((link) => {
      const value = link.getAttribute('data-value');

      // Update selection status
      if (
        value &&
        value === selectedValue &&
        link.parentElement instanceof HTMLElement &&
        link.parentElement.querySelector('.js-option_upscale-price') instanceof HTMLElement
      ) {
        let priceSpan: HTMLSpanElement = link.parentElement.querySelector(
          '.js-option_upscale-price',
        );
        const currencyCode = priceSpan.getAttribute('data-currency');
        const formattedPrice = this.formatCurrency(price, currencyCode);
        priceSpan.innerText = `+${formattedPrice}`;
      } else {
        let priceSpan: HTMLSpanElement = link.parentElement.querySelector(
          '.js-option_upscale-price',
        );
        priceSpan.innerText = '';
      }
    });
  }

  updateOutOfStock(selectedValue?: string): void {
    if (selectedValue) {
      this.clearError();
    }

    this.links.forEach((link) => {
      const value = link.getAttribute('data-value');

      // Update selection status
      if (
        value &&
        value === selectedValue &&
        link.parentElement instanceof HTMLElement &&
        link.parentElement.querySelector('.js-option_notify-icon') instanceof HTMLElement
      ) {
        let notifyIcon = link.parentElement.querySelector(
          '.js-option_notify-icon',
        );
        notifyIcon.classList.remove('hidden');
      } else if (
        link.parentElement.querySelector('.js-option_notify-icon') instanceof HTMLElement
      ) {
        let notifyIcon = link.parentElement.querySelector(
          '.js-option_notify-icon',
        );
        notifyIcon.classList.add('hidden');
      }
    });
  }

  update(selectedValue: string, availableValues: Set<string>): void {
    if (selectedValue) {
      this.clearError();
    }

    this.links.forEach((link) => {
      const value = link.getAttribute('data-value');

      // Update selection status
      if (value && value === selectedValue) {
        link.classList.add('is-selected');
      } else {
        link.classList.remove('is-selected');
      }

      // Update availability
      if (value && availableValues.has(value)) {
        link.setAttribute('data-state', '');
        link.setAttribute('tabindex', '0');
      } else {
        link.setAttribute('data-state', 'unavailable');
        link.setAttribute('tabindex', '-1');
      }
    });
  }

  showError() {
    if (!(this.errorElement instanceof HTMLElement)) {
      return;
    }
    if (this.errorText === null || this.errorText === undefined) {
      return;
    }
    this.element.classList.add('is-error');
    this.errorElement.innerText = this.errorText;
  }

  clearError() {
    if (!(this.errorElement instanceof HTMLElement)) {
      return;
    }
    if (this.errorText === null || this.errorText === undefined) {
      return;
    }
    this.element.classList.remove('is-error');
    this.errorElement.innerText = '';
  }

  getInitialSelected(): null | string {
    const initial = this.element.getAttribute('data-initial');
    return initial !== '' ? initial : null;
  }

  getDefaultSelected(): null | string {
    const defaultValue = this.element.getAttribute('data-default');
    return defaultValue !== '' ? defaultValue : null;
  }
};

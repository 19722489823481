import React, { Component } from 'react';
import { Result } from '.';

const KEYCODE_UP = 38;
const KEYCODE_DOWN = 40;
const KEYCODE_ENTER = 13;

type Props = {
  search: (arg0: string) => void,
  scrollUp: () => void,
  scrollDown: () => void,
  navigate: (arg0: Result) => void,
  navigateResultsPage: (arg0: string) => void,
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
};

type State = {
  query: string,
};

export default class SearchField extends Component<Props, State> {
  state = {
    query: '',
  };

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.props.navigateResultsPage(this.state.query);
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ query: event.target.value }, () => {
      this.props.search(this.state.query);
      this.props.handleInputChange(this.state.query);
    });
  };

  handleKeyUp = (event: React.KeyboardEvent<HTMLFormElement>) => {
    const keyHandlers = {
      [KEYCODE_UP]: this.props.scrollUp,
      [KEYCODE_DOWN]: this.props.scrollDown,
      [KEYCODE_ENTER]: this.props.navigate,
    };

    const handler = keyHandlers[event.code];
    if (handler !== undefined) {
      handler();
    }
  };

  render() {
    return (
      <form
        className="search-widget__field"
        onSubmit={this.handleSubmit}
        onKeyUp={this.handleKeyUp}
      >
        <label className="search-widget__label" htmlFor="search-widget-input">
          <span className="sr-only">Search steelseries.com:</span>
          Search:
        </label>
        <input
          className="search-widget__input"
          id="search-widget-input"
          type="text"
          value={this.state.query}
          onChange={this.handleChange}
        />
      </form>
    );
  }
}

// Criteo - GTM tags need info in datalayer
import axios from 'axios';
import { getCurrentUser } from './utils/currentUser';

let criteoPartnerID = '';
let criteoPageType = '';

function getCriteoID() {
  switch (window.countryCode) {
    case 'DK':
      return (criteoPartnerID = '79609');
    case 'SE':
      return (criteoPartnerID = '79205');
    case 'FI':
      return (criteoPartnerID = '79206');
    case 'NO':
      return (criteoPartnerID = '79207');
    default:
      return (criteoPartnerID = '');
  }
}

// Criteo Tag Names
// Homepage
// Listing Tag (Catalog Pages)
// Product Tag (Product Pages)
// Basket Tag (Cart Page)
// Confirmation Tag (Post Purchase Page)
function getPageType() {
  if (document.querySelector('.buy-section') instanceof HTMLElement) {
    criteoPageType = 'Product Tag';
  } else if (
    document.querySelector('.js-faceted-product-list') instanceof HTMLElement
  ) {
    criteoPageType = 'Listing Tag';
  } else if (window.location.pathname === '/cart') {
    criteoPageType = 'Basket Tag';
  } else if (window.location.pathname === '/checkout/complete') {
    criteoPageType = 'Confirmation Tag';
  } else {
    criteoPageType = '';
  }
  return criteoPageType;
}

export default function addCriteoGTM() {
  window.dataLayer = window.dataLayer || [];
  const currentUser = getCurrentUser();

  if (currentUser) {
    if (
      'dataLayer' in window &&
      typeof window.dataLayer.push === 'function'
    ) {
      let criteoDataObject = {
        criteoPartnerId: getCriteoID(),
        criteoEmail: currentUser.email_hashed,
        criteoPagetype: getPageType(),
      };

      switch (criteoDataObject.criteoPagetype) {
        case 'Product Tag':
          const productIdInput = document.querySelector(
            'input[name="product_id"',
          );
          if (
            productIdInput !== null &&
            productIdInput instanceof HTMLInputElement
          ) {
            const productId = productIdInput.value;
            criteoDataObject.criteoProductId = productId;
          }
          break;
        case 'Listing Tag':
          // Get first 3 items SKUs
          let items = Array.from(
            document.querySelectorAll('.catalog-list-product__link'),
          );
          items = items.slice(0, 3);
          let itemSkus = [];
          items.forEach((item) => {
            itemSkus.push(item.dataset.itemSku);
          });
          criteoDataObject.criteoListingIds = itemSkus;
          break;
        case 'Basket Tag':
          let criteoBasketItems = [];
          // Get cart items
          const fetchCart = axios.get('/api/v3/cart');
          fetchCart.then(
            (response) => {
              let items = response.data.items;
              // sku, price, quantity
              items.forEach((item) => {
                let cartItem = {
                  id: item.sku,
                  price: item.price,
                  quantity: item.quantity,
                };
                criteoBasketItems.push(cartItem);
              });
            },
            (error) => {
              console.log('cart fetch error');
            },
          );
          criteoDataObject.criteoBasketItems = criteoBasketItems;
          break;
        case 'Confirmation Tag':
          const container = document.querySelector(
            '.js-criteo-order-complete',
          );

          // Get order number
          if (container instanceof HTMLElement) {
            const orderNumber = container.querySelector('[data-order-number]')
              .dataset.orderNumber;
            criteoDataObject.criteoTransactionId = orderNumber;
            // Get items in order
            let transactionItems = Array.from(
              container.querySelectorAll('.item'),
            );
            let criteoTransactionItems = [];

            transactionItems.forEach((item) => {
              let object = {
                id: item.dataset.itemSku,
                price: item.dataset.itemPrice,
                quantity: item.dataset.itemQuantity,
              };
              criteoTransactionItems.push(object);
            });
            criteoDataObject.criteoTransactionItems = criteoTransactionItems;
          }
          break;
        default:
          break;
      }
      window.dataLayer.push({ criteoDataObject: criteoDataObject });
    }
  }
}

import ValidationResult from './ValidationResult';

// Types

type Options = {
  dirtyAttribute?: string,
  errorSelector?: string,
  errorElement?: string,
  errorTitleClass?: string,
  errorClass?: string,
  errorTimeout?: number,
};


// Constants

const errorMessages = {
  valueMissing: 'This field is required.',
  typeMismatchEmail: 'Please enter an email address.',
  typeMismatchUrl: 'Please enter a URL.',
  badInput: 'Please enter a number.',
  stepMismatch: 'Please select a valid value.',
  // tooShort: 'Please enter at least {} characters.',
  // tooLong: 'Please enter fewer than {} characters.',
  // rangeOverflow: 'Please select a value lower than {}.',
  // rangeUnderflow: 'Please select a value higher than {}.',
  // patternMismatch: 'Please match the requested format.',

  generic: 'Please enter a valid value.',
};

// Implementation

export default class FormFieldValidator {
  field: HTMLElement;
  errorSelector: string;
  errorElement: string;
  errorClass: string;
  errorTitleClass: string;
  errorTimeout: number;
  dirtyAttribute: string;

  constructor(
    element: HTMLElement,
    {
      dirtyAttribute = 'data-dirty',
      errorSelector = '.form-field__error-list',
      errorElement = 'span',
      errorTitleClass = 'form-field__error-title',
      errorClass = 'form-field__error',
      errorTimeout = 400,
    }: Options = {},
  ) {
    this.dirtyAttribute = dirtyAttribute;
    this.errorSelector = errorSelector;
    this.errorElement = errorElement;
    this.errorTitleClass = errorTitleClass;
    this.errorClass = errorClass;
    this.errorTimeout = errorTimeout;

    this.field = element;
    this.field.addEventListener('keyup', (e: KeyboardEvent) => this.onKeyUp(e), true);
    this.field.addEventListener('blur', (e: FocusEvent) => this.onBlur(e), true);
    this.field.addEventListener('change', (e: Event) => this.onChange(e), true);
  }

  // Validation

  validate(): ValidationResult {
    // @TODO: we assume that there is only one element per field. Might have to handle more.
    const element = this.field.querySelector('input, select');
    let result = new ValidationResult(true, 'required', '');
    if (element instanceof HTMLInputElement || element instanceof HTMLSelectElement) {
      result = this.getValidationResult(element);
      this.updateErrors(element, result);
    }
    return result;
  }

  getValidationResult(el: HTMLInputElement | HTMLSelectElement) {
    if (el.disabled
        || el.type === 'hidden'
        || el.type === 'file'
        || el.type === 'input'
        || el.type === 'submit'
        || el.type === 'button') {
      return new ValidationResult(true, 'required', '');
    }

    const { validity } = el;
    if (validity instanceof ValidityState) {
      // We intentionally only cover the "value missing" error, since that's the only
      // one where the backend and the frontend validation is matched up well enough.
      if (!validity.valid && validity.valueMissing) {
        return new ValidationResult(false, 'required', errorMessages.valueMissing);
      }
    }

    return new ValidationResult(true, 'required', '');
  }

  // Event handlers

  onKeyUp(event: KeyboardEvent) {
    const { target } = event;
    if (!(target instanceof HTMLInputElement)) {
      // Nothing to do here
      return;
    }
    const isDirty = target.hasAttribute(this.dirtyAttribute);
    const result = this.getValidationResult(target);

    // Regardless if the field is dirty or not, we update validation
    // errors as soon as the field is in a valid state.
    if (result.isValid) {
      this.updateErrors(target, result);
    }

    // Fields already marked dirty don't need to be re-checked
    if (!isDirty) {
      // We mark a field as dirty if:
      //  * The rendered value was null or empty, and the length
      //    of the current value is at least 1.
      //  * The rendered value is different than the current value
      //    (e.g. the user deleted everything in the input)
      const renderedValue = target.getAttribute('value');
      const currentValue = target.value;
      if ((renderedValue === null || renderedValue === '') && currentValue.length > 0) {
        target.setAttribute(this.dirtyAttribute, '');
      } else if (renderedValue !== null && currentValue !== renderedValue) {
        target.setAttribute(this.dirtyAttribute, '');
      }
    }
  }

  onBlur(event: FocusEvent) {
    const { target } = event;
    if (!(target instanceof HTMLInputElement)) {
      // nothing to do here
      return;
    }
    const isDirty = target.hasAttribute(this.dirtyAttribute);
    const result = this.getValidationResult(target);

    if (result.isValid || isDirty) {
      // Only update errors on valid input or if field is dirty
      this.updateErrors(target, result);
    }
  }

  onChange(event: Event) {
    const { target } = event;
    if (!(target instanceof HTMLSelectElement)) {
      // Nothing to do here
      return;
    }
    const result = this.getValidationResult(target);

    // Always update errors for a select field on change
    this.updateErrors(target, result);
  }

  // Error messages

  updateErrors(element: HTMLInputElement | HTMLSelectElement, result: ValidationResult) {
    // Find the error container
    const errorContainer = this.field.querySelector(this.errorSelector);
    if (!(errorContainer instanceof HTMLElement)) {
      return;
    }

    // Get existing title
    const existingTitle = Array.from(errorContainer.querySelectorAll(`.${this.errorTitleClass}`));

    // Get existing errors
    const existingErrors = Array.from(errorContainer.querySelectorAll(`.${this.errorClass}`));
    const sameError = existingErrors.filter(el => el.getAttribute('data-code') === result.code);
    const otherErrors = existingErrors.filter(el => el.getAttribute('data-code') !== result.code);

    if (result.isValid) {
      // If the error message exists, we need to remove it
      sameError.map(el => setTimeout(() => {
        if (el.parentNode === errorContainer) {
          errorContainer.removeChild(el);
        }
      }, this.errorTimeout));

      // If it was the last error message, we need to fade out the errors
      if (otherErrors.length === 0) {
        // Remove the itle
        existingTitle.map(el => setTimeout(() => {
          if (el.parentNode === errorContainer) {
            errorContainer.removeChild(el);
          }
        }, this.errorTimeout));

        // This was the last error, so close the error messages
        errorContainer.classList.add('is-empty');
        // Set aria invalid on the element
        element.setAttribute('aria-invalid', 'false');
        // Mark the field as not being invalid
        this.field.classList.remove('is-invalid');
      }
    } else {
      // If the error message doesn't exist, add it
      if (sameError.length === 0) {
        // Create a title and insert first if we have other errors, otherwise at the end
        const errorElement = this.createError(result.code, result.message);
        if (otherErrors.length === 0) {
          const titleElement = this.createTitle();
          errorContainer.appendChild(titleElement);
          errorContainer.appendChild(errorElement);
        } else {
          errorContainer.insertBefore(errorElement, otherErrors[0]);
        }
      }

      // We know we have at least one error now, so show the error container
      errorContainer.classList.remove('is-empty');
      // Same goes for aria-invalid
      element.setAttribute('aria-invalid', 'true');
      // And we know that the field is invalid now
      this.field.classList.add('is-invalid');
    }
  }

  createTitle() {
    const label = this.field.getAttribute('data-label');
    const title = label ? `Errors for ${label}:` : 'Errors:';

    const titleElement = document.createElement(this.errorElement);
    titleElement.className = `${this.errorTitleClass} sr-only`;
    titleElement.innerHTML = title;
    return titleElement;
  }

  createError(code: string, message: string) {
    const errorElement = document.createElement(this.errorElement);
    errorElement.setAttribute('data-code', code);
    errorElement.className = this.errorClass;
    errorElement.innerHTML = message;
    return errorElement;
  }
}

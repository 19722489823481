import type { Cart, AddonList } from './types';

export type CartState = {
  open: boolean;
  isLoaded: boolean;
  couponError?: string;
  addons: AddonList;
  data: Cart;
};

const initialState: CartState = {
  open: false,
  isLoaded: false,
  couponError: null,
  addons: [],
  data: {
    id: '',
    country: 'US',
    region: 'US',
    currency: 'USD',
    symbol: '$',
    charge_currency: 'USD',
    charge_currency_symbol: '$',
    charge_currency_rate: 1.0,
    quantity: 0,
    quantity_incl_value_adds: 0,
    is_empty: true,
    is_login_required: false,
    can_checkout: false,
    total_excl_discounts: 0,
    total_discounts: 0,
    total: 0,
    total_msrp: 0,
    total_msrp_discount: 0,
    total_incl_shipping: 0,
    total_excl_discounts_charge: 0,
    total_discounts_charge: 0,
    total_charge: 0,
    total_incl_shipping_charge: 0,
    display_vat: false,
    vat: 0,
    vat_charge: 0,
    url: '/api/v3/cart',
    available_add_on_products_url: '/api/v3/cart/available-add-ons',
    checkout_url: '/checkout/new',
    login_required_url: '/login?next=/cart',
    items: [],
    value_adds: [],
    coupon_code: null,
    promotions: [],
    post_order: [],
    estimated_shipping_option: null,
    estimated_shipping_price_base: 0,
    estimated_free_shipping_qualify_subtotal: 0,
    estimated_total_base: 0,
  },
};

export default initialState;
